import React, { useLayoutEffect, useState } from 'react'

import IconX from '../IconX'

import './style.scss'

const UseCookieToast = () => {

    const
        [isToast, setToast] = useState(false),
        onClick = {

            accept: (e) => {

                localStorage.setItem('MTP|UseCookieToast', true)
                setToast(false)
                return true
            }
        }

    useLayoutEffect((_) => { setTimeout((_) => setToast(!Boolean(localStorage.getItem('MTP|UseCookieToast'))), 10000) }, [])

    return (<>{isToast && <div id="UseCookieToast">
        <div className="title"><IconX name="info-circle" /> Файлы Cookie</div>
        <div className="description">Используя сайт <b>МагТехПро</b>, вы соглашаетесь с использованием файлов <b>cookie</b>.</div>
        <div className='btn_default' onClick={onClick.accept}><IconX name="check2" /> Закрыть</div>
    </div>}</>)
}

export default UseCookieToast