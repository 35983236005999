import React, { useEffect, useState } from 'react'

import IconX from '../../../../Components/IconX'
import Block from '../../../../Components/Block'
import InputX from '../../../../Components/InputX'
import ButtonX from '../../../../Components/ButtonX'
import { animationElementClick, animationElementError } from '../../../../Components/Animations'
import AlertX from '../../../../Components/AlertX'
import { DateTime } from '../../../../FuncX'

import API from '../../../../API'

import './style.scss'

const BlockMiddleFeedBack = ({ selectService }) => {

    const
        [isError, setError] = useState({ status: false, msg: '' }),
        [isSending, setSending] = useState(false),
        [isSend, setSend] = useState(false),
        [isComment, setComment] = useState('')

    const onClick = {

        push: (e) => {

            if (isSending) return false

            setError({ status: false })

            const
                formElement = document.querySelector('#BlockX>.name-FeedBack>form'),
                formData = new FormData(formElement)

            if (formData.get('name')?.length < 2) {

                animationElementError(formElement.querySelector('.id_name'), true)
                return false
            }

            if (formData.get('communication')?.length < 8) {

                animationElementError(formElement.querySelector('.id_communication'), true)
                return false
            }

            setSending(true)
            animationElementClick(e.currentTarget)
            send(formData.get('name'), formData.get('communication'), formData.get('comment'))
        }
    }

    const send = (name, communication, comment) => new Promise(async (resolve) => {

        let response

        try { response = await API.FeedBacks.Create(name, communication, 'ALL', comment) }
        catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            switch (response.result) {

                case 'limit_query': {

                    setError({
                        status: true,
                        msg: <>
                            Превышен лимит запросов! ({response.limitToHour} в час)<br />
                            <br />
                            Попробуйте снова через {DateTime.msToTime(new Date(response.dateTime_retry).getTime() - new Date().getTime()).text}
                        </>
                    })
                    break
                }

                default: {

                    setError({
                        status: true,
                        msg: <>При отправке запроса, произошла ошибка.<br />Попробуйте отправить запрос ещё раз.</>
                    })
                    break
                }
            }

            setSending(false)
            return resolve(false)
        }

        setSending(false)
        setSend({ name, communication, comment })
        return resolve(true)
    })

    const updateSelectService = (selected) => {

        if (!selected) return false

        const
            text = `Здравствуйте! Меня интересует услуга - '${selected}'.\n\n`,
            commentElement = document.querySelector('#BlockX>.name-FeedBack>form>.id_comment>textarea')

        setComment(text)
        setTimeout(() => {

            commentElement?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
            commentElement?.focus()
            commentElement.selectionStart = text?.length || 0
        }, 100)
    }

    useEffect(() => { updateSelectService(selectService) }, [selectService])

    return (<Block name="FeedBack" icon={<IconX name="person-raised-hand" />} title="Обратная связь">
        {isError.status && <><AlertX type="error" icon={<IconX name="x-lg" />}>{isError.msg}</AlertX></>}
        {isSend ?
            <div className="send elementShow">
                <IconX name="check2-circle" />
                <div className="title"><b>{isSend?.name}</b>, Ваш запрос на обратную связь успешно отправлен!</div>
                Скоро с вами свяжутся (<b>{isSend?.communication}</b>)
                {isSend?.comment && <div className="comment">
                    <div className="title">Ваш комментарий</div>
                    {isSend?.comment}
                </div>}
            </div>
            :
            <>
                <form className={isSending ? 'elementLoading' : ''}>
                    <InputX name="name" label="Как к Вам обращаться?" readonly={isSending ? true : false} />
                    <InputX name="communication" label="Как с Вами связаться?" readonly={isSending ? true : false} />
                    <InputX textarea name="comment" label="Комментарий" value={isComment} readonly={isSending ? true : false} />
                </form>
                <ButtonX onUse={onClick.push} full iconUse>
                    {(!isSending && <><IconX name="chat-right-dots" /> Отправить запрос</>)}
                    {(isSending && <><IconX name="code-slash" /> Отправка...</>)}
                </ButtonX>
            </>
        }
    </Block>)
}

export default BlockMiddleFeedBack