import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import IconX from '../../../Components/IconX'
import InputX from '../../../Components/InputX'
import { animationElementError, animationElementClick } from '../../../Components/Animations'
import { DateTime, Kludge, formatName, setHeaderTitle } from '../../../FuncX'
import Loading from '../../../Components/Loading'
import Error from '../../../Components/Error'
import DialogX from '../../../Components/DialogX'
import SelectUser from '../../../Components/SelectUser'
import API from '../../../API'

import './style.scss'
import ContentMenu from '../../../Components/ContentMenu'

const RequestsCreate = ({ User }) => {

    const
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '' }),
        [isDialog, setDialog] = useState(false),
        [isAdmin, setAdmin] = useState(false),
        [isClient, setClient] = useState(false),
        [isCorp, setCorp] = useState(false),
        [isResponsible, setResponsible] = useState(false),
        [isCreated, setCreated] = useState({ status: false, code: 'Неизвестно', id: 'none' }),
        [title, setTitle] = useState(''),
        [description, setDescription] = useState(''),
        [dateTime_limit, setDateTime_limit] = useState(false),
        [isRetryPeriod, setRetryPeriod] = useState(0),
        [isRetryPeriodDateNext, setRetryPeriodDateNext] = useState(new Date())


    const onClick = {

        selectClient: (e) => {

            if (e) animationElementClick(e.currentTarget)

            setDialog({

                id: 'select_client',
                title: 'Выбор клиента',
                content: <>
                    <SelectUser name="client_users" select={async (value) => {

                        setCorp(value?.corp)
                        setClient(value?.user)
                        setDialog(false)
                    }} hide={[]} />
                </>
            })
            return true
        },

        selectResponsible: (e) => {

            if (e) animationElementClick(e.currentTarget)

            setDialog({

                id: 'select_client',
                title: 'Выбор ответственного',
                content: <>
                    <SelectUser name="responsible_users" onlyUsers select={async (value) => {

                        setResponsible(value?.user)
                        setDialog(false)
                    }} hideStatus={['client']} />
                </>
            })
            return true
        },

        create: async (e) => {

            if (isLoading.status) return false

            animationElementClick(e.currentTarget)
            setLoading({ status: true, msg: 'Создание заявки...' })

            const getCompleted = checkCompleted()

            if (getCompleted !== 'ok') {

                animationElementError(document.querySelector(`#inputX.id_${getCompleted}`))
                return setLoading({ status: false })
            }

            let response

            try { response = await API.Requests.Create({ dateTime_limit: dateTime_limit || null, client: !isAdmin ? localStorage?.getItem('MTP|Auth')?.split('|')[0] : isClient?._id, corp: isCorp?._id || false, title, description, responsible: isAdmin ? (isResponsible?._id || null) : null }) }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                setError({ status: true, title: 'создания заявки', msg: response.result })
                setLoading({ status: false })
                return false
            }

            setCreated({ status: true, code: response?.result?.code || isCreated.code, id: response?.result?.id || '' })
            setLoading({ status: false })
            return true
        }
    }

    const onChange = {

        title: (e) => {

            setTitle(e.target.value)
            return true
        },

        description: (e) => {

            setDescription(e.target.value.replace(/<(?!img|code>|\/code>|b>|\/b>|i>|\/i>|u>|\/u>|s>|\/s>).*?>/gm, ''))
            return true
        },

        dateTime_limit: (e) => {

            setDateTime_limit(new Date(e.target.value).toISOString())
            return true
        },

        corp: (e) => {

            e.target.options[0].disabled = true
            if (e.target.value === 'none') setCorp(false)
            else setCorp({ _id: e.target.value })
            return true
        },

        retry: (e) => {

            if (isRetryPeriod > 0) setRetryPeriod(0)
            else {

                const date = new Date()

                date.setHours(0)
                date.setMinutes(0)
                date.setSeconds(0)
                date.setMilliseconds(0)
                setRetryPeriod(1)
                setRetryPeriodDateNext(date)
            }

            return true
        },

        retry_days: (e) => {

            const
                date = new Date(),
                days = Number(e.target.value)

            date.setHours(0)
            date.setMinutes(0)
            date.setSeconds(0)
            date.setMilliseconds(0)

            if (days === NaN || e.target.value?.length < 1) return false
            if (days < 1 || days > 99980145) {

                e.target.value = '1'
                date.setDate(date.getDate() + 1)
                setRetryPeriod(1)
                setRetryPeriodDateNext(date)
                return false
            }

            date.setDate(date.getDate() + (days || 1))
            setRetryPeriod(days || 1)
            setRetryPeriodDateNext(date)
            return true
        }
    }

    const checkCompleted = () => {

        if (isAdmin && !isClient) return 'client'
        if (!title) return 'title'
        if (!description) return 'description'

        return 'ok'
    }

    setHeaderTitle('Заявки > Создать')
    useEffect(() => { if (User) setAdmin((User?.status === 'gAdmin' || User?.status === 'moder') ? true : false) }, [User]) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`МТП | Личный кабинет | Создание заявки`}</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>

        {isDialog &&
            <DialogX id={isDialog.id} title={isDialog.title} full={isDialog.full ?? false} close={() => { setDialog(false) }}>
                {isDialog.content}
            </DialogX>
        }

        <div id="Create">
            {isLoading.status ?
                <Loading module >{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            <ContentMenu>
                                <Link to="/lk/req"><IconX name="chevron-left" /> Вернуться</Link>
                            </ContentMenu>

                            <div className='info'>
                                {!isCreated.status ?
                                    <>
                                        {isAdmin &&
                                            <>
                                                <InputX
                                                    name="client"
                                                    label={!isCorp && !isClient ? 'Выбрать клиента/организацию' : 'Клиент'}
                                                    onClick={onClick.selectClient}
                                                    value={isCorp ? `${isCorp.name} > ${formatName(isClient, false, false)}` : isClient ? formatName(isClient, false, false) : ''}
                                                    readonly
                                                />
                                                <InputX
                                                    name="responsible"
                                                    label={!isResponsible ? 'Выбрать ответственного' : 'Ответственный'}
                                                    onClick={onClick.selectResponsible}
                                                    value={isResponsible ? formatName(isResponsible, false, false) : ''}
                                                    readonly
                                                />
                                            </>
                                        }
                                        {User?.corps?.length > 0 && !isAdmin &&
                                            <InputX select name='corp' label='Выберите организацию' required={true} handle={onChange.corp}>
                                                <option value="default" selected>Выберите организацию...</option>
                                                <option value="none">Без организации</option>
                                                {User?.corps.map((corpParce, index) => (
                                                    <option key={index} value={corpParce?._id}>{corpParce?.name}</option>
                                                ))}
                                            </InputX>
                                        }
                                        <InputX name="title" label="Название заявки" handle={onChange.title} value={title} required={true} />
                                        <InputX textareaHTML name="description" label="Описание заявки" handle={onChange.description} value={description} required={true} />
                                        <div className="sub">
                                            <InputX name="dateTime_limit" type='datetime-local' label="Дедлайн (не обязательно)" handle={onChange.dateTime_limit} value={dateTime_limit} />

                                            <div className="retryRequest">
                                                <InputX
                                                    checkbox
                                                    name="retry"
                                                    label="Повтор заявки"
                                                    handle={onChange.retry}
                                                />
                                                {isRetryPeriod > 0 && <>
                                                    <InputX name="retry_days" type='number' label={`1 раз в ${isRetryPeriod} дней`} value={isRetryPeriod} handle={onChange.retry_days} />
                                                    <div className="next">Следующий повтор: {DateTime.fix(isRetryPeriodDateNext).text}</div>
                                                </>}
                                            </div>
                                        </div>
                                        <div className='btn_default' onClick={onClick.create}><IconX name='plus-lg' /> Создать</div>
                                    </>
                                    :
                                    <div className='created'>
                                        <b>Заявка #{isCreated.code}</b> успешно создана
                                        <a href={`/lk/req/${isCreated.id}`}><div className="btn_default">Перейти к заявке - {title}</div></a>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </>
            }
        </div>
    </>)
}

export default RequestsCreate