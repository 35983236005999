import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import AlertX from '../../Components/AlertX'
import IconX from '../../Components/IconX'
import { DateTime, setHeaderTitle } from '../../FuncX'
import Loading from '../../Components/Loading'
import Error from '../../Components/Error'
import { animationElementClick } from '../../Components/Animations'
import API from '../../API'

import './style.scss'

const Apps = ({ User }) => {

    const isInit = { mount: false }

    const
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [isAppList, setAppList] = useState([])

    const onClick = {

        togleInfo: (e) => {

            const app = e.currentTarget.closest('.app')

            animationElementClick(e.currentTarget)

            if (app.classList.contains('close')) {

                app.classList.remove('close')
                app.classList.add('open')
            } else {

                app.classList.remove('open')
                app.classList.add('close')
            }
        }
    }

    const update = () => new Promise(async (resolve) => {

        setError({ status: false })
        setLoading({ status: true, msg: 'Загрузка приложений...' })

        let response

        try { response = await API.Apps.Get() }
        catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            setError({ status: true, title: 'загрузки приложений', msg: response.result })
            setLoading({ status: false })
            return resolve(false)
        }

        setAppList(response.result)
        setLoading({ status: false })
        return resolve(true)
    })

    const Mount = () => {

        isInit.mount = true
        update()
        setHeaderTitle('Приложения')
        
        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`МТП | Личный кабинет | Приложения`}</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div id="Apps">
            {isLoading?.status ?
                <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            {isAppList.length < 1 ?
                                <AlertX type="info" icon={<IconX name="window-stack" />}>Сейчас для вас отсутствуют приложения 🤷<br /><br /><i>Обратитесь к нам если вы уверены, что оно должно быть у вас!</i></AlertX>
                                :
                                <>

                                    {isAppList?.map(({ _id, active, name, name_url, icon, description, version }) => (
                                        <div key={_id} className="app close">
                                            <div className="head" onClick={onClick.togleInfo}>
                                                <div className="icon"><img src={icon} alt={name} /></div>
                                                <div className="info">
                                                    <div className="title">{name}</div>
                                                    <div className="version">Версия: <b>{version?.version}</b></div>
                                                    <div className="dateTime">От {DateTime.fix(new Date(version?.dateTime_create)).text}</div>
                                                </div>
                                                <Link className="more" to={'/lk/apps/' + name_url}><IconX name="arrow-right-circle" /></Link>
                                            </div>
                                            <div className="description">
                                                <div className="what">Описание</div>
                                                <div className="text" dangerouslySetInnerHTML={{ __html: description || '-' }}></div>
                                            </div>
                                            <div className="lastUpdate">
                                                <div className="what">Что обновилось в текущей версии?</div>
                                                <div className="text" dangerouslySetInnerHTML={{ __html: version?.comment || '-' }}></div>
                                            </div>
                                        </div>
                                    ))}
                                </>
                            }
                        </>
                    }
                </>
            }
        </div>
    </>)
}

export default Apps