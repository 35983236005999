import { useState } from 'react'
import IconX from '../IconX'

import './style.scss'
import { animationElementClick } from '../Animations'
import { DateTime } from '../../FuncX'



const ButtonX = ({ children, active = true, noUse = false, confirm = false, confirmTime = 3, name = 'noNAME', onUse = (e) => animationElementClick(e.currentTarget), full = false, red = false, green = false, iconUse = false, ...props }) => {

    const
        [isClick, setClick] = useState(false),
        [isUse, setUse] = useState(false),
        [isTimeText, setTimeText] = useState(false)

    let isTime = { _: null, time: false }

    const onClick = {

        use: (e) => {

            if (!active) return false

            animationElementClick(e.currentTarget)
            return onUse(document?.querySelector(`#ButtonX.${name}`))
        },

        confirmWait: (e) => {

            if (isClick) return false

            animationElementClick(e.currentTarget)
            setClick(true)
            setUse(false)
            timerMod.start(confirmTime, () => { setUse(true) })
            return true
        },

        confirmAbort: (e) => {

            if (!isClick) return false

            animationElementClick(e.currentTarget)
            setUse(false)
            setClick(false)
            return true
        },

        confirmAccept: (e) => {

            if (!isClick) return false
            if (!isUse) return false

            animationElementClick(e.currentTarget)
            setUse(false)
            setClick(false)
            return onUse(document?.querySelector(`#ButtonX.${name}`))
        }
    }

    const timerMod = {

        start: (time, callback) => {

            setTimeText(time)
            isTime = { _: setTimeout(() => { timerMod.check(callback) }, 1000), time }
        },

        check: (callback) => {

            setUse(false)

            if (isTime.time === false) {

                isTime = { _: null, time: false }
                setTimeText(false)
                return false
            }

            isTime.time--

            if (isTime.time <= 0) {

                callback()
                isTime = { _: null, time: false }
                setTimeText(false)
            } else {

                setTimeText(isTime.time)
                isTime._ = setTimeout(() => { timerMod.check(callback) }, 1000)
            }
        }
    }

    return (<div
        id="ButtonX"
        className={`${name}${full ? ' full' : ''}${confirm ? ' confirm' : ''}${isClick ? ' clicked' : ''}${red ? ' red' : ''}${green ? ' green' : ''}${noUse ? ' noUse' : ''}${iconUse ? ' iconUse' : ''}`}
        onClick={confirm ? onClick.confirmWait : noUse ? null : onClick.use}
        {...props}
    >
        {confirm ?
            <>
                {isClick ?
                    <>
                        <div className="abort use" onClick={onClick.confirmAbort}><IconX name="x-lg" /></div>
                        <div className="text">{confirm}</div>
                        <div className={`accept ${isUse ? ' use' : ''}`} onClick={onClick.confirmAccept}>{isTimeText ? <IconX name={`${isTimeText}-circle`} /> : <IconX name="check-lg" />}</div>
                    </>
                    :
                    <>{children}</>
                }
            </>
            :
            <>{children}</>
        }
    </div>)
}

export default ButtonX