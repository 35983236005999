// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getMessaging, getToken } from "firebase/messaging"
// import { getAnalytics } from "firebase/analytics"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {

    apiKey: "AIzaSyDwitXQ0poM83VdibSXCy7YazMrqqx-wys",
    authDomain: "magtehpro.firebaseapp.com",
    projectId: "magtehpro",
    storageBucket: "magtehpro.appspot.com",
    messagingSenderId: "33606052019",
    appId: "1:33606052019:web:d3fd868b389ed50816e8c6",
    measurementId: "G-Z588CM5C1C"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig)
// const analytics = getAnalytics(app)

const app = initializeApp(firebaseConfig)
export const messaging = getMessaging(app)

export const generateToken = () => new Promise( async(resolve) => {
    
    const permission = await Notification.requestPermission()

    if (permission === 'granted') {

        const token = await getToken(messaging, { vapidKey: "BFydXzh-xCpFv7-AcvyatvZkBnQHN6u_w6R18OAaSEtTyQYrgVGl0pfFDbeUKkBbXXJEIgFHT3BsdiT03TLCIws" })

        // console.log("firebase | generateToken | token", token)
        return resolve(token)
    }

    return resolve('NONE')
})

navigator.serviceWorker.onmessage = (event) => {

    console.log('serviceWorker | event', event)
    
    if (event.data.messageType === 'notification-clicked') {

        window.location.href = event.data.notification.click_action
    }
}