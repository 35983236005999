import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { setHeaderTitle } from '../../../FuncX'
import IconX from '../../../Components/IconX'
import { animationElementClick } from '../../../Components/Animations'
import AdminReportsIncome from './Income'
import AdminReportsExpenses from './Expenses'
import ContentMenu from '../../../Components/ContentMenu'

import './style.scss'

const AdminReports = ({ User }) => {

    const
        isInit = { mount: false }

    const
        [isCurrent, setCurrent] = useState(false),
        [isView, setView] = useState(<></>)

    const onClick = {

        selectType: (e) => {

            animationElementClick(e.currentTarget)
            setCurrent(e?.currentTarget?.className)
            e.currentTarget.id = 'OOOO'
            return true
        }
    }

    const render = () => {

        switch (isCurrent) {

            case 'income': {

                setView(<AdminReportsIncome />)
                break
            }

            case 'expenses': {

                setView(<AdminReportsExpenses />)
                break
            }

            default: setView(<div className="message">Выберите тип отчёта...</div>)
        }
    }

    const Mount = () => {

        isInit.mount = true
        render()
        setHeaderTitle('Управление > Отчёты')

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, [isCurrent]) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>МТП | Личный кабинет | Управление | Отчёты</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div id="Admin_Reports">
            <ContentMenu>
                <Link to='/lk/adm'><IconX name='chevron-left' />Управление</Link>
            </ContentMenu>

            <div className="reports">
                <div id={isCurrent === 'income' ? 'select' : ''} className="income" onClick={onClick.selectType}><IconX name="cash-stack" /> Поступления</div>
                <div id={isCurrent === 'expenses' ? 'select' : ''} className="expenses" onClick={onClick.selectType}><IconX name="cash" /> Расходы</div>
            </div>
            <div className="view">{isView}</div>
        </div>
    </>)
}

export default AdminReports