import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Loading from '../../../Components/Loading'
import Error from '../../../Components/Error'
import IconX from '../../../Components/IconX'
import LinkX from '../../../Components/LinkX'
import ListX from '../../../Components/ListX'
import { animationElementClick } from '../../../Components/Animations'
import { DateTime, formatName, setHeaderTitle } from '../../../FuncX'
import AlertX from '../../../Components/AlertX'
import API from '../../../API'

import './style.scss'
import ContentMenu from '../../../Components/ContentMenu'

const Admin_News = ({ User }) => {

    const
        isInit = { mount: false }

    const
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [isData, setData] = useState([]),
        [isUpdate, setUpdate] = useState(false)


    const onClick = {

        create: (e) => {

            animationElementClick(e.currentTarget)
            return true
        },

        update: async (e) => {

            if (isUpdate) return false

            animationElementClick(e.currentTarget)
            await update(true)
            return true
        }
    }

    const update = (silent = false, noRetry = false) => new Promise(async (resolve) => {

        let response

        setError({ status: false })
        setUpdate(true)

        if (!silent) setLoading({ status: true, msg: 'Загрузка списка новостей...' })

        try { response = await API.News.Get() }
        catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            if (!silent) setError({ status: true, title: 'загрузки списка новостей', msg: response.result })

            return resolve(false)
        }

        setData(response?.result)
        setLoading({ status: false })
        setUpdate(false)
        return resolve(true)
    })

    const Mount = () => {

        isInit.mount = true
        update()
        setHeaderTitle('Управление > Новости')

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>МТП | Личный кабинет | Управление | Новости</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div id="Admin_News">
            <ContentMenu>
                <Link to='/lk/adm'><IconX name='chevron-left' />Управление</Link>
                <Link to='/lk/adm/news/new'><IconX name="plus-lg" />Создать</Link>
                <div className={isUpdate ? 'elementLoading' : ''} onClick={onClick.update}><IconX name="arrow-clockwise" /></div>
            </ContentMenu>
            
            {isLoading?.status ?
                <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            {isData?.length > 0 ?
                                <ListX
                                    name="newsList"
                                    header={<>
                                        <div>#</div>
                                        <div>Дата и Время</div>
                                        <div>Автор</div>
                                        <div>Заголовок</div>
                                    </>}
                                    footer={`Отображено новостей: ${isData?.length}`}
                                >
                                    {isData?.map(({ _id, dateTime_create, author, title }, idx) => (
                                        <LinkX className="content" key={_id} to={`/lk/adm/news/${_id}`}>
                                            <div>{idx + 1}</div>
                                            <div>{DateTime.fix(new Date(dateTime_create)).text}</div>
                                            <div>{formatName(author, true)}</div>
                                            <div>{title}</div>
                                        </LinkX>
                                    ))}
                                </ListX>
                                :
                                <AlertX icon={<IconX name="info" />}>Список новостей пуст</AlertX>
                            }
                        </>
                    }
                </>
            }
        </div >
    </>)
}

export default Admin_News
