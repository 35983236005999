import React from 'react'

import './style.scss'



const parceMessage = (msg) => {

    if (!msg) return null

    switch (msg?.toLowerCase()) {

        case 'incorrect_rights': return <>Нет нужных прав доступа для совершения этого действия</>
        case 'no_find_todo_item': return <>Не найден элемент в TODO листе</>
        case 'no_access_device': return <>У вас нет доступа к этому устройству</>
        case 'no_access_data': return <>У вас нет доступа к этим данным</>
        case 'incorrect_data': return <>Сервер отверг запрос к API из-за отсутсвия поля или неверного поля - <b>data</b></>
        case 'incorrect_name': return <>Сервер отверг запрос к API из-за отсутсвия или неверного поля - <b>name</b></>
        case 'incorrect_param_type': return <>Сервер отверг запрос к API из-за отсутсвия или неверного поля - <b>type</b></>
        case 'network error': return <>Проблема c доступом к серверу API</>
        case 'document_delete': return <>Документ удалён</>
        case 'request failed with status code 404': return <>Запрос не найден в API сервера</>
        case 'request failed with status code 500': return <>Внутренняя ошибка сервера</>
        default: {

            if (msg.toLowerCase().includes('_api__webpack_imported_module')) return <>Неизвестная функция сайта</>

            return msg
        }
    }
}

const Error = ({ children, fatal = false, title, msg = false }) => (<div id="Error" className={`${fatal ? 'fatal' : ''}`}>
    <div className='content'>
        <div className='title'>{fatal ? 'Фатальная ошибка' : 'Ошибка'} {title}</div>
        <div className='description'>{parceMessage(msg) || parceMessage(children)}</div>
        <div className='ps'>Сфотографируйте или запишите ошибку, чтобы её можно было передать разработчику.</div>
    </div>
</div>)

export default Error