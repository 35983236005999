import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { setHeaderTitle } from '../../../../FuncX'
import Loading from '../../../../Components/Loading'
import Error from '../../../../Components/Error'
import IconX from '../../../../Components/IconX'
import { animationElementClick, animationElementError } from '../../../../Components/Animations'
import InputX from '../../../../Components/InputX'
import ButtonX from '../../../../Components/ButtonX'
import ContentMenu from '../../../../Components/ContentMenu'
import API from '../../../../API'

import './style.scss'

const Admin_Works_New = ({ User }) => {

    const
        _def = { isInit: { mount: false, loading: false } },
        _ = _def

    const
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [isCreated, setCreated] = useState({ status: false, id: 'none' }),
        [isServices, setServices] = useState([]),
        [isName, setName] = useState(''),
        [isCost, setCost] = useState(0),
        [isType, setType] = useState(false),
        [isService, setService] = useState(false)

    const onClick = {

        create: async (e) => {

            if (isLoading.status) return false

            setError({ status: false })
            animationElementClick(e.currentTarget)
            setLoading({ status: true, msg: 'Создание услуги...' })

            const completed = checkCompleted()

            if (completed !== 'ok') {

                animationElementError(document.querySelector(`#inputX.id_${completed}`), true)
                return setLoading({ status: false })
            }

            let res

            try { res = await API.Works.Create(isName, isCost, isType, isService) }
            catch (error) { res = { status: false, result: error.message } }

            if (!res.status) {

                setError({ status: true, title: 'создания услуги', msg: res.result })
                setLoading({ status: false })
                return false
            }

            setCreated({ status: true, id: res?.result?.id || '' })
            setLoading({ status: false })
            return true
        }
    }

    const onChange = {

        name: (e) => setName(e?.target?.value || ''),

        cost: (e) => setCost(Number(e?.target?.value || 0) || 0),

        type: (e) => {

            e.target.options[0].disabled = true
            if (e.target.value === 'none') setType(false)
            else setType(e.target.value)
            return true
        },

        service: (e) => {

            e.target.options[0].disabled = true
            if (e.target.value === 'none') setService(false)
            else setService(e.target.value)
            return true
        },
    }

    const checkCompleted = () => {

        if (!isName) return 'name'
        if (!isCost) return 'cost'
        if (!isType) return 'type'

        return 'ok'
    }

    const getData = () => new Promise(async (resolve) => {

        if (_.isInit.loading) return false

        _.isInit.loading = true

        setError({ status: false })
        setLoading({ status: true, msg: 'Загрузка типов услуг...' })

        const services = await getServices()

        if (!services.status) {

            setError({ status: true, title: 'загрузки типов услуг', msg: services?.result })
            setLoading({ status: false })
            _.isInit.loading = false
            return resolve(false)
        }

        setServices(services?.result)
        setLoading({ status: false })
        _.isInit.loading = false
        return resolve(true)
    })

    const getServices = () => new Promise(async (resolve) => {

        try { return resolve(await API.Services.Get()) }
        catch (error) { return resolve({ status: false, result: error?.message }) }
    })

    const Mount = () => {

        _.isInit.mount = true
        getData()
        setHeaderTitle('Управление > Услуги > Создание')

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        _.isInit = _def.isInit
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>МТП | Личный кабинет | Управление | Услуги | Создание</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div id="Admin_Works_New">
            <ContentMenu>
                <Link to="/lk/adm/works"><IconX name="chevron-left" />Вернуться</Link>
            </ContentMenu>

            {isLoading?.status ?
                <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <div className='info'>
                            {!isCreated.status ?
                                <>
                                    <InputX name="name" label="Наименование" handle={onChange.name} />
                                    <InputX type="number" name="cost" label="Цена" handle={onChange.cost} />
                                    <InputX select name="type" handle={onChange.type}>
                                        <option value="default" selected>Выберите тип цены...</option>
                                        <option value="unit">за единицу</option>
                                        <option value="metr">за метр</option>
                                        <option value="pmetr">за погонный метр</option>
                                        <option value="timeHour">почасовая</option>
                                        <option value="income">прибыль</option>
                                        <option value="expenses">расходы</option>
                                    </InputX>
                                    <InputX select name="type" handle={onChange.service}>
                                        <option value="default" selected>Выберите тип услуги...</option>
                                        {isServices?.map(({ _id, name }) => (<option value={_id}>{name}</option>))}
                                    </InputX>
                                    <ButtonX onUse={onClick.create} full><IconX name='plus-lg' /> Создать</ButtonX>
                                </>
                                :
                                <div className="created">
                                    <div className="title">Услуга успешно создана!</div>
                                    <a href={`/lk/adm/works/${isCreated.id}`}><ButtonX iconUse full>Перейти к услуге - {isName}</ButtonX></a>
                                </div>
                            }
                        </div>
                    }
                </>
            }
        </div >
    </>)
}

export default Admin_Works_New
