import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Loading from '../../Components/Loading'
import Error from '../../Components/Error'
import InputX from '../../Components/InputX'
import IconX from '../../Components/IconX'
import ButtonX from '../../Components/ButtonX'
import AlertX from '../../Components/AlertX'
import { animationElementClick, animationElementLoadingStart, animationElementLoadingStop } from '../../Components/Animations'
import DialogX from '../../Components/DialogX'
import { formatName, setHeaderTitle } from '../../FuncX'
import ContentMenu from '../../Components/ContentMenu'
import API from '../../API'

import './style.scss'

const Access = ({ User }) => {

    const isInit = { mount: false }

    const
        defaultPath = { path: 'main', name: 'Хранилище' },
        [isDialog, setDialog] = useState(false),
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '' }),
        [isLoadingBackground, setLoadingBackground] = useState(false),
        [isData, setData] = useState({ main: [] }),
        [isPath, setPath] = useState(localStorage?.getItem('MTP|Module.Access|isPath') || 'main'),
        [isPathHistory, setPathHistory] = useState(JSON?.parse(localStorage?.getItem('MTP|Module.Access|isPathHistory')) || [defaultPath]),
        [isCurrentFolderInfo, setCurrentFolderInfo] = useState(false),
        [isAdmin, setAdmin] = useState(false),
        [isClient, setClient] = useState(JSON?.parse(localStorage?.getItem('MTP|Module.Access|isClient')) || User),
        [isCorp, setCorp] = useState(JSON?.parse(localStorage?.getItem('MTP|Module.Access|isCorp')) || false),
        [isViewList, setViewList] = useState([])

    const updateData = (stealth = false) => new Promise(async (resolve) => {

        let response

        if (!stealth) {

            setError({ status: false })
            setLoading({ status: true, msg: 'Загрузка доступов...' })
        } else setLoadingBackground(true)

        try { response = await API.Access.Get() }
        catch (error) {

            if (!stealth) setLoading({ status: false })

            setError({ status: true, title: 'загрузки доступов', msg: error.message })
            return resolve(false)
        }

        if (!response.status) {

            if (!stealth) setLoading({ status: false })

            setError({ status: true, title: 'загрузки доступов', msg: response.result })
            return resolve(false)
        }

        const parce = {}

        for (const item of response.result) {

            if (parce[item.path]) parce[item.path].push(item)
            else parce[item.path] = [{ ...item }]

            if (item.type === 'folder') {

                if (!parce[item._id]) parce[item._id] = []
            }
        }

        if (User && User?.status === 'gAdmin') setAdmin(true)

        setData(parce)
        if (!stealth) setLoading({ status: false })
        else setLoadingBackground(false)
        return resolve(true)
    })

    const onClick = {

        update: async (e) => {

            animationElementClick(e.currentTarget)
            await updateData(true)
            return true
        },

        selectAccess: async (e) => {

            if (!isAdmin) return false

            animationElementClick(e.currentTarget)
            await Clients.Get()
            return true
        },

        itemCreate: (e) => {

            animationElementClick(e.currentTarget)
            setDialog({

                id: 'create_item',
                title: 'Создание нового документа',
                content: <>
                    <InputX name='itemName' label='Имя документа' />
                    {isCorp && <InputX name='itemAccessLevel' label='Уровень доступа' />}
                    <div className='btn_default' onClick={async (e) => {

                        animationElementLoadingStart(e.currentTarget)

                        let response
                        const corp = isCorp ? isCorp._id : false
                        const client = corp ? null : isAdmin ? isClient._id : User._id

                        try { response = await API.Access.ItemCreate(client, corp, isPath, document.querySelector('.id_itemName>input').value, isCorp ? document.querySelector('.id_itemAccessLevel>input').value : 'full') }
                        catch (error) { response = { status: false, result: error.message } }

                        if (!response.status) {

                            alert(response.result)
                            return false
                        }

                        await updateData(true)
                        animationElementLoadingStop(e.currentTarget)
                        return setDialog(false)
                    }}><IconX name="file-earmark-plus" /> Создать документ</div>
                </>
            })
            return true
        },

        folderCreate: (e) => {

            animationElementClick(e.currentTarget)
            setDialog({

                id: 'create_folder',
                title: 'Создание новой папки',
                content: <>
                    <InputX name='folderName' label='Имя папки' />
                    {isCorp && <InputX name='folderAccessLevel' label='Уровень доступа' />}
                    <div className='btn_default' onClick={async (e) => {

                        animationElementLoadingStart(e.currentTarget)

                        let response
                        const corp = isCorp ? isCorp._id : false
                        const client = corp ? null : isAdmin ? isClient._id : User._id

                        try { response = await API.Access.FolderCreate(client, corp, isPath, document.querySelector('.id_folderName>input').value, isCorp ? document.querySelector('.id_folderAccessLevel>input').value : 'full') }
                        catch (error) { response = { status: false, result: error.message } }

                        if (!response.status) {

                            alert(response.result)
                            return false
                        }

                        await updateData(true)
                        animationElementLoadingStop(e.currentTarget)
                        return setDialog(false)
                    }}><IconX name="folder-plus" /> Создать папку</div>
                </>
            })
            return true
        },

        folderEnter: (e) => {

            const
                path = e.target.dataset.path,
                name = e.target.dataset.name,
                tmpPathHistory = isPathHistory

            if (!path || !name) return false

            setPath(path)
            tmpPathHistory.push({ path, name })
            setPathHistory(tmpPathHistory)

            localStorage.setItem('MTP|Module.Access|isPath', path)
            localStorage.setItem('MTP|Module.Access|isPathHistory', JSON.stringify(tmpPathHistory))
            return true
        },

        folderBack: (_) => {

            const tmpPathHistory = isPathHistory

            tmpPathHistory.pop()

            if (tmpPathHistory.length < 1) {

                setPath(defaultPath.path)
                tmpPathHistory.push(defaultPath)
                localStorage.setItem('MTP|Module.Access|isPath', defaultPath.path)
            } else {

                setPath(tmpPathHistory.at(-1).path)
                localStorage.setItem('MTP|Module.Access|isPath', tmpPathHistory.at(-1).path)
            }

            setPathHistory(tmpPathHistory)
            localStorage.setItem('MTP|Module.Access|isPathHistory', JSON.stringify(tmpPathHistory))
            return true
        },

        folderDelete: async (e) => {

            animationElementLoadingStart(e.currentTarget)

            let response

            if (!isCurrentFolderInfo) return false

            try { response = await API.Access.FolderDelete(isCurrentFolderInfo._id) }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                alert(response.result)
                return false
            }

            onClick.folderBack()
            await updateData(true)
            animationElementLoadingStop(e.currentTarget)
        }
    }

    const onChange = {

        corp: (e) => {

            const value = e.target.value

            if (value === undefined || value === null) return false
            if (value === 'none') {

                setCorp(false)
                setPath(defaultPath.path)
                setPathHistory([defaultPath])
                return false
            }

            const corp = User?.corps[value]

            if (corp === undefined || corp === null) return false

            setCorp(corp)
            setPath(defaultPath.path)
            setPathHistory([defaultPath])
            return true
        }
    }

    const Clients = {

        type: 'individuals',
        select: { corp: null, client: null },
        data: null,

        Get: () => new Promise(async (resolve) => {

            let response

            setDialog({

                id: 'select_client',
                title: 'Выбор клиента',
                content: <Loading module>Загрузка клиентов</Loading>
            })

            try { response = await API.Users.GetClients() }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                setDialog({

                    id: 'select_client',
                    title: 'Выбор клиента',
                    content: <Error title="загрузки клиентов" msg={response.result} />
                })
                return resolve(false)
            }

            Clients.data = response.result
            Clients.ReRender()
            return true
        }),

        GetIndividuals: () => <>
            {Clients?.data?.individuals?.map((item, index) => (
                <>
                    <div key={index} data-id={item?._id} data-type="individuals" className='btn_default user' onClick={(e) => Clients.SetClient(e, item)}><IconX name='person' /> {item?.name?.last} {item?.name?.first} {item?.name?.middle}</div>
                </>
            ))}
        </>,

        GetCorps: () => <>
            {Clients?.data?.corps?.map((item, index) => (
                <>
                    <div key={index} data-type="corps" className='btn_default user' onClick={(e) => Clients.SetCorp(e, item)}><IconX name='building' /> {item?.name}</div>
                </>
            ))}
        </>,

        SetList: (e, type) => {

            if (Clients.type === type || Clients.type === type + 'Select') return false

            animationElementClick(e.currentTarget)

            Clients.type = type
            Clients.ReRender()
            return true
        },

        SetClient: (e, item) => {

            if (e) animationElementClick(e.currentTarget)

            setCorp(false)
            setClient(item)
            setPath(defaultPath.path)
            setPathHistory([defaultPath])
            setDialog(false)
            localStorage.setItem('MTP|Module.Access|isClient', JSON.stringify({ _id: item?._id, name: item?.name }))
            localStorage.removeItem('MTP|Module.Access|isCorp')
            localStorage.setItem('MTP|Module.Access|isPath', defaultPath.path)
            localStorage.setItem('MTP|Module.Access|isPathHistory', JSON.stringify([defaultPath]))
            return true
        },

        SetCorp: (e, item) => {

            animationElementClick(e.currentTarget)
            setClient(false)
            setCorp(item)
            setPath(defaultPath.path)
            setPathHistory([defaultPath])
            setDialog(false)
            localStorage.setItem('MTP|Module.Access|isCorp', JSON.stringify({ _id: item?._id, name: item?.name }))
            localStorage.removeItem('MTP|Module.Access|isClient')
            localStorage.setItem('MTP|Module.Access|isPath', defaultPath.path)
            localStorage.setItem('MTP|Module.Access|isPathHistory', JSON.stringify([defaultPath]))
            return true
        },

        ReRender: () => {

            setDialog({

                id: 'select_client',
                title: 'Выбор клиента',
                content: <>
                    <div className='types'>
                        <div className={`type${Clients.type === 'individuals' ? ' active' : ''}`} onClick={(e) => Clients.SetList(e, 'individuals')}><IconX name='person' />Физ. Лица</div>
                        <div className={`type${Clients.type === 'corps' || Clients.type === 'corpsSelect' ? ' active' : ''}`} onClick={(e) => Clients.SetList(e, 'corps')}><IconX name='building' />Юр. Лица</div>
                    </div>
                    <div className="list">
                        {Clients.type === 'individuals' && <>{Clients.GetIndividuals()}</>}
                        {Clients.type === 'corps' && <>{Clients.GetCorps()}</>}
                    </div>
                </>
            })
        }
    }

    const findCorpUser = () => {

        if (!User) return false
        if (!isData) return false
        if (!isCurrentFolderInfo) return false
        if (!isCurrentFolderInfo?.corp) return false

        for (const corp of User.corps) {

            if (corp._id === isCurrentFolderInfo.corp._id) return corp
        }

        return false
    }

    const getAccessLevelCorp = (accessCheck) => {

        const corp = findCorpUser()

        if (!corp) return false

        for (const access of corp.accessLevels) {

            if (access === accessCheck) return true
        }

        return false
    }

    const checkPermissionEdit = (typeList = []) => {

        if (!User) return false
        if (!isData) return false
        if (!isCurrentFolderInfo) return false

        for (const type of typeList) {

            switch (type) {

                case 'admin': {

                    if (User.status === 'gAdmin') return true
                    if (User.status === 'moder') return true

                    break
                }

                case 'author': {

                    if (User.status === 'gAdmin') return true
                    if (User.status === 'moder') return true
                    if (getAccessLevelCorp('full')) return true
                    if (isCurrentFolderInfo && User._id === isCurrentFolderInfo.author._id) return true

                    break
                }

                default: { }
            }
        }

        return false
    }

    const parceList = () => {

        const viewList = []

        if (!isData[isPath]) return setViewList(viewList)

        for (const item of isData[isPath]) {

            if (isCorp) {

                if (item?.corp && isCorp?._id === item?.corp?._id) viewList.push(item)
            } else {

                if (isAdmin && isClient) {

                    if (item?.client?._id === isClient?._id) viewList.push(item)
                } else if (item?.corp?._id === undefined) viewList.push(item)
            }
        }

        if (isPath === 'main') setCurrentFolderInfo(false)
        else {

            const rootFolder = isData[isPathHistory[isPathHistory.length - 2]?.path]
            let findInfo = false

            if (rootFolder) {

                for (const folder of rootFolder) {

                    if (folder?._id === isPath) {

                        findInfo = folder
                        break
                    }
                }
            }

            setCurrentFolderInfo(findInfo)
        }

        setViewList(viewList)
        return true
    }

    const Mount = () => {

        isInit.mount = true
        setHeaderTitle('Доступы')

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { parceList() }, [isData, isClient, isCorp, isPath]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {

        if (User) {

            updateData()

            if (!isClient) setClient(User)
        }
    }, [User]) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>

        <Helmet>
            <meta charSet="utf-8" />
            <title>МТП | Личный кабинет | Доступы</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>

        {isDialog &&
            <DialogX id={isDialog.id} title={isDialog.title} full={isDialog.full ?? false} close={() => { setDialog(false) }}>
                {isDialog.content}
            </DialogX>
        }

        <div id="Access">
            {isLoading.status ?
                <Loading module >{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            <ContentMenu>
                                <div><IconX name="folder-plus" onClick={onClick.folderCreate} /></div>
                                <div><IconX name="file-earmark-plus" onClick={onClick.itemCreate} /></div>
                                <div className={isLoadingBackground ? 'elementLoading' : ''}><IconX name="arrow-clockwise" onClick={onClick.update} /></div>
                                {isAdmin && <ButtonX onUse={onClick.selectAccess}>Выбрать клиента/организацию</ButtonX>}

                                {User && User?.corps && User?.corps?.length > 0 && !isAdmin && <InputX name="selectCorp" select={true} handle={onChange.corp} defaultValue={'none'}>
                                    <option value="none">Без организации</option>
                                    {User?.corps.map((corpParce, idx) => (
                                        <option key={idx} value={idx}>{corpParce?.name}</option>
                                    ))}
                                </InputX>}
                            </ContentMenu>

                            {Object.keys(isData).length < 1 ?
                                <AlertX type="info" icon={<IconX name="info-lg" />}>Ваше хранилище пусто</AlertX>
                                :
                                <>
                                    <div className="currentPath">
                                        <div>Хранилище<div>&nbsp;{isCorp ? `(${isCorp.name})` : isClient ? `(${formatName(isClient, false, false)})` : ''}</div></div>
                                        {isPathHistory.map((path, idx) => (
                                            <>{idx !== 0 && <><IconX name="arrow-right-short" /> <div>{path.name}</div></>}</>
                                        ))}
                                    </div>
                                    <div className="list">
                                        {isViewList?.length > 0 ?
                                            <>
                                                {isPath !== 'main' && <div className="folderBack" style={{ animationName: 'showContent' }} onClick={onClick.folderBack}><IconX name="folder-symlink" /> Вернуться назад</div>}
                                                {isViewList
                                                    .sort((a, b) => {

                                                        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
                                                        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1

                                                        return 0
                                                    })
                                                    .sort((a, b) => {

                                                        if (a.type > b.type) return 1
                                                        if (a.type < b.type) return -1

                                                        return 0
                                                    })
                                                    .map(({ _id, active, type, name }, idx) => (<div key={idx}>
                                                        <>
                                                            {type === 'folder' &&

                                                                <div
                                                                    key={_id}
                                                                    className={`folder${!active ? ' delete' : ''}`}
                                                                    style={{ animationName: 'showContent' }}
                                                                    data-path={_id}
                                                                    data-name={name}
                                                                    onClick={onClick.folderEnter}>
                                                                    {!active ? <IconX name="trash-fill" /> : ''}<IconX name="folder2" /> {name}
                                                                </div>}
                                                            {type === 'item' &&

                                                                <Link
                                                                    key={_id}
                                                                    to={`/lk/access/${_id}`}
                                                                    className={`item${!active ? ' delete' : ''}`}
                                                                    style={{ animationName: 'showContent' }}>
                                                                    {!active ? <IconX name="trash-fill" /> : ''}<IconX name="file-earmark-lock" /> {name}
                                                                </Link>
                                                            }
                                                        </>
                                                    </div>))}
                                            </>
                                            :
                                            <>
                                                {isPath === 'main' ?
                                                    <AlertX icon={<IconX name="info-lg" />}>Это хранилище пустое</AlertX>
                                                    :
                                                    <>
                                                        <div className='folderBack' style={{ animationName: 'showContent' }} onClick={onClick.folderBack}><IconX name="folder-symlink" /> Вернуться назад</div>
                                                        {checkPermissionEdit(['author']) && <div className="folderDelete" style={{ animationName: 'showContent' }} onClick={onClick.folderDelete}><IconX name="folder-x" /> Удалить папку</div>}
                                                        <AlertX icon={<IconX name="info-lg" />}>Эта папка пустая</AlertX>
                                                    </>
                                                }
                                            </>
                                        }
                                    </div>
                                </>
                            }
                        </>
                    }
                </>
            }
        </div>
    </>)
}

export default Access