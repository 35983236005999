import React, { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import ContentMenu from '../../../Components/ContentMenu'
import Loading from '../../../Components/Loading'
import Error from '../../../Components/Error'
import IconX from '../../../Components/IconX'
import LinkX from '../../../Components/LinkX'
import ListX from '../../../Components/ListX'
import TabsX from '../../../Components/TabsX'
import { animationElementClick } from '../../../Components/Animations'
import { setHeaderTitle } from '../../../FuncX'
import AlertX from '../../../Components/AlertX'
import API from '../../../API'

import './style.scss'

const Admin_Works = ({ User }) => {

    const
        _def = { isInit: { mount: false, loading: false } },
        _ = _def

    const
        [isParamsURL, setParamsURL] = useSearchParams(),
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [isData, setData] = useState([]),
        [isServices, setServices] = useState([]),
        [isUpdate, setUpdate] = useState(false)


    const onClick = {

        create: (e) => {

            animationElementClick(e.currentTarget)
            return true
        },

        update: async (e) => {

            if (isUpdate) return false

            animationElementClick(e.currentTarget)
            await update(true)
            return true
        }
    }

    const parceCost = (cost, type) => {

        switch (type) {

            case 'unit': return <>{cost?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })} / шт.</>
            case 'metr': return <>{cost?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })} / м.</>
            case 'pmetr': return <>{cost?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })} / п.м.</>
            case 'timeHour': return <>{cost?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })} / час</>
            case 'income': return <>По итогам</>
            case 'expenses': return <>По итогам</>
            default: return ''
        }
    }

    const update = (silent = false, noRetry = false) => new Promise(async (resolve) => {

        if (_.isInit.loading) return false

        _.isInit.loading = true

        let query

        setError({ status: false })
        setUpdate(true)

        if (!silent) setLoading({ status: true, msg: 'Загрузка списка услуг...' })

        try { query = await API.Works.Get() }
        catch (error) { query = { status: false, result: error?.message } }

        if (!query?.status) {

            if (!silent) setError({ status: true, title: 'загрузки списка услуг', msg: query?.result })

            setLoading({ status: false })
            _.isInit.loading = false
            return resolve(false)
        }

        setData(query?.result)

        if (!silent) setLoading({ status: true, msg: 'Загрузка типов услуг...' })

        const services = await getServices()

        if (!services.status) {

            if (!silent) setError({ status: true, title: 'загрузки типов услуг', msg: services?.result })

            setLoading({ status: false })
            _.isInit.loading = false
            return resolve(false)
        }

        setServices(services?.result)
        setLoading({ status: false })
        setUpdate(false)
        _.isInit.loading = false
        return resolve(true)
    })

    const getServices = () => new Promise(async (resolve) => {

        try { return resolve(await API.Services.Get()) }
        catch (error) { return resolve({ status: false, result: error?.message }) }
    })

    const parceTypeService = (typeID) => {

        const out = []

        for (const work of isData) {

            if (work?.service?._id === typeID) out.push(work)
        }

        return out
    }

    const Mount = () => {

        _.isInit.mount = true
        update()
        setHeaderTitle('Управление > Услуги')

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        _.isInit = _def.isInit
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>МТП | Личный кабинет | Управление | Услуги</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div id="Admin_Works">
            <ContentMenu>
                <Link to='/lk/adm'><IconX name='chevron-left' />Управление</Link>
                <Link to='/lk/adm/works/new'><IconX name="plus-lg" />Создать</Link>
                <div className={isUpdate ? 'elementLoading' : ''} onClick={onClick.update}><IconX name="arrow-clockwise" /></div>
            </ContentMenu>

            {isLoading?.status ?
                <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            <TabsX
                                name="Admin_Works"
                                head={isServices?.map(({ _id, name }) => { return [<>{name} ({parceTypeService(_id)?.length})</>] })}
                                content={isServices?.map((service) => {
                                    return [
                                        <ListX
                                            name="worksList"
                                            header={<>
                                                <div>#</div>
                                                <div>Наименование</div>
                                                <div>Стоимость</div>
                                            </>}
                                            footer={`Отображено услуг: ${parceTypeService(service?._id)?.length}`}
                                        >
                                            {parceTypeService(service?._id)?.map(({ _id, active, name, cost, type }, idx) => (
                                                <LinkX className={`content${active ? '' : ' delete'}`} key={_id} to={`/lk/adm/works/${_id}`}>
                                                    <div>{idx + 1}</div>
                                                    <div>{name}</div>
                                                    <div>{parceCost(cost, type)}</div>
                                                </LinkX>
                                            ))}
                                        </ListX>
                                    ]
                                })}
                                select={Number(isParamsURL?.get('type') || -1)}
                                onClickTab={(idx) => setParamsURL({ ['type']: idx })}
                            />
                        </>
                    }
                </>
            }
        </div >
    </>)
}

export default Admin_Works