import React, { useState } from 'react'
import NewWindow from 'react-new-window'

import { ReactComponent as Logotype } from '../../../../Images/Logo/v4.svg'
import { DateTime, Text, parceTypeWorkUnit } from '../../../../FuncX'
import IconX from '../../../../Components/IconX'

import './print.scss'

const RequestsViewWorksPrint = ({ title, request = {}, num = `${DateTime.fix().text_v4}${request?.code}`, prepay = 100, works = [], onUnload = () => { }, ...params }) => {

    const
        [isNum] = useState(num),
        [isPrePay] = useState(Number(prepay))

    const onOpen = (win) => {

        win.document.body.style.background = '#fff'
    }

    const features = () => {

        const winSize = { width: window.screen.availWidth / 2, height: window.screen.availHeight }
        // const winCentred = `top=${window.screen.availHeight / 2 - (winSize.height / 2)},left=${window.screen.availWidth / 2 - (winSize.width / 2)}`

        return { height: winSize.height, width: winSize.width, menubar: 'no', toolbar: 'no', location: 'no', status: 'no' }
    }

    const parceTotalCash = (works) => {

        let cash = 0

        for (const wrk of works) {

            cash += (wrk?.count > 0 ? wrk?.count : wrk.time) * wrk.value
        }

        return cash
    }

    const parceToPrepay = (cash, proc) => cash / 100 * proc

    return (
        <NewWindow title={title} center="screen" onOpen={onOpen} onUnload={onUnload} features={features()}>
            <div id="RequestsViewWorksPrint">
                <div className="header">
                    <Logotype />
                    <div>МагТехПро</div>
                    <ul>
                        <li><div><IconX name="telephone" /><IconX name="whatsapp" /></div><div style={{ fontSize: '20px', fontWeight: 'bold' }}>+7 (913) 000 1830</div></li>
                        <li><div><IconX name="telegram" /></div><div>@MagTehPro</div></li>
                        <li><div><IconX name="envelope-at" /></div><div>info@magtehpro.ru</div></li>
                        <li><div><IconX name="globe" /></div><div>МагТехПро.РФ</div></li>
                    </ul>
                </div>
                <div className="bank">
                    <div className="name">
                        <div className="value">АО «Тинькофф Банк»</div>
                        <div className="title">Банк получателя</div>

                        <div className="inn_kpp">
                            <div className="inn">ИНН 490913576177</div>
                            <div className="kpp">КПП</div>
                        </div>
                        <div className="user_name">Кривошеев Никита Викторович</div>
                        <div className="user_title">Получатель</div>
                    </div>
                    <div className="title">
                        <div className="bik">БИК</div>
                        <div className="bill">Сч. №</div>
                        <div className="user_bill">Сч. №</div>
                    </div>
                    <div className="value">
                        <div className="bik">044525974</div>
                        <div className="bill">30101810145250000974</div>
                        <div className="user_bill">40817810800111143293</div>
                    </div>
                </div>
                <div className="bill_name">Счёт-договор № {isNum} от {DateTime.fix(new Date()).text_v3} г.</div>
                <div className="infoPay">
                    <div className="title">Исполнитель:</div>
                    <div className="value">Кривошеев Никита Викторович, ИНН: 490913576177, адрес: г. Магадан, ул. Чукотская, д. 9, тел. +7 913 000 1830</div>
                </div>
                <div className="infoPay">
                    <div className="title">Заказчик:</div>
                    <div className="value">{request?.corp ? <>{request?.corp?.name}, ИНН: {request?.corp?.inn}, {request?.corp?.kpp ? `КПП: ${request?.corp?.kpp}` : `ОГРНИП: ${request?.corp?.ogrnip}`}, адрес: {request?.corp?.address}</> : <>{request?.client?.name?.last} {request?.client?.name?.first} {request?.client?.name?.middle}, тел. +{request?.client?.phoneNumber}</>}</div>
                </div>
                <div className="infoPay">
                    <div className="title">Назначение:</div>
                    <div className="value">Оплата заявки №{request?.code}, по счёту-договору № {isNum} от {DateTime.fix(new Date()).text_v3} г.</div>
                </div>
                <div className="works">
                    <div className="header">
                        <div>№</div>
                        <div>Наименование</div>
                        <div>Кол-во</div>
                        <div>Ед.</div>
                        <div>Цена</div>
                        <div>Сумма</div>
                    </div>
                    {works
                        ?.sort((a, b) => a.dateTime_create > b.dateTime_create ? 1 : -1)
                        ?.map(({ _id, work, work_type, count, time, value, comment }, idx) => (
                            <div className="content" key={_id}>
                                <div>{idx + 1}</div>
                                <div>{work?.name}{comment?.length > 0 ? <> | {comment}</> : <></>}</div>
                                <div>{count > 0 ? count : time}</div>
                                <div>{parceTypeWorkUnit(work.type)}</div>
                                <div>{value?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</div>
                                <div>{((count > 0 ? count : time) * value)?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</div>
                            </div>
                        ))}
                </div>
                <div className="total_info">
                    <div className="current">
                        <div className="title">Итого:</div>
                        <div className="value">{parceTotalCash(works)?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</div>
                    </div>
                    <div className="nds">
                        <div className="title">В том числе НДС:</div>
                        <div className="value">без НДС</div>
                    </div>
                    <div className="all">
                        <div className="title">Всего к оплате:</div>
                        <div className="value">{parceTotalCash(works)?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</div>
                    </div>
                </div>
                <div className="total_index">
                    <div className="int">Всего наименований {works?.length}, на сумму {parceTotalCash(works)?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</div>
                    <div className="string">Сумма прописью: {Text.firstUpperCase(Text.numbersToWords(parceTotalCash(works)))}</div>
                </div>
                {/* <div className="description">Счёт за услуги</div> */}
                <div className="contract">
                    <b>Основные положения счёта-договора № {isNum} от {DateTime.fix(new Date()).text_v3} г.</b>
                    <ul>
                        <li>Счёт-договор № {isNum} от {DateTime.fix(new Date()).text_v3} г. составлен в двух экземплярах, по одному для каждой из сторон, имеющих равную юридическую силу, и вступает в силу с момента его подписания.</li>
                        <li>Настоящий счёт-договор является основанием для оплаты.</li>
                        <li>Срок выполнения работ Исполнителем составляет ___ рабочих дней.</li>
                        {isPrePay < 100 ?
                            <>
                                {isPrePay === 0 ?
                                    <>
                                        <li>Исполнитель приступает к выполнению работ в течении <u>3</u> рабочего дня <u>с момента подписания</u> данного счёта-договора.</li>
                                        <li>Заказчик обезуется оплатить Исполнителю <u>{parceToPrepay(parceTotalCash(works), 100 - isPrePay)?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</u> в течении <u>5</u> банковских дней с момента подписания Акта выполненных работ.</li>
                                    </>
                                    :
                                    <>
                                        <li>Исполнитель приступает к выполнению работ в течении <u>1</u> рабочего дня с момента оплаты Заказчиком <u>{isPrePay}% ({parceToPrepay(parceTotalCash(works), isPrePay)?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })})</u> от общей суммы настоящего счёт-договора.</li>
                                        <li>Заказчик обезуется оплатить Исполнителю оставшуюся часть <u>{100 - isPrePay}% ({parceToPrepay(parceTotalCash(works), 100 - isPrePay)?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })})</u> в течении <u>5</u> банковских дней с момента подписания Акта выполненных работ.</li>
                                    </>
                                }
                            </>
                            :
                            <>
                                <li>Исполнитель приступает к выполнению работ в течении <u>1</u> рабочего дня с момента полной оплаты <u>{parceTotalCash(works)?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</u> услуг по данному счёту-договору.</li>
                            </>
                        }
                        <li>На основании настоящего счёта-договора формируется Акт выполненных работ.</li>
                        <li>Работы считаются выполнеными с момента подписания сторонами Акта выполненных работ.</li>
                        <li>В случае отказа от подписания Акта выполненных работ Заказчик должен предоставить письменный мотивированный отказ от подписания Акта.</li>
                        <li>Если в течении <u>5</u> рабочих дней с даты получения Заказчиком Акта выполненных работ Заказчик не подписывает Акт и не предоставляет письменный мотивированный отказ от подписания, работы считаются выполнеными и подлежат оплате.</li>
                    </ul>
                </div>
                <div className="signature">
                    <div>Исполнитель</div>
                    {/* <img src={signature} alt='sign' /> */}
                    <div>__________________</div>
                    <div><u>Кривошеев Н. В.</u>/ ФИО</div>
                </div>
                <div className="signature">
                    <div>Заказчик</div>
                    <div>__________________</div>
                    <div>_________________________________/ ФИО</div>
                    <div>М.П.</div>
                </div>
            </div>
        </NewWindow>
    )
}

export default RequestsViewWorksPrint