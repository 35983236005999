import React from 'react'

import './style.scss'



const ProgressBar = ({ circle = false, loading = false, progress = 0 }) => (<div id="ProgressBar" className={circle ? 'circle' : 'horizontal'}>
    {circle ?
        <svg className='progress' value={progress > -1 ? progress > 100 ? 100 : progress?.toFixed(0) : -1} viewBox="0 0 100 100">
            <path d="M 50 96 a 46 46 0 0 1 0 -92 46 46 0 0 1 0 92" />
            <text x="50%" y="53%" dominantBaseline="middle" textAnchor="middle">{progress > -1 ? progress > 100 ? '100%' : progress?.toFixed(0) + '%' : '!'}</text>
        </svg>
        :
        <>
            <div className='progress'>
                <div className='value'>0%</div>
                {progress > 1 && <div className='value'>{progress?.toFixed(0)}%</div>}
                <div className='value'>100%</div>
            </div>
            {progress > 1 && <span className={`value${loading ? ' loading' : ''}`} style={{ width: `${progress?.toFixed(0)}%` }}></span>}
        </>
    }
</div>)

export default ProgressBar