import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import IconX from '../IconX'
import { animationElementLoadingStart, animationElementLoadingStop } from '../Animations'
import API from '../../API'

import './style.scss'

const DialogsButton = ({ User }) => {

    const
        isInit = { mount: false },
        isTimer = { update: { _: null, active: false, time: 10000 } }

    const [isUnRead, setUnRead] = useState(0)

    const update = () => new Promise(async (resolve) => {

        if (isTimer.update.active) return resolve(false)

        isTimer.update.active = true

        const dialogs = document?.querySelector('#DialogsButton')
        let response

        if (!dialogs) return resolve(false)

        animationElementLoadingStart(dialogs)

        try { response = await API.Dialogs.GetUnRead() }
        catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            animationElementLoadingStop(dialogs)
            isTimer.update._ = setTimeout(() => update(), isTimer.update.time)
            return resolve(false)
        }

        setUnRead(Number(response.result) || 0)
        animationElementLoadingStop(dialogs)
        isTimer.update._ = setTimeout(() => update(), isTimer.update.time)
        isTimer.update.active = false
        return resolve(true)
    })

    const Mount = () => {

        isInit.mount = true
        update()
        
        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        clearTimeout(isTimer.update._)
        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<Link id="DialogsButton" to="/lk/dialogs">
        {isUnRead > 0 ?
            <>
                <IconX name="envelope-fill" id="active" />
                <div className="count">{isUnRead}</div>
            </>
            :
            <IconX name="envelope" />
        }
    </Link>)
}

export default DialogsButton