import React, { useEffect, useState } from 'react'

import AlertX from '../AlertX'
import IconX from '../IconX'

import './index.scss'

const TabsX = ({
    name = 'no_name',
    head = [
        <>Вкладка 1</>,
        <>Вкладка 2</>,
        <>Вкладка 3</>
    ],
    content = [],
    select = -1,
    onClickTab = () => { }
}) => {

    const
        _def = {
            isInit: { mount: false, loading: false },
            isScroll: { current: 0 }
        }, _ = _def

    const
        [isTab, setTab] = useState(select)

    const onClick = {

        switch: (idx) => {

            setTab(idx)
            onClickTab(idx)
        }
    }

    const Mount = () => {

        _.isInit.mount = true

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        _.isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { setTab(select) }, [select]) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div id="TabsX" className={name}>
        <div className="head">
            {head?.map((tabName, idx) => (<div
                key={idx}
                className={`${idx === isTab ? 'active' : ''}`}
                onClick={(_) => onClick.switch(idx)}
            >{tabName}</div>))}
        </div>
        <div className="content">
            {isTab === -1 ?
                <AlertX icon={<IconX name="info" />}>Выберите вкладку</AlertX>
                :
                <>{content[isTab]}</>
            }
        </div>
    </div>)
}

export default TabsX