import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import IconX from '../../../Components/IconX'
import { animationElementClick } from '../../../Components/Animations'
import { Text, checkUserStatus } from '../../../FuncX'

import './style.scss'

const defaultRoutes = [
  [
    { _id: 'fk4fj9j94grg34g3', active: false, name: 'main', icon: 'house', title: 'Главная', path: '/lk', view: false },
    { _id: 'fk4fj543534534g3', active: false, name: 'req', icon: 'list-task', title: 'Заявки', path: '/lk/req', view: false },
    { _id: 'fkgfdgd4534g3', active: false, name: 'access', icon: 'key', title: 'Доступы', path: '/lk/access', view: false },
    { _id: 'fkgfdgdfs34g3', active: false, name: 'apps', icon: 'window-stack', title: 'Приложения', path: '/lk/apps', view: false },
    { _id: 'fkgfdga534g3', active: false, name: 'settings', icon: 'gear', title: 'Настройки', path: '/lk/settings', view: false },
  ],
  [
    { _id: 'fkgfgfh534g3', active: false, name: 'adm', icon: 'building-gear', title: 'Управление', path: '/lk/adm', view: ['gAdmin', 'moder'] }
  ]
]

const LkNavBar = ({ User, toggleStatus = (status) => { } }) => {

  const
    location = useLocation(),
    [isDefaultNames, setDefaultNames] = useState({}),
    [isStatus, setStatus] = useState(false),
    [isRoutes] = useState(defaultRoutes)

  const onClick = {

    toggleStatus: (e) => {

      setStatus(!isStatus)
      toggleStatus(!isStatus)
      return true
    }
  }

  const waitLoading = (id = '', title = '') => {

    if (document.title.includes('МТП | Загрузка...')) return setTimeout(() => waitLoading(id, title), 500)
    if (document.title.includes(`МТП | ${title} | Загрузка...`)) return setTimeout(() => waitLoading(id, title), 500)

    const active = document.getElementById(id)
    let name = 'Неизвестно'

    if (!active) return setTimeout(() => waitLoading(id, title), 500)
    if (document.title.includes(`МТП | ${title} |`)) name = Text.replaceAll(document.title, `МТП | ${title} | `, '')
    if (document.title.includes(`МТП | Личный кабинет | ${title} |`)) name = Text.replaceAll(document.title, `МТП | Личный кабинет | ${title} | `, '')

    active.querySelector('.title').textContent = `${name}`
    return true
  }

  const registerRoute = (id = '', path = '', title = '') => {

    const
      url = window.location.pathname,
      active = document.getElementById(id),
      defaultTitle = active?.querySelector('.title')?.textContent || 'Неизвестно'

    if (!active) return false
    if (!isDefaultNames?.[id]) setDefaultNames({ ...isDefaultNames, [id]: defaultTitle })
    if (url.includes(`/lk/${id}`)) {

      active.classList.add('active')

      if (id === path) {

        if (url.includes(`/lk/${id}/`)) setTimeout(() => waitLoading(id, title), 500)
        else active.querySelector('.title').textContent = `${isDefaultNames?.[id] || defaultTitle} > ${title}`

        return true
      } else {

        if (url.includes(`/lk/${id}/${path}`)) {

          if (url.includes(`/lk/${id}/${path}/`)) setTimeout(() => waitLoading(id, title), 500)
          else active.querySelector('.title').textContent = `${isDefaultNames?.[id] || defaultTitle} > ${title}`

          return true
        }

        active.querySelector('.title').textContent = isDefaultNames?.[id] || defaultTitle
      }
    } else {

      active.classList.remove('active')
      active.querySelector('.title').textContent = isDefaultNames?.[id] || defaultTitle
    }
    return true
  }

  const checkRoute = (path) => {

    if (!path) return false

    const pathClear = Text.replaceAll(path, '/lk', '')
    let findRoute = false

    for (const list of isRoutes) {

      for (const route of list) {

        if (!checkUserStatus(route?.view, User?.status)) continue

        const e = document?.getElementById(route.name)

        if (path === route?.path) {

          e.classList.add('active')
          findRoute = true
        } else e.classList.remove('active')

        e.querySelector('.title').textContent = route.title
      }
    }

    if (findRoute) return false

    if (pathClear?.includes('/req/')) return registerRoute('req', 'req', 'Заявки')
    if (pathClear?.includes('/access/')) return registerRoute('access', 'access', 'Доступы')
    if (pathClear?.includes('/apps/IO.check')) return registerRoute('apps', 'IO.check', 'IO.check')
    if (pathClear?.includes('/adm/news')) return registerRoute('adm', 'news', 'Новости')
    if (pathClear?.includes('/adm/works')) return registerRoute('adm', 'works', 'Услуги')
    if (pathClear?.includes('/adm/reports')) return registerRoute('adm', 'reports', 'Отчёты')
    if (pathClear?.includes('/dialogs')) return registerRoute('dialogs', 'dialogs', 'Диалоги')

    return true
  }

  useEffect(() => { checkRoute(location?.pathname || false) }, [location]) // eslint-disable-line react-hooks/exhaustive-deps

  return (<div id="NavBar" className={`lk ${isStatus ? 'open' : 'close'}`}>
    <div className="header">
      <div className="item" onClick={onClick.toggleStatus}>
        <div className="icon"><IconX name={isStatus ? 'chevron-bar-left' : 'chevron-bar-right'} /></div>
        <div className="title">Скрыть</div>
      </div>
    </div>

    {isRoutes?.map((list, idx) => (
      <div key={'list_' + idx} className="items">
        {list?.map(({ _id, active, name, icon, title, path, view }, idx) => (
          <>
            {checkUserStatus(view, User?.status) &&
              <Link key={_id} to={path} id={name} className={active ? 'active' : ''}>
                <div className="icon"><IconX name={icon} /></div>
                <div className="title">{title}</div>
              </Link>
            }
          </>
        ))}
      </div>
    ))}
    <div className="bottom">
      {/* <div className="sign">&copy; 1830.pro, 2020 &mdash; {new Date().getFullYear()}</div> */}
      <div className="sign">&copy; МагТехПро.рф, {new Date().getFullYear()}</div>
    </div>
  </div>)
}

export default LkNavBar