import React, { useState, useEffect } from 'react'

import { DateTime, formatName, parceTypeWork } from '../../../../../FuncX'
import AlertX from '../../../../../Components/AlertX'
import IconX from '../../../../../Components/IconX'
import Loading from '../../../../../Components/Loading'
import Error from '../../../../../Components/Error'
import Block from '../../../../../Components/Block'
import API from '../../../../../API'

import './style.scss'

const Admin_Works_View_History = ({ workId = null }) => {

    const _def = { isInit: { mount: false, loading: false }, isAfterLoading: { skip: 0, count: 0 } }
    const _ = _def

    const
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [isLoadingNext, setLoadingNext] = useState({ status: false, msg: '', progress: 0 }),
        [isHistory, setHistory] = useState([]),
        [isHistoryCount, setHistoryCount] = useState(0)

    const update = (next = false) => new Promise(async (resolve) => {

        if (_.isAfterLoading.skip > 0 && _.isAfterLoading.skip >= _.isAfterLoading.count) return false

        if (_.isInit.loading) return false

        _.isInit.loading = true
        setError(false)

        if (!next) setLoading({ status: true, msg: 'Загрузка истории услуги...' })
        else {

            setLoadingNext({ status: true, msg: 'Загрузка истории услуги...' })
        }

        if (!workId) {

            setError({ status: true, title: 'загрузки истории услуги', msg: 'Неверная заявка' })
            setLoading(false)
            setLoadingNext(false)
            _.isInit.loading = false
            return resolve(false)
        }

        const onDownloadProgress = (progressEvent) => {

            if (next) return false

            const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

            return setLoading({ status: true, msg: 'Загрузка истории услуги...', progress: percentage })
        }

        let res = null

        try { res = await API.Works.GetHistory(workId, _.isAfterLoading.skip, onDownloadProgress) }
        catch (error) { res = { status: false, result: error.message } }

        if (!res.status) {

            setError({ status: true, title: 'загрузки истории.', msg: res.result })
            setLoading(false)
            setLoadingNext(false)
            _.isInit.loading = false
            return false
        }

        setHistoryCount(res.result.count)
        _.isAfterLoading.count = res.result.count

        if (!next) {

            _.isAfterLoading.skip = res.result.history.length
            setHistory(res.result.history)
            setLoading(false)
        } else {

            _.isAfterLoading.skip += res.result.history.length

            if (_.isAfterLoading.skip >= _.isAfterLoading.count) _.isAfterLoading.skip = _.isAfterLoading.count

            setHistory((prevData) => [...prevData, ...res.result.history])
            setLoadingNext(false)
        }

        _.isInit.loading = false
        return resolve(true)
    })

    // const getData = () => new Promise(async (resolve) => {

    //     setError(false)
    //     setLoading({ status: true, msg: 'Загрузка заявки...' })

    //     if (!workId) {

    //         setError({ status: true, title: 'загрузки заявки', msg: 'Неверная заявка' })
    //         setLoading({ status: false })
    //         return resolve(false)
    //     }

    //     let res

    //     try {

    //         res = await API.Requests.Get(reqId, (progressEvent) => {

    //             const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

    //             return setLoading({ status: true, msg: 'Загрузка заявки...', progress: percentage })
    //         })
    //     } catch (error) { res = { status: false, result: error.message } }

    //     if (!res.status) {

    //         setError({ status: true, title: 'загрузки заявки', msg: res.result })
    //         setLoading({ status: false })
    //         return resolve(false)
    //     }

    //     res.result = res.result?.[0] || null

    //     setRequest(res.result)
    //     setHeaderTitle(`Заявка #${res.result?.code} > История`)

    //     return resolve(await update())
    // })

    const parceHistory = (history) => {

        const
            tmo_history = [],
            create = history.create,
            update = history?.update

        if (create) tmo_history.push(<>
            <div className="event">Услуга создана</div>
            <div><b>Название: </b>{create.name}</div>
            <div><b>Стоимость: </b>{create.cost.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</div>
            <div><b>Тип: </b>{parceTypeWork(create.type)}</div>
        </>)

        if (update) {

            for (const name of Object.keys(update)) {

                if (name === "name") tmo_history.push(<><div className="event">Изменение названия</div><div>{update[name]}</div></>)
                if (name === "cost") tmo_history.push(<><div className="event">Изменение стоимости</div><div>{update[name]?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</div></>)
                if (name === "type") tmo_history.push(<><div className="event">Изменение типа цены</div><div>{parceTypeWork(update[name])}</div></>)
                if (name === "service") tmo_history.push(<><div className="event">Изменение типа услуги</div><div>{update[name]}</div></>)
            }
        }

        return <>
            {tmo_history.map((history, idx) => (
                <div key={idx} className="type">{history}</div>
            ))}
        </>
    }

    const handleScroll = async (e) => {

        if (_.isInit.loading) return false

        const scrollBottom = Math.round(e.target.scrollTop + e.target.offsetHeight) >= e.target.scrollHeight - 1500

        if (scrollBottom) await update(true)

        return true
    }

    const Mount = () => {

        _.isInit.mount = true
        update()
        document.querySelector('.content.lk').addEventListener('scroll', handleScroll)

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        document.querySelector('.content.lk').removeEventListener("scroll", handleScroll)
        _.isInit = _def.isInit
        _.isAfterLoading = _def.isAfterLoading
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <div id="Admin_Works_View_History">
            <Block name="services_works_historys" icon={<IconX name="clock-history" />} title="История изменений">
                {isLoading.status ?
                    <Loading module progress={isLoading.progress} >{isLoading.msg}</Loading>
                    :
                    <>
                        {isError.status ?
                            <Error title={isError.title} msg={isError.msg} />
                            :
                            <>
                                {isHistory?.length > 0 ?
                                    <>

                                        {isHistory.map((history, index) => (
                                            <div key={history?._id} className="item">
                                                <div className="head">
                                                    <div className='info'>
                                                        <div className='name'>
                                                            <b>Автор: </b>{formatName(history?.author, false)}
                                                        </div>
                                                        <div className='dateTime_Create'>
                                                            <b>Дата создания: </b>{DateTime.fix(new Date(history?.dateTime_create)).text}
                                                        </div>
                                                        <div className="id">
                                                            <b>ID: </b>{history?._id}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='text'>{parceHistory(history?.data)}</div>
                                                <div className="count">{index + 1} из {isHistoryCount}</div>
                                            </div>
                                        ))}

                                        {isHistory.length < isHistoryCount && <>

                                            {isLoadingNext.status && <Loading module progress={isLoadingNext.progress} >{isLoadingNext.msg}</Loading>}
                                        </>}
                                    </>
                                    :
                                    <AlertX type="info" icon={<IconX name="info" />}>История этой услуги пуста</AlertX>
                                }
                            </>
                        }
                    </>
                }
            </Block>
        </div>
    </>)
}

export default Admin_Works_View_History