import React from 'react'

import './style.scss'



const ListX = ({ children, name, header = <div>Название</div>, footer = <></> }) => {

    return (<div id="ListX" className={name}>
        <div className="header">{header}</div>
        {children}
        <div className="footer">{footer}</div>
    </div>)
}

export default ListX