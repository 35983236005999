import React from 'react'

import IconX from '../../../Components/IconX'

import './style.scss'



const BlockServices = ({ onClick_item }) => (<div id="BlockServices">
    <div className="servicesList">
        <div className="block">
            <div className="title"><IconX name="magic" /> Администрирование</div>
            <div className="items">
                <div onClick={onClick_item}>Настройка и поддержка сетевого оборудования</div>
                <div onClick={onClick_item}>Настройка и поддержка серверов</div>
                <div onClick={onClick_item}>Настройка и поддержка компьютеров</div>
                <div onClick={onClick_item}>Настройка и поддержка ноутбуков</div>
                <div onClick={onClick_item}>Настройка и поддержка рабочих станций</div>
                <div onClick={onClick_item}>Настройка и поддержка планшетов</div>
                <div onClick={onClick_item}>Настройка и поддержка телефонов</div>
                <div onClick={onClick_item}>Сборка компьютеров</div>
                <div onClick={onClick_item}>Поставка оборудования</div>
                <div onClick={onClick_item}>Аутсорсинг</div>
            </div>
        </div>
        <div className="block">
            <div className="title"><IconX name="wrench-adjustable" /> Монтажные работы</div>
            <div className="items">
                <div onClick={onClick_item}>Прокладка интернет кабеля</div>
                <div onClick={onClick_item}>Установка сетевого оборудования</div>
                <div onClick={onClick_item}>Установка камер</div>
                <div onClick={onClick_item}>Установка Wi-Fi</div>
                <div onClick={onClick_item}>Установка Wi-Fi моста</div>
                <div onClick={onClick_item}>Установка извещателей пожарной сигнализации</div>
                <div onClick={onClick_item}>Установка датчиков пожарной сигнализации</div>
                <div onClick={onClick_item}>Установка систем учёта времени</div>
                <div onClick={onClick_item}>Установка электромагнитных замков</div>
            </div>
        </div>
        <div className="block">
            <div className="title"><IconX name="braces" /> Разработка</div>
            <div className="items">
                <div onClick={onClick_item}>Сайт - визитка (Web)</div>
                <div onClick={onClick_item}>Корпоративный информационный сайт</div>
                <div onClick={onClick_item}>Корпоративный имиджевый сайт</div>
                <div onClick={onClick_item}>Интернет - магазин</div>
                <div onClick={onClick_item}>Информационный сайт</div>
                <div onClick={onClick_item}>Игровой портал (Web)</div>
                <div onClick={onClick_item}>Персональный проект (Web)</div>
                <div onClick={onClick_item}>Контент - проект (Web)</div>
                <div onClick={onClick_item}>Промо сайт</div>
                <div onClick={onClick_item}>Сайт - форум</div>
                <div onClick={onClick_item}>Сайт - блог</div>
                <div onClick={onClick_item}>Приложение для Windows</div>
                <div onClick={onClick_item}>Приложение для Linux</div>
                <div onClick={onClick_item}>Приложение для MacOS</div>
                <div onClick={onClick_item}>Приложение для Android</div>
                <div onClick={onClick_item}>Робот для Telegram</div>
                <div onClick={onClick_item}>Робот для WhatsApp</div>
                <div onClick={onClick_item}>Робот для Discord</div>
            </div>
        </div>
    </div>
</div>)

export default BlockServices