import React, { useState, useEffect } from 'react'

import IconX from '../../../../Components/IconX'
import AlertX from '../../../../Components/AlertX'
import InputX from '../../../../Components/InputX'
import { Text, DateTime, formatName } from '../../../../FuncX'
import { animationElementClick, animationElementLoadingStart, animationElementLoadingStop } from '../../../../Components/Animations'
import ButtonX from '../../../../Components/ButtonX'
import API from '../../../../API'

import './style.scss'

const RequestsViewComments = ({ requestId, requestStatus, requestEdit }) => {

    const
        isInit = { mount: false }

    const
        [IsloadModule, set___IsloadModule] = useState(false),
        [error, setError] = useState(false),
        [errorAdd, setErrorAdd] = useState(false),
        [comments_data, set___comments_data] = useState(false),
        [comment_sending, set___comment_sending] = useState(false),
        [comment_send, set___comment_send] = useState(false)

    const getComments = () => new Promise(async (resolve) => {

        set___comments_data(false)
        set___IsloadModule(false)

        if (!requestId) {

            setError('Неверный адрес заявки')
            return resolve(false)
        }

        const apiX = await API.Requests.GetComments(requestId)

        if (!apiX.status) {

            switch (apiX.result) {

                case 'no_access': {

                    setError('У вас нет доступа к комментариям этой заявке')
                    break
                }

                case 'no_data': break

                default: {

                    setError(apiX.result || 'Неизвестная ошибка')
                    break
                }
            }

            set___comments_data(false)
            set___IsloadModule(true)
            return resolve(false)
        }



        set___comments_data(apiX.result.sort((a, b) => {
            const dateA = new Date(a.dateTime_create), dateB = new Date(b.dateTime_create)
            return dateB - dateA
        }))
        set___IsloadModule(true)
        return resolve(true)
    })

    const onClick = {

        addComment: async (e) => {

            if (comment_sending) return false

            const commentElement = document.querySelector('#inputX.id_comment>textarea')
            if (commentElement.value.length < 1) return false

            set___comment_sending(false)
            setTimeout(() => animationElementLoadingStart(e.currentTarget), 100)

            const newText = commentElement.value.replace(/<(?!img|code>|\/code>|b>|\/b>|i>|\/i>|u>|\/u>|s>|\/s>).*?>/gm, '')

            let res

            try { res = await API.Requests.AddComment(requestId, newText) }
            catch (error) { res = { status: false, result: error.message } }

            if (!res.status) {

                setErrorAdd(res.result)
                animationElementLoadingStop(e.currentTarget)
                return false
            }

            await getComments()
            setErrorAdd(false)
            set___comment_send('Ваш комментарий успешно отправлен')
            set___comment_sending(false)
            animationElementLoadingStop(e.currentTarget)
            return true
        }
    }

    const Mount = () => {

        isInit.mount = true
        getComments()

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <div id='Comments'>
            {requestStatus !== 'closed' && !requestEdit && !comment_send &&
                <div className='add'>
                    {errorAdd && <AlertX className='error' type='error' icon={<IconX name="server" />}>{errorAdd}</AlertX>}
                    <InputX textareaHTML name="comment" label="Оставить комментарий" required={true} />
                    <ButtonX iconUse full name="sendAgain" onUse={onClick.addComment}><IconX name='send-plus' />Отправить</ButtonX>
                </div>
            }
            {requestStatus !== 'closed' && !requestEdit && comment_send &&
                <AlertX type="info" icon={<IconX name="chat-square-text" />}>
                    {comment_send}
                    <ButtonX iconUse full name="sendAgain" onUse={(e) => { set___comment_send(false) }}><IconX name='repeat' />Отправить ещё</ButtonX>
                </AlertX>
            }
            <div className="list">
                {!IsloadModule && <div id="loadingContent"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>}
                {error && <AlertX className="error" type="error" icon={<IconX name="server" />}>{error}</AlertX>}
                {!comments_data && !error &&
                    <div className="value">
                        {!IsloadModule ?
                            <div className="text elementLoading" style={{ textAlign: 'center' }}>Загрузка комментариев...</div>
                            :
                            <div className="text" style={{ textAlign: 'center' }}>Нет комментариев</div>
                        }
                    </div>
                }
                {comments_data && comments_data.length > 0 ?
                    <>
                        {comments_data.map((comment, index) => (
                            <div className='value' key={index} data-info={JSON.stringify(comment)}>
                                <div className='head'>
                                    <div className='info'>
                                        <div className='name'><b>Автор:</b> {formatName(comment?.author)}</div>
                                        <div className='dateTime_Create'><b>Создан: </b>{DateTime.fix(new Date(comment.dateTime_create)).text_v2}</div>
                                    </div>
                                </div>
                                <div className='text' dangerouslySetInnerHTML={{ __html: Text.replaceAll(comment.text, '\n', '<br />') }}></div>
                            </div>
                        ))}
                    </>
                    :
                    <>
                        <AlertX type='info' icon={<IconX name="chat-square" />}>Нет комментариев</AlertX>
                    </>
                }
            </div>
        </div>
    </>)
}

export default RequestsViewComments