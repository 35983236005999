import { useEffect, useState } from 'react'

import './style.scss'

const ContentMenu = ({ children, ...props }) => {

    const _def = { isInit: { mount: false, loading: false }, isScroll: { current: 0 } }
    const _ = _def

    const
        [isActive, setActive] = useState(true),
        [isScroll, setScroll] = useState(false)

    const handleScroll = async (e) => {

        if (_.isInit.loading) return false

        const
            scroll = e.target.scrollTop,
            ctx_lk = document.querySelector('.content.lk'),
            ContextMenu_height = document.querySelector('#ContentMenu')?.clientHeight || 0

        if (scroll > 80) setActive(false)
        if (_.isScroll.current >= scroll) setActive(true)

        _.isScroll.current = scroll

        if (scroll > 80) {

            // ctx_lk.style.paddingTop = (ContextMenu_height + 10) + 'px'
            // ctx_lk.style.marginBottom = (ContextMenu_height + 10) + 'px'
            setScroll(true)
        } else {

            // ctx_lk.style.paddingTop = '0'
            // ctx_lk.style.paddingBottom = '0'
            setScroll(false)
        }

        return true
    }

    const Mount = () => {

        _.isInit.mount = true
        document.querySelector('.content.lk')?.addEventListener('scroll', handleScroll)

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        document.querySelector('.content.lk')?.removeEventListener("scroll", handleScroll)
        _.isInit = _def.isInit
        _.isScroll = _def.isScroll
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <div id="ContentMenu" className={`static ${isActive ? 'active' : ''}${isScroll ? ' scroll' : ''}`} {...props}>{children}</div>
        <div id="ContentMenu" className={`dynamic ${isActive ? 'active' : ''}${isScroll ? ' scroll' : ''}`} {...props}>{children}</div>
    </>)
}

export default ContentMenu