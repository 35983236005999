import React, { useCallback } from 'react'
import Particles from 'react-tsparticles'
import { loadSlim } from 'tsparticles-slim'

import './style.scss'



const Background = () => {

    const init = useCallback(async (engine) => { await loadSlim(engine) }, [])

    // return (<div id="Background">
    //     <Particles id="tsparticles" url="./particlesjs-config.json" init={init} />
    // </div>)
    return (<></>)
}

export default Background