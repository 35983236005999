import React from 'react'

import { ReactComponent as Logotype } from '../../../Images/Logo/v4.svg'
import DialogsButton from '../../../Components/DialogsButton'
import Notifications from '../../../Components/Notifications'
import Auth from '../../../Components/Auth'
import LinkX from '../../../Components/LinkX'

import './style.scss'

const LkHeader = ({ User, isNavBarStatus }) => (<nav className='lk'>
    <LinkX className='logoLink' to="/">
        <Logotype />
    </LinkX>
    <div className={`title${!isNavBarStatus ? ' force' : ''}`}></div>
    {/* <marquee className={`title${!isNavBarStatus ? ' force' : ''}`}></marquee> */}
    {(User?.status === 'gAdmin' || User?.status === 'moder') && <DialogsButton />}
    <Notifications />
    <Auth User={User} compact />
</nav >)

export default LkHeader