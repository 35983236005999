import React, { forwardRef } from 'react'

import './style.scss'



export default forwardRef(({ children, name, icon, title, ...props }, ref) => {

    return (
        <div ref={ref} id="BlockX">
            <h2>{icon} {title}</h2>
            <div className={`name-${name}`}>{children}</div>
        </div>
    )
})