import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import Background from '../../Layouts/Default/Background'
import BlockServices from '../../Layouts/Default/BlockServices'
import BlockMiddle from '../../Layouts/Default/BlockMiddle'
import { animationElementClick } from '../../Components/Animations'



const Home = () => {

  const [isSelectService, setSelectService] = useState(false)



  const onClick = {

    item: (e) => {

      animationElementClick(e.currentTarget)
      setSelectService(e.currentTarget.textContent)
    }
  }


  
  return (<>
    <Helmet>
      <meta charSet="utf-8" />
      <title>МагТехПро</title>
      <link rel="canonical" href={window.location.href} />
    </Helmet>
    <Background />
    <BlockServices onClick_item={onClick.item} />
    <BlockMiddle selectService={isSelectService} />
  </>)
}

export default Home