import axios from 'axios'

import { getUserInfo } from './FuncX'

// const server = 'http://127.0.0.1:1830'
const server = 'https://api.magtehpro.ru:1830'
const MethodTypes = { POST: 'post', GET: 'get', FILE: 'file' }

const Query = async (method = '', url = '', params = {}, config = {}) => new Promise(async (resolve, reject) => {

    const tmp = { response: false }

    try {

        switch (method) {

            case MethodTypes.GET: {

                tmp.response = await axios.get(server + url, { key: localStorage.getItem('MTP|Auth'), userAgent: await getUserInfo(), ...params })
                break
            }

            case MethodTypes.POST: {

                tmp.response = await axios.post(server + url, { key: localStorage.getItem('MTP|Auth'), userAgent: await getUserInfo(), ...params }, config)
                break
            }

            case MethodTypes.FILE: {

                const formData = new FormData()

                formData.append('key', localStorage.getItem('MTP|Auth'))
                formData.append('userAgent', JSON.stringify(await getUserInfo()))

                for (const file of params.files) { formData.append('file', file, file.name) }

                tmp.response = await axios.post(server + url, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                break
            }

            default: tmp.response = await axios.get(server + url, { key: localStorage.getItem('MTP|Auth'), userAgent: await getUserInfo(), ...params })
        }

    } catch (error) { return reject(error) }

    return resolve(tmp.response.data)
})

const API = {

    Access: {

        Get: async (id = null) => await Query(MethodTypes.POST, '/Access/Get', { id }),
        FolderCreate: async (client, corp, path, name, accessLevel) => await Query(MethodTypes.POST, '/Access/FolderCreate', { client, corp, path, name, accessLevel }),
        FolderDelete: async (id) => await Query(MethodTypes.POST, '/Access/FolderDelete', { id }),
        ItemCreate: async (client, corp, path, name, accessLevel) => await Query(MethodTypes.POST, '/Access/ItemCreate', { client, corp, path, name, accessLevel }),
        ItemFieldCreate: async (id, data) => await Query(MethodTypes.POST, '/Access/ItemFieldCreate', { id, data }),
        ItemFieldDelete: async (id, idx) => await Query(MethodTypes.POST, '/Access/ItemFieldDelete', { id, idx }),
        ItemUpdate: async (id, name, data) => await Query(MethodTypes.POST, '/Access/ItemUpdate', { id, name, data }),
        ItemDelete: async (id) => await Query(MethodTypes.POST, '/Access/ItemDelete', { id }),
    },

    Apps: {

        Get: async (id = null, onDownloadProgress = {}) => await Query(MethodTypes.POST, '/Apps/Get', { id }, { onDownloadProgress }),
        IOcheck: {

            Get: async (id = null, onDownloadProgress = {}) => await Query(MethodTypes.POST, '/IO.check/Get', { id }, { onDownloadProgress }),
            Create: async () => await Query(MethodTypes.POST, '/IO.check/Create'),
            Remove: async (device) => await Query(MethodTypes.POST, '/IO.check/Remove', { device }),
            AddUserToAccess: async (device, user_id) => await Query(MethodTypes.POST, '/IO.check/AddUserToAccess', { device, user_id }),
            RemoveUserToAccess: async (device, user_id) => await Query(MethodTypes.POST, '/IO.check/RemoveUserToAccess', { device, user_id }),
        }
    },

    Auth: {

        Check: async (tokenFirebase = null) => await Query(MethodTypes.POST, '/Auth/Check', { tokenFirebase }),
        CodeSend: async (phoneNumber) => await Query(MethodTypes.POST, '/Auth/CodeSend', { phoneNumber }),
        CodeVerify: async (phoneNumber, code) => await Query(MethodTypes.POST, '/Auth/CodeVerify', { phoneNumber, code }),
        Telegram___CodeSend: async (email) => await Query(MethodTypes.POST, '/Auth/Telegram___CodeSend', { email }),
        Telegram___CodeVerify: async (email, code) => await Query(MethodTypes.POST, '/Auth/Telegram___CodeVerify', { email, code })
    },

    Users: {

        Get: async (id = 'me') => await Query(MethodTypes.POST, '/Users/Get', { id }),
        Set: async (id = null, data) => await Query(MethodTypes.POST, '/Users/Set', { id, data }),
        GetFilters: async (onDownloadProgress = {}) => await Query(MethodTypes.POST, '/Users/GetFilters', {}, { onDownloadProgress }),
        SetFilters: async (filters) => await Query(MethodTypes.POST, '/Users/SetFilters', { filters }),
        GetResponsibles: async () => await Query(MethodTypes.POST, '/Users/GetResponsibles'),
        GetClients: async () => await Query(MethodTypes.POST, '/Users/GetClients'),
        GetNotificationSettings: async (id = null, type) => await Query(MethodTypes.POST, '/Users/GetNotificationSettings', { id, type }),
        SetNotificationSetting: async (id = null, type, name, value) => await Query(MethodTypes.POST, '/Users/SetNotificationSetting', { id, type, name, value })
    },

    Devices: {

        GetAll: async () => await Query(MethodTypes.POST, '/Devices/GetAll')
    },

    FeedBacks: {

        Create: async (name, communication, service, comment) => await Query(MethodTypes.POST, '/FeedBacks/Create', { name, communication, service, comment })
    },

    Requests: {

        Create: async (data) => await Query(MethodTypes.POST, '/Requests/Create', { data }),
        Get: async (id = null, onDownloadProgress = () => true) => await Query(MethodTypes.POST, '/Requests/Get', { id }, { onDownloadProgress }),
        Set: async (id, data) => await Query(MethodTypes.POST, '/Requests/Set', { id, data }),
        GetStatuses: async () => await Query(MethodTypes.POST, '/Requests/GetStatuses'),
        GetFilters: async () => await Query(MethodTypes.POST, '/Requests/GetFilters'),
        GetComments: async (id) => await Query(MethodTypes.POST, '/Requests/GetComments', { id }),
        AddComment: async (id, comment) => await Query(MethodTypes.POST, '/Requests/AddComment', { id, comment }),
        GetHistory: async (id, skip = 0, onDownloadProgress = () => true) => await Query(MethodTypes.POST, '/Requests/GetHistory', { id, skip }, { onDownloadProgress }),
        GetWorks: async (id, onDownloadProgress = () => true) => await Query(MethodTypes.POST, '/Requests/GetWorks', { id }, { onDownloadProgress }),
        WorkAdd: async (id, work) => await Query(MethodTypes.POST, '/Requests/WorkAdd', { id, work }),
        WorkUpdate: async (id, data) => await Query(MethodTypes.POST, '/Requests/WorkUpdate', { id, data }),
        WorkRemove: async (id) => await Query(MethodTypes.POST, '/Requests/WorkRemove', { id }),
        TodoGet: async (id, onDownloadProgress = () => true) => await Query(MethodTypes.POST, '/Requests/TodoGet', { id }, { onDownloadProgress }),
        TodoRemove: async (id, idx) => await Query(MethodTypes.POST, '/Requests/TodoRemove', { id, idx }),
        TodoListAdd: async (id, name) => await Query(MethodTypes.POST, '/Requests/TodoListAdd', { id, name }),
        TodoListName: async (id, name) => await Query(MethodTypes.POST, '/Requests/TodoListName', { id, name }),
        TodoListItemAdd: async (id, name) => await Query(MethodTypes.POST, '/Requests/TodoListItemAdd', { id, name }),
        TodoListItemName: async (id, idx, name) => await Query(MethodTypes.POST, '/Requests/TodoListItemName', { id, idx, name }),
        TodoListItemStatus: async (id, idx, status) => await Query(MethodTypes.POST, '/Requests/TodoListItemStatus', { id, idx, status }),
        PaidGet: async (request) => await Query(MethodTypes.POST, '/Requests/PaidGet', { request }),
        PaidAdd: async (data) => await Query(MethodTypes.POST, '/Requests/PaidAdd', { data }),
        PaidEdit: async (id, data) => await Query(MethodTypes.POST, '/Requests/PaidEdit', { id, data }),
        PaidDelete: async (id) => await Query(MethodTypes.POST, '/Requests/PaidDelete', { id })
    },

    Works: {

        Get: async (id = null) => await Query(MethodTypes.POST, '/Works/Get', { id }),
        Create: async (name, cost, type, service) => await Query(MethodTypes.POST, '/Works/Create', { name, cost, type, service }),
        Update: async (id, data) => await Query(MethodTypes.POST, '/Works/Update', { id, data }),
        Delete: async (id) => await Query(MethodTypes.POST, '/Works/Delete', { id }),
        Restore: async (id) => await Query(MethodTypes.POST, '/Works/Restore', { id }),
        DeleteForce: async (id) => await Query(MethodTypes.POST, '/Works/DeleteForce', { id }),
        GetHistory: async (id, skip = 0, onDownloadProgress = () => true) => await Query(MethodTypes.POST, '/Works/GetHistory', { id, skip }, { onDownloadProgress })
    },

    Services: {

        Get: async (id = null) => await Query(MethodTypes.POST, '/Services/Get', { id }),
    },

    News: {

        Get: async (id = null, onDownloadProgress = () => true) => await Query(MethodTypes.POST, '/News/Get', { id }, { onDownloadProgress }),
        Create: async (title, description) => await Query(MethodTypes.POST, '/News/Create', { title, description })
    },

    Files: {

        // key: localStorage.getItem('MTP|Auth'), userAgent: await getUserInfo()

        GetPrivate: (pathFile) => `${server}/Files/GetPrivate?path=${pathFile}`,
        SetUserAvatar: async (files) => await Query(MethodTypes.FILE, '/Files/SetUserAvatar', { files })
    },

    Notifications: {

        Get: async (type = 'Site', id = null) => await Query(MethodTypes.POST, '/Notifications/Get', { type, id })
    },

    Dialogs: {

        Get: async () => await Query(MethodTypes.POST, '/Dialogs/Get'),
        GetOne: async (id) => await Query(MethodTypes.POST, '/Dialogs/GetOne', { id }),
        GetUnRead: async () => await Query(MethodTypes.POST, '/Dialogs/GetUnRead'),
        SetReadMesasge: async (id = null) => await Query(MethodTypes.POST, '/Dialogs/SetReadMesasge', { id }),
        SendMessageText: async (id = null, social, text) => await Query(MethodTypes.POST, '/Dialogs/SendMessageText', { id, social, text }),
    },

    Reports: {

        Income: {

            Get: async (id = null, onDownloadProgress = () => true) => await Query(MethodTypes.POST, '/Reports/Income_Get', { id }, { onDownloadProgress })
        },
        Expenses: {

            Get: async (id = null, onDownloadProgress = () => true) => await Query(MethodTypes.POST, '/Reports/Expenses_Get', { id }, { onDownloadProgress })
        }
    },

    Finance: {

        Create: async (type, name, request = null, user = null, corp = null, onDownloadProgress = () => true) => await Query(MethodTypes.POST, '/Finance/Create', { type, name, request, user, corp }, { onDownloadProgress }),
        GetOne: async (id, onDownloadProgress = () => true) => await Query(MethodTypes.POST, '/Finance/GetOne', { id }, { onDownloadProgress }),
        Income: {

            Get: async (onDownloadProgress = () => true) => await Query(MethodTypes.POST, '/Finance/Income', {}, { onDownloadProgress })
        },
        Expenses: {

            Get: async (onDownloadProgress = () => true) => await Query(MethodTypes.POST, '/Finance/Expenses', {}, { onDownloadProgress })
        }
    },

    getReviews: e => axios.get(server + '/review/get', { onDownloadProgress: progressEvent => { e.parceProgress(progressEvent) } })
}

export default API