import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { setHeaderTitle } from '../../FuncX'
import Loading from '../../Components/Loading'
import Error from '../../Components/Error'
import IconX from '../../Components/IconX'
import API from '../../API'

import './style.scss'

const Admin = ({ User }) => {

    const
        isInit = { mount: false }

    const
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 })


    const Mount = () => {

        isInit.mount = true
        setHeaderTitle('Управление')
        // update()

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>МТП | Личный кабинет | Управление</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div id="Admin">
            {isLoading?.status ?
                <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            <div className="list">
                                <Link to="/lk/adm/main" className="nope"><IconX name="wrench-adjustable-circle" />Система</Link>
                                <Link to="/lk/adm/news"><IconX name="newspaper" />Новости</Link>
                                <Link to="/lk/adm/works"><IconX name="magic" />Услуги</Link>
                                <Link to="/lk/adm/permissions" className="nope"><IconX name="person-wheelchair" />Права</Link>
                                <Link to="/lk/adm/users" className="nope"><IconX name="person-raised-hand" />Пользователи</Link>
                                <Link to="/lk/adm/finance"><IconX name="safe" />Финансы</Link>
                                <Link to="/lk/adm/reports"><IconX name="diagram-3" />Отчёты</Link>
                            </div>
                        </>
                    }
                </>
            }
        </div >
    </>)
}

export default Admin