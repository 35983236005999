import React, { useEffect, useState } from 'react'

import Error from '../../../../Components/Error'
import Loading from '../../../../Components/Loading'
import ListX from '../../../../Components/ListX'
import LinkX from '../../../../Components/LinkX'
import AlertX from '../../../../Components/AlertX'
import IconX from '../../../../Components/IconX'
import { DateTime, formatName, Kludge } from '../../../../FuncX'
import API from '../../../../API'

import './index.scss'

const AdminFinanceExpenses = ({ }) => {

    const
        _def = {
            isInit: { mount: false, loading: false },
            isScroll: { current: 0 }
        }, _ = _def

    const
        [isLoading, setLoading] = useState({ status: true, msg: 'Загрузка расходов...', progress: -1 }),
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isData, setData] = useState([]),
        [isCash, setCash] = useState(0)

    const update = () => new Promise(async (resolve) => {

        if (_.isInit.loading) return false

        _.isInit.loading = true

        let query

        setError({ status: false })

        try {

            query = await API.Finance.Expenses.Get((progressEvent) => {

                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                if (_.isInit.loading) setLoading({ status: true, msg: 'Загрузка расходов...', progress: percentage })

                return true
            })
        } catch (error) { query = { status: false, result: error.message } }

        if (!query.status) {

            setError({ status: true, title: 'загрузки расходов', msg: query.result })
            setLoading({ status: false })
            console.log('test', query)
            return resolve(false)
        }


        setData(query?.result || [])
        setCash(query?.cash || 0)
        setLoading({ status: false })
        _.isInit.loading = false
        return resolve(true)
    })

    const Mount = () => {

        _.isInit.mount = true
        update()

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        _.isInit = _def.isInit
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div id="AdminFinanceExpenses">
        {isLoading?.status ?
            <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading>
            :
            <>
                {isError?.status ?
                    <Error title={isError.title} msg={isError.msg} />
                    :
                    <>
                        {isData?.length > 0 ?
                            <ListX
                                name="AdminFinanceExpenses"
                                header={<>
                                    <div>#</div>
                                    <div>Дата и время</div>
                                    <div>Автор</div>
                                    <div>Сумма</div>
                                </>}
                                footer={<>Всего: {Kludge.toRUB(isCash)}</>}
                            >
                                {isData?.map(({ _id, dateTime_fact, author, value }, idx) => (
                                    <LinkX key={_id} className="content" to={`/lk/adm/finance/${_id}`}>
                                        <div>{idx + 1}</div>
                                        <div>{DateTime.fix(new Date(dateTime_fact)).text}</div>
                                        <div>{formatName(author, false, false, false)}</div>
                                        <div>{Kludge.toRUB(value)}</div>
                                    </LinkX>
                                ))}
                            </ListX>
                            :
                            <AlertX icon={<IconX name="info" />}>Список расходов пуст</AlertX>
                        }
                    </>
                }
            </>
        }
    </div>)
}

export default AdminFinanceExpenses