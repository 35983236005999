import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import API from '../../API'
import Block from '../../Components/Block'
import IconX from '../../Components/IconX'
import AlertX from '../../Components/AlertX'

import './style.scss'
import { DateTime, setHeaderTitle } from '../../FuncX'
import Loading from '../../Components/Loading'
import Error from '../../Components/Error'

const
    _def = { isInit: { mount: false, loading: { request: false, news: false } }},
    _ = _def

const Main = () => {

    const
        [isError_requestsInfo, setError_requestsInfo] = useState({ status: false, title: '', msg: '' }),
        [isLoading_requestsInfo, setLoading_requestsInfo] = useState({ status: false, msg: '', progress: 0 }),
        [isError_newsInfo, setError_newsInfo] = useState({ status: false, title: '', msg: '' }),
        [isLoading_newsInfo, setLoading_newsInfo] = useState({ status: false, msg: '', progress: 0 }),
        [isRequestInfo, setRequestInfo] = useState(false),
        [isNews, setNews] = useState([])

    const loadRequests = () => new Promise(async (resolve) => {

        if (_.isInit.loading.request) return false

        _.isInit.loading.request = true

        let res
        const requests_info = {

            all: 0,
            new: { className: '', count: 0 },
            opened: { className: '', count: 0 },
            paused: { className: '', count: 0 },
            awaitPay: { className: '', count: 0 },
            closed: { className: '', count: 0 }
        }

        setLoading_requestsInfo({ status: true, msg: 'Загрузка информации по заявкам...' })

        try {

            res = await API.Requests.Get(null, (progressEvent) => {

                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                if (_.isInit.loading.request) setLoading_requestsInfo({ status: true, msg: 'Загрузка информации по заявкам...', progress: percentage })

                return true
            })
        } catch (error) { res = { status: false, result: error.message } }

        if (!res.status) {

            setError_requestsInfo({ status: true, title: 'загрузки информации по заявкам', msg: res.result })
            setLoading_requestsInfo({ status: false })
            return resolve(false)
        }

        for (const request of res.result) {

            requests_info.all++
            if (request.status.className === 'new') requests_info.new = { className: request.status.className, count: requests_info.new.count + 1 }
            if (request.status.className === 'opened') requests_info.opened = { className: request.status.className, count: requests_info.opened.count + 1 }
            if (request.status.className === 'paused') requests_info.paused = { className: request.status.className, count: requests_info.paused.count + 1 }
            if (request.status.className === 'awaitPay') requests_info.awaitPay = { className: request.status.className, count: requests_info.awaitPay.count + 1 }
            if (request.status.className === 'closed') requests_info.closed = { className: request.status.className, count: requests_info.closed.count + 1 }
        }

        setRequestInfo(requests_info)
        setLoading_requestsInfo({ status: false })
        _.isInit.loading.request = false
        return resolve(true)
    })

    const loadNews = () => new Promise(async (resolve) => {

        if (_.isInit.loading.news) return false

        _.isInit.loading.news = true

        let res

        setLoading_newsInfo({ status: true, msg: 'Загрузка новостей...' })

        try {

            res = await API.News.Get(null, (progressEvent) => {

                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                if (_.isInit.loading.news) setLoading_newsInfo({ status: true, msg: 'Загрузка новостей...', progress: percentage })

                return true
            })
        } catch (error) { res = { status: false, result: error.message } }

        if (!res.status) {

            setError_newsInfo({ status: true, title: 'загрузки новостей', msg: res.result })
            setLoading_newsInfo({ status: false })
            return resolve(false)
        }

        setNews(res.result)
        setLoading_newsInfo({ status: false })
        _.isInit.loading.news = false
        return resolve(true)
    })

    const update = async () => {

        loadRequests()
        loadNews()
    }

    const Mount = () => {

        _.isInit.mount = true
        update()
        setHeaderTitle('Главная')

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        _.isInit = _def.isInit
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>МТП | Личный кабинет | Главная</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div id='Index'>
            <AlertX type="info" icon={<IconX name="list-task" />}>
                {isLoading_requestsInfo.status ?
                    <Loading module>{isLoading_requestsInfo.msg}</Loading>
                    :
                    isError_requestsInfo.status ?
                        <Error title={isError_requestsInfo.title}>{isError_requestsInfo.msg}</Error>
                        :
                        <>
                            <div className='title'>Информация по заявкам</div>
                            <br />
                            <b>Всего:</b> {isRequestInfo.all}<br />
                            <span className={isRequestInfo?.new?.count > 0 ? isRequestInfo?.new?.className : 'unFocus'}><b>Новых:</b> {isRequestInfo?.new?.count}</span><br />
                            <span className={isRequestInfo?.opened?.count > 0 ? isRequestInfo?.opened?.className : 'unFocus'}><b>Выполняются:</b> {isRequestInfo?.opened?.count}</span><br />
                            <span className={isRequestInfo?.paused?.count > 0 ? isRequestInfo?.paused?.className : 'unFocus'}><b>Приостановлены:</b> {isRequestInfo?.paused?.count}</span><br />
                            <span className={isRequestInfo?.awaitPay?.count > 0 ? isRequestInfo?.awaitPay?.className : 'unFocus'}><b>Ожидают оплаты:</b> {isRequestInfo?.awaitPay?.count}</span><br />
                            <span className={isRequestInfo?.closed?.count > 0 ? isRequestInfo?.closed?.className : 'unFocus'}><b>Закрыты:</b> {isRequestInfo?.closed?.count}</span><br />
                        </>
                }
            </AlertX>

            <Block name='News' icon={<IconX name='newspaper' />} title='Новости'>
                {isLoading_newsInfo.status ?
                    <Loading module>{isLoading_newsInfo.msg}</Loading>
                    :
                    isError_newsInfo.status ?

                        <Error title={isError_newsInfo.title}>{isError_newsInfo.msg}</Error>
                        :
                        <>
                            {isNews.length < 1 ?
                                <AlertX type="info" icon={<IconX name="emoji-frown" />}>Нет новостей</AlertX>
                                :
                                <>
                                    {isNews.map((item, idx) => (
                                        <div key={idx} className="item">
                                            <div className="dateTime">{DateTime.fix(new Date(item.dateTime_create)).text_v2}</div>
                                            <div className="title">{item.title}</div>
                                            <div className="description" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                        </div>
                                    ))}
                                </>
                            }
                        </>
                }
            </Block>
        </div >
    </>)
}

export default Main