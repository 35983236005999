import React, { useEffect, useState } from 'react'

import IconX from '../IconX'
import { animationElementClick, animationElementLoadingStart, animationElementLoadingStop } from '../Animations'
import API from '../../API'

import './style.scss'

const isInit = { mount: false }
const isTimer = { update: { _: null, time: 30000 } }

const Notifications = ({ User }) => {

    const
        [isData, setData] = useState([])

    const onClick = {

        main: (e) => {

            animationElementClick(e.currentTarget)
        }
    }

    const update = () => new Promise(async (resolve) => {

        const notifications = document?.querySelector('#Notifications')
        let response

        if (!notifications) return resolve(false)

        animationElementLoadingStart(notifications)

        try { response = await API.Notifications.Get() }
        catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            animationElementLoadingStop(notifications)
            isTimer.update._ = setTimeout(() => update(), isTimer.update.time)
            return resolve(false)
        }

        setData(response.result)
        animationElementLoadingStop(notifications)
        isTimer.update._ = setTimeout(() => update(), isTimer.update.time)
        return resolve(true)
    })

    const Mount = () => {

        isInit.mount = true
        // update()
        
        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        clearTimeout(isTimer.update._)
        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div id="Notifications" onClick={onClick.main}>
        {isData?.length > 0 ?
            <>
                <IconX name="bell-fill" />
                <div className="count">{isData?.length}</div>
            </>
            :
            <IconX name="bell" />
        }
    </div>)
}

export default Notifications