import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import IconX from '../../../Components/IconX'
import Loading from '../../../Components/Loading'
import Error from '../../../Components/Error'
import Block from '../../../Components/Block'
import InputX from '../../../Components/InputX'
import { animationElementClick, animationElementError } from '../../../Components/Animations'
import DialogX from '../../../Components/DialogX'
import AlertX from '../../../Components/AlertX'
import { Kludge, setHeaderTitle } from '../../../FuncX'
import ButtonX from '../../../Components/ButtonX'
import API from '../../../API'

import './style.scss'
import ContentMenu from '../../../Components/ContentMenu'

const
    isInit = { mount: false }

const AccessView = ({ User }) => {

    const
        { accessId } = useParams(),
        [isDialog, setDialog] = useState(false),
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '' }),
        [isEdit, setEdit] = useState({ active: false }),
        [isData, setData] = useState(false)

    const onClick = {

        back: (e) => {

            window.history.back()
            return true
        },

        copy: (e) => {

            animationElementClick(e.currentTarget)

            setDialog({

                id: 'copy_file',
                title: 'Копирование документа',
                content: <>
                    <InputX name="copy_name" label="Название нового документа" />
                    <ButtonX iconUse full onUse={onClick.copyDocument}><IconX name="file-earmark-plus" /> Создать документ</ButtonX>
                </>
            })
        },

        copyDocument: (e) => {

            const copyName = document.querySelector('.id_copy_name'), copyName_value = copyName?.querySelector('input')?.value

            if (!copyName_value) return animationElementError(copyName, true)
        },

        edit: (e) => {

            animationElementClick(e.currentTarget)
            setEdit({ active: !isEdit.active })
            return true
        },

        save: async (e) => {

            animationElementClick(e.currentTarget)

            const saveData = Kludge.deleteX(isEdit, ['active'])

            setError({ status: false })
            setLoading({ status: true, msg: 'Сохранение доступа...' })

            let response

            try { response = await API.Access.ItemUpdate(accessId, isData?.name, saveData) }
            catch (error) {

                setError({ status: true, title: 'сохранения доступа', msg: error.message })
                setLoading({ status: false })
                return false
            }

            if (!response.status) {

                setError({ status: true, title: 'сохранения доступа', msg: response.result })
                setLoading({ status: false })
                return false
            }

            await loadData()
            setLoading({ status: false })
            return true
        },

        delete: async (e) => {

            animationElementClick(e.currentTarget)
            setDialog({

                id: 'create_field',
                title: 'Удаление документа доступа',
                content: <>
                    <div className='title'>
                        <div className='main'>Вы уверены что хотите удалить документ доступа - {isData?.name}?</div>
                        <div className='alt'>Удаление этого документа сотрёт все доступы находящиеся в нём.</div>
                    </div>
                    <div className='question'>
                        <div className='btn_default' onClick={() => {

                            setDialog(false)
                        }}><IconX name="chevron-left" /> Отменить</div>

                        <div className='btn_default red' onClick={async () => {

                            let response

                            setDialog(false)
                            setLoading({ status: true, msg: 'Удаление документа доступа...' })

                            try { response = await API.Access.ItemDelete(accessId) }
                            catch (error) {

                                setError({ status: true, title: 'удаления документа доступа', msg: error.message })
                                setLoading({ status: false })
                                return false
                            }

                            if (!response.status) {

                                setError({ status: true, title: 'удаления документа доступа', msg: response.result })
                                setLoading({ status: false })
                                return false
                            }

                            await loadData()
                            setLoading({ status: false })
                            return true
                        }}><IconX name="trash" /> Удалить</div>
                    </div>
                </>
            })
        },

        addField: (e) => {

            animationElementClick(e.currentTarget)
            setDialog({

                id: 'create_field',
                title: 'Добавление нового поля',
                content: <>
                    <InputX name='fieldName' label='Имя поля' />
                    <InputX name='fieldValue' label='Значение поля' />
                    <div className='btn_default' onClick={async (e) => {

                        let response
                        const name = document.querySelector('.id_fieldName>input').value
                        const value = document.querySelector('.id_fieldValue>input').value

                        setDialog(false)
                        setLoading({ status: true, msg: 'Создание поля доступа...' })

                        try { response = await API.Access.ItemFieldCreate(accessId, { position: isData?.data?.length + 1, name, value }) }
                        catch (error) {

                            setError({ status: true, title: 'сохранения доступа', msg: error.message })
                            setLoading({ status: false })
                            return false
                        }

                        if (!response.status) {

                            setError({ status: true, title: 'сохранения доступа', msg: response.result })
                            setLoading({ status: false })
                            return false
                        }

                        await loadData()
                        setLoading({ status: false })
                        return true
                    }}><IconX name="plus-lg" /> Добавить поле</div>
                </>
            })
            return true
        },

        fieldDelete: (e) => {

            const idx = e.target.dataset.idx
            const name = e.target.dataset.name
            const value = e.target.dataset.value

            if (idx === undefined || idx === null) return false
            if (name === undefined || name === null) return false
            if (value === undefined || value === null) return false

            animationElementClick(e.currentTarget)
            setDialog({

                id: 'create_field',
                title: 'Удаление поля доступа',
                content: <>
                    <InputX name='fieldName' label={name} value={value} readonly />
                    <div className='btn_default' onClick={async (e) => {

                        let response

                        setDialog(false)
                        setLoading({ status: true, msg: 'Удаление поля доступа...' })

                        try { response = await API.Access.ItemFieldDelete(accessId, idx) }
                        catch (error) {

                            setError({ status: true, title: 'удаления поля доступа', msg: error.message })
                            setLoading({ status: false })
                            return false
                        }

                        if (!response.status) {

                            setError({ status: true, title: 'удаления поля доступа', msg: response.result })
                            setLoading({ status: false })
                            return false
                        }

                        await loadData()
                        setLoading({ status: false })
                        return true
                    }}><IconX name="trash" /> Удалить</div>
                </>
            })
        }
    }

    const onChange = {

        inputName: (e) => {

            const idx = e.target.dataset.idx
            const value = e.target.value
            const item = isData?.data[idx]

            if (!item) return false
            if (item.name === value) {

                setEdit((currentData) => {

                    const newData = Kludge.deleteX(currentData[idx], ['name'])

                    if (Object.keys(newData).length < 1) Kludge.deleteX(currentData, [idx])
                    else currentData[idx] = newData

                    if (Object.keys(currentData).length === 1) return { active: currentData.active }
                    else return currentData
                })
                return true
            }

            setEdit((currentData) => {

                return {

                    ...currentData,
                    [idx]: { ...isEdit?.[idx], name: value }
                }
            })
            return true
        },

        inputValue: (e) => {

            const idx = e.target.dataset.idx
            const value = e.target.value
            const item = isData?.data[idx]

            if (!item) return false
            if (item.value === value) {

                setEdit((currentData) => {

                    const newData = Kludge.deleteX(currentData[idx], ['value'])

                    if (Object.keys(newData).length < 1) Kludge.deleteX(currentData, [idx])
                    else currentData[idx] = newData

                    if (Object.keys(currentData).length === 1) return { active: currentData.active }
                    else return currentData
                })
                return true
            }

            setEdit((currentData) => {

                return {

                    ...currentData,
                    [idx]: { ...isEdit?.[idx], value: value }
                }
            })
            return true
        }
    }

    const findCorpUser = () => {

        if (!User) return false
        if (!isData) return false
        if (!isData.corp) return false

        for (const corp of User.corps) {

            if (corp._id === isData.corp._id) return corp
        }

        return false
    }

    const getAccessLevelCorp = (accessCheck) => {

        const corp = findCorpUser()

        if (!corp) return false

        for (const access of corp.accessLevels) {

            if (access === accessCheck) return true
        }

        return false
    }

    const copyDocument = (e) => {


    }

    const checkPermissionEdit = (typeList = []) => {

        if (!User) return false
        if (!isData) return false

        for (const type of typeList) {

            switch (type) {

                case 'admin': {

                    if (User.status === 'gAdmin') return true
                    if (User.status === 'moder') return true

                    break
                }

                case 'author': {

                    if (User.status === 'gAdmin') return true
                    if (User.status === 'moder') return true
                    if (getAccessLevelCorp('full')) return true
                    if (User._id === isData.author._id) return true

                    break
                }

                default: { }
            }
        }

        return false
    }

    const loadData = () => new Promise(async (resolve) => {

        setError({ status: false })
        setLoading({ status: true, msg: 'Загрузка доступа...' })

        if (!accessId) {

            setError({ status: true, title: 'загрузки доступа', msg: 'Доступ не найден.' })
            setLoading({ status: false })
            return resolve(false)
        }

        let response

        try { response = await API.Access.Get(accessId) }
        catch (error) {

            setError({ status: true, title: 'загрузки доступа', msg: error.message })
            setLoading({ status: false })
            return resolve(false)
        }

        if (!response.status) {

            setError({ status: true, title: 'загрузки доступа', msg: response.result })
            setLoading({ status: false })
            return resolve(false)
        }

        setData(response.result)
        setLoading({ status: false })
        setHeaderTitle(`Доступ > ${response.result?.name}`)
        return resolve(true)
    })

    const Mount = () => {

        isInit.mount = true
        loadData()
        setHeaderTitle('Загрузка...')

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>

        {isError.status &&
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`МТП | Личный кабинет | Доступы | ${isError.msg}`}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
        }

        {isData ?
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`МТП | Личный кабинет | Доступы | ${isData.name}`}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            :
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`МТП | Личный кабинет | Доступы | Загрузка...`}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
        }

        {isDialog &&
            <DialogX id={isDialog.id} title={isDialog.title} full={isDialog.full ?? false} close={() => { setDialog(false) }}>
                {isDialog.content}
            </DialogX>
        }

        {isLoading.status ?
            <Loading module >{isLoading.msg}</Loading>
            :
            <>
                {isError.status ?
                    <Error title={isError.title} msg={isError.msg} />
                    :
                    <div id="accessView">
                        <ContentMenu>
                            <div onClick={onClick.back}><IconX name="chevron-left" />Вернуться</div>
                            {checkPermissionEdit(['author']) && <div onClick={onClick.copy}><IconX name="copy" /></div>}
                            {checkPermissionEdit(['author']) && <div className={isEdit.active ? 'active' : ''} onClick={onClick.edit}><IconX name="pencil" /></div>}
                            {checkPermissionEdit(['author']) && isEdit.active && <div className="delete" onClick={onClick.delete}><IconX name="trash" /></div>}
                            {checkPermissionEdit(['author']) && isEdit.active && Object.keys(isEdit).length > 1 && <div className='save' onClick={onClick.save}><IconX name="floppy" /></div>}
                        </ContentMenu>
                        
                        <Block name="access" icon={<IconX name="file-earmark-lock" />} title={isData.name}>
                            {isData?.data && isData?.data.map((field, idx) => (
                                <div key={idx} className='field'>
                                    {isEdit.active ?
                                        <>
                                            <IconX name="trash" onClick={onClick.fieldDelete} data-idx={idx} data-name={field.name} data-value={field.value} />
                                            <InputX name={`field_name_${idx}`} label={`Название #${idx + 1}`} value={field.name} data-idx={idx} handle={onChange.inputName} />
                                            <InputX name={`field_value_${idx}`} label={`Значение #${idx + 1}`} value={field.value} data-idx={idx} handle={onChange.inputValue} />
                                        </>
                                        :
                                        <InputX name={`field_${idx}`} label={field.name} value={field.value} readonly />
                                    }
                                </div>
                            ))}
                            {isData?.data && isData?.data?.length < 1 &&
                                <AlertX type="info" icon={<IconX name="file-earmark" />}>Документ пуст</AlertX>
                            }
                            {isEdit.active && <div className="btn_default addField" onClick={onClick.addField}><IconX name="plus-lg" /> Добавить поле</div>}
                        </Block>
                    </div>
                }
            </>
        }
    </>)
}

export default AccessView