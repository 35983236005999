import React from 'react'

import IconX from '../../../Components/IconX'

import './style.scss'

const Footer = () => {

    return (<footer>
        <div className="connect">
            <div className="title"><IconX name="phone-vibrate" /> Контакты</div>
            <div className="items">
                <a className="item" href="tel:+7 (913) 000 1830" target="_blank" rel="noreferrer">
                    <div className="icon"><IconX name="telephone" /></div>
                    <div className="info">
                        <div className="name">Номер телефона</div>
                        <div className="value">+7 (913) 000 1830</div>
                    </div>
                </a>
                <a className="item" href="https://wa.me/79130001830" target="_blank" rel="noreferrer">
                    <div className="icon"><IconX name="whatsapp" /></div>
                    <div className="info">
                        <div className="name">WhatsApp</div>
                        <div className="value">+7 (913) 000 1830</div>
                    </div>
                </a>
                <a className="item" href="https://MagTehPro.t.me" target="_blank" rel="noreferrer">
                    <div className="icon"><IconX name="telegram" /></div>
                    <div className="info">
                        <div className="name">Telegram</div>
                        <div className="value">@MagTehPro</div>
                    </div>
                </a>
                <a className="item" href="mailto:info@magtehpro.ru" target="_blank" rel="noreferrer">
                    <div className="icon"><IconX name="envelope" /></div>
                    <div className="info">
                        <div className="name">Электронная почта</div>
                        <div className="value">info@magtehpro.ru</div>
                    </div>
                </a>
            </div>
        </div>
        {/* <div className="copyright">&copy; МагТехПро.рф, 2024 &mdash; {new Date().getFullYear()}</div> */}
        <div className="copyright">&copy; МагТехПро.рф, {new Date().getFullYear()}</div>
    </footer>)
}

export default Footer