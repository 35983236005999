import API from "./API"
import IconX from "./Components/IconX"

const Text = {

    numberToWord(number, words) {

        number = Math.abs(number) % 100
        const num = number % 10
        if (number > 10 && number < 20) return words[2]
        if (num > 1 && num < 5) return words[1]
        if (num === 1) return words[0]
        return words[2]
    },

    replaceAll(string, search, replace) {

        if (!string) return false

        return string.split(search).join(replace)
    },

    inputPhoneToText(data = {}) {

        let out = ''

        for (const pos of Object.keys(data)) {

            out += data[pos]
        }

        return out
    },

    toInputPhone(string = '') {

        const tmp = {}

        for (let idx = 0; idx < string.length; ++idx) {

            tmp[idx] = string[idx]
        }

        return tmp
    },

    firstUpperCase(string = '') {

        if (string?.length < 1) return string

        return string[0]?.toUpperCase() + string?.slice(1)
    },

    numbersToWords: (number, currCode) => {

        const
            words = [
                [
                    '', 'один', 'два', 'три', 'четыре', 'пять', 'шесть',
                    'семь', 'восемь', 'девять', 'десять', 'одиннадцать',
                    'двенадцать', 'тринадцать', 'четырнадцать', 'пятнадцать',
                    'шестнадцать', 'семнадцать', 'восемнадцать', 'девятнадцать'
                ],
                [
                    '', '', 'двадцать', 'тридцать', 'сорок', 'пятьдесят',
                    'шестьдесят', 'семьдесят', 'восемьдесят', 'девяносто'
                ],
                [
                    '', 'сто', 'двести', 'триста', 'четыреста', 'пятьсот',
                    'шестьсот', 'семьсот', 'восемьсот', 'девятьсот'
                ]
            ],
            rus = ['рубль', 'рубля', 'рублей'],
            toFloat = (number) => parseFloat(number),
            plural = (count, options) => {

                if (options.length !== 3) return false

                count = Math.abs(count) % 100

                const rest = count % 10

                if (count > 10 && count < 20) return options[2]
                if (rest > 1 && rest < 5) return options[1]
                if (rest === 1) return options[0]

                return options[2]
            },
            parseNumber = (number, count, currCode) => {

                let first, second, numeral = ''

                if (number.length === 3) {

                    first = number.substr(0, 1)
                    number = number.substr(1, 3)
                    numeral = '' + words[2][first] + ' '
                }

                if (number < 20) numeral = numeral + words[0][toFloat(number)] + ' '
                else {

                    first = number.substr(0, 1)
                    second = number.substr(1, 2)
                    numeral = numeral + words[1][first] + ' ' + words[0][second] + ' '
                }

                if (count === 0) {

                    switch (currCode) {

                        case 'RU':
                        default: {

                            numeral = numeral + plural(number, rus)
                        }
                    }
                } else if (count === 1) {

                    if (numeral !== '  ') {

                        numeral = numeral + plural(number, ['тысяча ', 'тысячи ', 'тысяч '])
                        numeral = numeral.replace('один ', 'одна ').replace('два ', 'две ')
                    }
                } else if (count === 2) {

                    if (numeral !== '  ') numeral = numeral + plural(number, ['миллион ', 'миллиона ', 'миллионов '])
                } else if (count === 3) numeral = numeral + plural(number, ['миллиард ', 'миллиарда ', 'миллиардов '])

                return numeral;
            },
            parseDecimals = (number) => {

                const text = plural(number, ['копейка', 'копейки', 'копеек'])

                if (number === 0) number = '00'
                else if (number < 10) number = '0' + number

                return ' ' + number + ' ' + text
            }

        if (!number) return ''

        const type = typeof number

        if (type !== 'number' && type !== 'string') return ''
        if (type === 'string') {

            number = toFloat(number.replace(',', '.'))

            if (isNaN(number)) return ''
        }

        if (number <= 0) return ''

        let
            splt,
            decimals,
            numeral = '',
            parts = '',
            count = 0,
            digit

        number = number.toFixed(2)

        if (number.indexOf('.') !== -1) {

            splt = number.split('.')
            number = splt[0]
            decimals = splt[1]
        }

        let length = number.length - 1

        while (length >= 0) {

            digit = number.substr(length, 1)
            parts = digit + parts

            if ((parts.length === 3 || length === 0) && !isNaN(toFloat(parts))) {

                numeral = parseNumber(parts, count, currCode) + numeral
                parts = ''
                count++
            }

            length--
        }

        numeral = numeral.replace(/\s+/g, ' ')

        if (decimals) numeral = numeral + parseDecimals(toFloat(decimals))

        return numeral
    }
}

const NumberX = {

    rand(min, max) {

        const rand = min + Math.random() * (max + 1 - min)

        return Math.floor(rand)
    }
}

const DateTime = {

    fix(date = new Date()) {

        try { date?.getTime() } catch (error) { return null }

        const out = {
            y: `${date.getFullYear()}`,
            m: `${(date.getMonth() + 1) < 10 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)}`,
            d: `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`,
            H: `${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}`,
            M: `${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}`,
            S: `${date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()}`,
            ms: `${date.getMilliseconds() < 10 ? "00" + date.getMilliseconds() : date.getMilliseconds() < 100 ? "0" + date.getMilliseconds() : date.getMilliseconds()}`,
            in: date,
            text: '',
            text_v2: '',
            text_v3: '',
            text_v4: ''
        }

        out.text = `${out.d}.${out.m}.${out.y} - ${out.H}:${out.M}:${out.S}`
        out.text_v2 = `${out.y}.${out.m}.${out.d} - ${out.H}:${out.M}:${out.S}`
        out.text_v3 = `${out.d}.${out.m}.${out.y}`
        out.text_v4 = `${out.H}${out.M}${out.S}${out.ms}`
        return out
    },

    parceDateTime(date = new Date()) {

        const dateTime = DateTime.fix(date)

        return dateTime.text
    },

    msToTime(
        ms = 0,
        showS = true,
        showMS = false,
        yearsText = ['год', 'года', 'лет'],
        monthsText = ['месяц', 'месяца', 'месяцев'],
        daysText = ['день', 'дня', 'дней'],
        hoursText = ['час', 'часа', 'часов'],
        minutesText = ['минута', 'минуты', 'минут'],
        secondsText = ['секунда', 'секунды', 'секунд'],
        millisecondText = ['миллисекунда', 'миллисекунды', 'миллисекунд']
    ) {

        const out_time = {
            minus: false,
            years: {},
            months: {},
            days: {},
            hours: {},
            minutes: {},
            seconds: {},
            milliseconds: {},
            text: ''
        }

        if (ms < 0) {
            out_time.minus = true
            ms = ms * -1
        }

        // Years
        out_time.years.full = ms / (1000 * 60 * 60 * 24 * 30 * 12)
        out_time.years.int = Math.floor(out_time.years.full)
        out_time.years.fix = out_time.years.int > 9 ? out_time.years.int : "0" + out_time.years.int
        out_time.years.text = out_time.years.int > 0 ? `${out_time.years.int} ${Text.numberToWord(out_time.years.int, yearsText)} ` : false

        // Months
        out_time.months.full = (out_time.years.full - out_time.years.int) * 12
        out_time.months.int = Math.floor(out_time.months.full)
        out_time.months.fix = out_time.months.int > 9 ? out_time.months.int : "0" + out_time.months.int
        out_time.months.text = out_time.months.int > 0 ? `${out_time.months.int} ${Text.numberToWord(out_time.months.int, monthsText)} ` : false

        // Days
        out_time.days.full = (out_time.months.full - out_time.months.int) * 30
        out_time.days.int = Math.floor(out_time.days.full)
        out_time.days.fix = out_time.days.int > 9 ? out_time.days.int : "0" + out_time.days.int
        out_time.days.text = out_time.days.int > 0 ? `${out_time.days.int} ${Text.numberToWord(out_time.days.int, daysText)} ` : false

        // Hours
        out_time.hours.full = (out_time.days.full - out_time.days.int) * 24
        out_time.hours.int = Math.floor(out_time.hours.full)
        out_time.hours.fix = out_time.hours.int > 9 ? out_time.hours.int : "0" + out_time.hours.int
        out_time.hours.text = out_time.hours.int > 0 ? `${out_time.hours.int} ${Text.numberToWord(out_time.hours.int, hoursText)} ` : false

        // Minutes
        out_time.minutes.full = (out_time.hours.full - out_time.hours.int) * 60
        out_time.minutes.int = Math.floor(out_time.minutes.full)
        out_time.minutes.fix = out_time.minutes.int > 9 ? out_time.minutes.int : "0" + out_time.minutes.int
        out_time.minutes.text = out_time.minutes.int > 0 ? `${out_time.minutes.int} ${Text.numberToWord(out_time.minutes.int, minutesText)} ` : false

        // Seconds
        out_time.seconds.full = (out_time.minutes.full - out_time.minutes.int) * 60
        out_time.seconds.int = Math.floor(out_time.seconds.full)
        out_time.seconds.fix = out_time.seconds.int > 9 ? out_time.seconds.int : "0" + out_time.seconds.int
        out_time.seconds.text = out_time.seconds.int > 0 ? `${out_time.seconds.int} ${Text.numberToWord(out_time.seconds.int, secondsText)} ` : false

        // Miliseconds
        out_time.milliseconds.full = (out_time.seconds.full - out_time.seconds.int) * 1000
        out_time.milliseconds.int = Math.floor(out_time.milliseconds.full)
        out_time.milliseconds.fix = out_time.milliseconds.int > 99 ? out_time.milliseconds.int : out_time.milliseconds.int > 9 ? '0' + out_time.milliseconds.int : '00' + out_time.milliseconds.int
        out_time.milliseconds.text = out_time.milliseconds.int > 0 ? `${out_time.milliseconds.int} ${Text.numberToWord(out_time.milliseconds.int, millisecondText)}` : false

        if (!showMS
            && out_time.years.int === 0
            && out_time.months.int === 0
            && out_time.days.int === 0
            && out_time.hours.int === 0
            && out_time.minutes.int === 0
            && out_time.seconds.int === 0
        ) out_time.text = 'Меньше секунды'
        else if (out_time.seconds.int === 0
            && out_time.years.int === 0
            && out_time.months.int === 0
            && out_time.days.int === 0
            && out_time.hours.int === 0
            && out_time.minutes.int === 0
            && out_time.seconds.int === 0
            && out_time.milliseconds.int === 0
        ) out_time.text = 'Меньше миллисекунды'
        else out_time.text = ''
            + `${out_time.years.text || ''}`
            + `${out_time.months.text || ''}`
            + `${out_time.days.text || ''}`
            + `${out_time.hours.text || ''}`
            + `${out_time.minutes.text || ''}`
            + `${(showS && out_time.seconds.text) || ''}`
            + `${(showMS && out_time.milliseconds.text) || ''}`

        return out_time
    },

    fixDateTimeLocal: (dateTime) => {

        if (dateTime) {

            const dateTimeFix = DateTime.fix(dateTime)

            return `${dateTimeFix.y}-${dateTimeFix.m}-${dateTimeFix.d}T${dateTimeFix.H}:${dateTimeFix.M}`
        }

        return ''
    }
}


const Kludge = {

    deleteX(object = {}, del = []) {

        for (const a of Object.keys(object)) {

            for (const b of del) {

                if (a === b) delete (object[a])
            }
        }

        return JSON.parse(JSON.stringify(object))
    },

    compareX(object_1, object_2) {

        if (!object_1 || !object_2) return false

        const props = Object.getOwnPropertyNames(object_1)

        if (props.length !== Object.getOwnPropertyNames(object_2).length) return false

        for (const a of props) {

            const isObject = typeof (object_1[a]) === 'object' && typeof (object_2[a]) === 'object'

            if (
                (!isObject && (object_1[a] !== object_2[a])) ||
                (isObject && !Kludge.compareX(object_1[a], object_2[a]))
            ) return false
        }

        return true
    },

    copyObject(obj) {

        return JSON.parse(JSON.stringify(obj))
    },

    convertUnitByte(byte) {

        const data = {
            _: byte,
            unit: 'Байт',
            text: ''
        }

        if (data._ >= 1099511627776) {

            data._ = Number((data._ / (1024 * 1024 * 1024 * 1024)).toFixed(1))
            data.unit = 'Тб'
        } else if (data._ >= 1073741824) {

            data._ = Number((data._ / (1024 * 1024 * 1024)).toFixed(1))
            data.unit = 'Гб'
        } else if (data._ >= 1048576) {

            data._ = Number((data._ / (1024 * 1024)).toFixed(1))
            data.unit = 'Mб'
        } else if (data._ >= 1024) {

            data._ = Number((data._ / 1024).toFixed(1))
            data.unit = 'Kб'
        }

        data.text = `${data._} ${data.unit}`

        return data
    },

    visibleContentScroll(visible = true, voider = 'MAIN') {

        const
            html = document.querySelector('html'),
            body = document.querySelector('body'),
            ContentMenu = document.querySelector('#ContentMenu'),
            content_lk = document.querySelector('.content.lk'),
            navBar_lk = document.querySelector('#NavBar.lk'),
            content_workSpace_content_lk = document.querySelector('#content>#workSpace>.content.lk')

        // if (!document?.querySelector('.content.lk')?.style) return false

        if (visible) {

            if (html) html.style.overflowY = 'auto'
            if (body) body.style.overflowY = 'auto'
            if (content_lk) content_lk.style.overflowY = 'auto'
            if (ContentMenu) ContentMenu?.classList?.remove('hide')
            if (navBar_lk) navBar_lk?.classList?.remove('hide')
            if (content_workSpace_content_lk) content_workSpace_content_lk?.classList?.remove('dialogX')

        } else {

            if (html) html.style.overflowY = 'hidden'
            if (body) body.style.overflowY = 'hidden'
            if (content_lk) content_lk.style.overflowY = 'hidden'
            if (ContentMenu) ContentMenu?.classList?.add('hide')
            if (navBar_lk) navBar_lk?.classList?.add('hide')
            if (content_workSpace_content_lk) content_workSpace_content_lk?.classList?.add('dialogX')
        }
        return true
    },

    getPercent: (current, max) => current / max * 100,
    toRUB: (value) => value?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })
}

const checkUserStatus = (statuses = false, currentStatus = false) => {

    if (!statuses) return true
    if (!currentStatus) return true

    for (const status of statuses) {

        if (status === currentStatus) return true
    }

    return false
}

const getUserInfo = () => new Promise(async (resolve) => {

    const Browsers = [
        {
            string: navigator.userAgent,
            subString: 'Chrome',
            versionSearch: 'Chrome',
            identity: 'Google Chrome'
        },
        {
            string: navigator.userAgent,
            subString: 'OmniWeb',
            versionSearch: 'OmniWeb/',
            identity: 'OmniWeb'
        },
        {
            string: navigator.vendor,
            subString: 'Apple',
            identity: 'Safari',
            versionSearch: 'Version'
        },
        {
            prop: window.opera,
            identity: 'Opera',
            versionSearch: 'Version'
        },
        {
            string: navigator.vendor,
            subString: 'iCab',
            identity: 'iCab'
        },
        {
            string: navigator.vendor,
            subString: 'KDE',
            identity: 'Konqueror'
        },
        {
            string: navigator.userAgent,
            subString: 'Firefox',
            identity: 'Firefox'
        },
        {
            string: navigator.vendor,
            subString: 'Camino',
            identity: 'Camino'
        },
        {
            /* For Newer Netscapes (6+) */
            string: navigator.userAgent,
            subString: 'Netscape',
            identity: 'Netscape'
        },
        {
            string: navigator.userAgent,
            subString: 'MSIE',
            identity: 'Internet Explorer',
            versionSearch: 'MSIE'
        },
        {
            string: navigator.userAgent,
            subString: 'Gecko',
            identity: 'Mozilla',
            versionSearch: 'rv'
        },
        {
            /* For Older Netscapes (4-) */
            string: navigator.userAgent,
            subString: 'Mozilla',
            identity: 'Netscape',
            versionSearch: 'Mozilla'
        }
    ], OS = [
        {
            string: navigator.userAgent,
            subString: 'Windows',
            identity: 'Windows'
        },
        {
            string: navigator.userAgent,
            subString: 'Mac',
            identity: 'Mac'
        },
        {
            string: navigator.userAgent,
            subString: 'iPhone',
            identity: 'iPhone/iPod'
        },
        {
            string: navigator.userAgent,
            subString: 'Linux',
            identity: 'Linux'
        }
    ],
        Devices = [
            {
                string: navigator.userAgent,
                subString: 'Windows',
                identity: 'ПК'
            },
            {
                string: navigator.userAgent,
                subString: 'Linux; Android',
                identity: 'Android',
                versionSearch: 'Android',
                deleteString: 'Linux; '
            },
            {
                string: navigator.userAgent,
                subString: 'iPhone',
                identity: 'iPhone',
                versionSearch: 'iPhone',
                deleteString: ''
            }
        ]

    let versionSearchString = '', versionSearchDeleteString = ''

    const searchString = (data) => {

        for (const a of data) {

            versionSearchString = a.versionSearch || a.identity || false
            versionSearchDeleteString = a.deleteString || ''
            if (a.string && a.string.indexOf(a.subString) !== -1) return a.identity || false
            if (a.prop) return a.identity || false
        }
    }

    const searchVersionBrowser = (data) => {

        const index = data.indexOf(versionSearchString)
        if (index === -1) return
        return parseFloat(data.substring(index + versionSearchString.length + 1))
    }

    const searchVersionOS = (data) => {

        const index = data.indexOf(versionSearchString)
        if (index === -1) return
        return parseFloat(data.substring(index + versionSearchString.length + (data.indexOf('NT') ? 4 : 1), data.indexOf(';')))
    }

    const searchVersionDevice = (data) => {

        const index = data.indexOf(versionSearchString)
        data = Text.replaceAll(data, versionSearchDeleteString, '')
        if (index === -1) return
        return parseFloat(data.substring(index + 1, data.indexOf(';')))
    }

    const out = {
        browser: {
            name: searchString(Browsers),
            version: searchVersionBrowser(window.navigator.userAgent) || searchVersionBrowser(window.navigator.appVersion) || false
        },
        os: {
            name: searchString(OS) || false,
            version: searchVersionOS(window.navigator.userAgent) || searchVersionOS(window.navigator.appVersion) || false
        },
        device: {
            name: searchString(Devices),
            version: searchVersionDevice(window.navigator.userAgent) || searchVersionDevice(window.navigator.appVersion) || false
        }
    }

    out.yandexId = await YandexMetrika.getClientID()
    return resolve(out)
})

const setHeaderTitle = (title) => setTimeout(() => {

    const headerTitle = document.querySelector('nav.lk>.title')

    if (!headerTitle) return false

    headerTitle.textContent = title
    return true
}, 50)

/**
 * formatName(user, short = false, url = true, popup = true)
 */
const formatName = (user, short = false, url = true, popup = true) => {

    let outName = ''

    if (user?.name?.first) outName += user?.name?.first
    if (user?.name?.middle) outName += ' ' + user?.name?.middle
    if (!short && user?.name?.last) outName += ' ' + user?.name?.last

    return url ?
        <a id="formatName" className="brand" href={`/lk/user/${user?._id}`} target='_blank' rel="noreferrer">
            {outName}
            {popup && <div className="info">
                {/* {user?.avatar && <img className="avatar" src={user?.avatar} alt={outName} />} */}
                <div><img className="avatar" src={API.Files.GetPrivate(user?.avatar)} alt={outName} /></div>
                <div><b>Статус: </b>{parceUserStatus(user?.status) || 'Неизвестно'}</div>
                <div><b>Последнее визит: </b>{user?.dateTime_last ? DateTime.fix(new Date(user?.dateTime_last)).text : 'Неизвестно'}</div>
            </div>}
            <IconX name="box-arrow-up-right" />
        </a>
        :
        outName
}

const checkPermissionUserByRequest = (User, Request, typeList = [], edit = false, newRequest = false, closeRequest = false) => {

    if (!User) return false
    if (!Request) return false

    for (const type of typeList) {

        switch (type) {

            case 'admin': {

                if (closeRequest && Request?.status?.className === 'closed') return false
                if (User?.status === 'gAdmin') return true
                if (User?.status === 'moder') return true

                break
            }

            case 'author': {

                if (closeRequest && Request?.status?.className === 'closed') return false
                if (User?.status === 'gAdmin') return true
                if (User?.status === 'moder') return true
                if (edit) {

                    if (User?._id === Request?.author?._id && (newRequest && Request?.status?.className === 'new')) return true
                }
                else if (User?._id === Request?.author?._id) return true

                break
            }

            case 'client': {

                if (closeRequest && Request?.status?.className === 'closed') return false
                if (User?.status === 'gAdmin') return true
                if (User?.status === 'moder') return true
                if (edit) {

                    if (User?._id === Request?.client?._id && (newRequest && Request?.status?.className === 'new')) return true

                } else if (User?._id === Request?.client?._id) return true

                break
            }

            default: { }
        }
    }

    return false
}

const sortBy = (array, key, invert = false) => array.sort((a, b) => {

    if (a[key]?.toLowerCase() < b[key]?.toLowerCase()) return invert ? 1 : -1
    if (a[key]?.toLowerCase() > b[key]?.toLowerCase()) return invert ? -1 : 1

    return 0
})

const parceUserStatus = (status) => {

    switch (status) {

        case 'gAdmin': return 'Руководитель'
        case 'moder': return 'Сотрудник'
        case 'client': return 'Клиент'
        default: return 'Неизвестно'
    }
}

const parceTypeWork = (type) => {

    switch (type) {

        case 'unit': return 'за единицу'
        case 'metr': return 'за метр'
        case 'pmetr': return 'за погонный метр'
        case 'timeHour': return 'почасовая'
        case 'income': return 'прибыль'
        case 'expenses': return 'расходы'
        default: return 'Неизвестно'
    }
}

const parceTypeWorkUnit = (type) => {

    switch (type) {

        case 'unit': return 'шт.'
        case 'metr': return 'м.'
        case 'pmetr': return 'п.м.'
        case 'timeHour': return 'ч.'
        default: return 'Неизвестно'
    }
}

const parceMessageFormatedSync = (messages) => {

    for (const message of messages) {

        const parceContent = []

        if (!message?.content?.text) continue
        if (typeof message?.content?.text !== 'string') continue

        message.content.text.replace(/((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim, (m, link, text) => {

            if (link) {

                const url = new URL(link)

                parceContent.push(<a href={link} target="_blank" rel="noreferrer">{url?.protocol === 'https:' ? <IconX name="shield-check" /> : <IconX name="shield-exclamation" />}{url?.host}</a>)
            } else {

                let rpl = false

                text.replace(/\n/g, (highlight) => {

                    parceContent.push(<br />)
                    rpl = true
                })

                text.replace(/(\*{1,2})(.*?)\1/g, (fullMatch, tagStart, tagContents) => {

                    parceContent.push(<b>{tagContents}</b>)
                    rpl = true
                })

                text.replace(/(\_{1,2})(.*?)\1/g, (fullMatch, tagStart, tagContents) => {

                    parceContent.push(<i>{tagContents}</i>)
                    rpl = true
                })

                if (!rpl) parceContent.push(text)
            }
        })

        message.content.text = parceContent
    }

    return messages
}

const parceDeviceTypeClient = (device) => {

    switch (device) {

        case 'ios': return <><IconX name="apple" /></>
        case 'android': return <><IconX name="android2" /></>
        case 'web': return <><IconX name="browser-chrome" /></>
        case 'system': return <><IconX name="code-slash" /></>
        default: return <><IconX name="cpu" /></>
    }
}

const parceNameIncome = (type) => {

    switch (type) {

        case 'work': return <>Оплата за услугу</>
        default: return <>Неизвестная оплата</>
    }
}

const YandexMetrika = {

    _: null,
    version: 'https://mc.yandex.ru/metrika/tag.js',
    clientID: Text.replaceAll(localStorage.getItem('_ym_uid') || false, '"', '') || -1830,

    init: (counterId, options = {}) => {

        const scripts = document.getElementsByName('script')

        for (const script of scripts) {

            if (script.src === YandexMetrika.version) return false
        }

        const script = document.createElement('script')

        script.type = 'text/javascript'
        script.async = true
        script.src = YandexMetrika.version

        Object.keys(options).map((name) => {

            if (script.__proto__.hasOwnProperty(name)) script.setAttribute(name, options[name])
        })

        script.onload = () => {

            YandexMetrika._ = new window.Ya.Metrika2({

                id: counterId,
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true
            })

            YandexMetrika.clientID = YandexMetrika._.getClientID()
        }

        document.head.appendChild(script)
    },

    getClientID: () => new Promise((resolve) => YandexMetrika.checkInit(() => resolve(YandexMetrika.clientID))),

    checkInit: (callback) => {

        if (!YandexMetrika._) return setTimeout(() => YandexMetrika.checkInit(callback), 100)

        return callback()
    }
}

export {

    checkUserStatus,
    getUserInfo,
    DateTime,
    Text,
    NumberX,
    Kludge,
    YandexMetrika,
    setHeaderTitle,
    formatName,
    checkPermissionUserByRequest,
    sortBy,
    parceTypeWork,
    parceTypeWorkUnit,
    parceMessageFormatedSync,
    parceDeviceTypeClient,
    parceNameIncome
}