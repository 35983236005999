import { useEffect } from 'react'

import IconX from '../IconX'
import { Kludge } from '../../FuncX'

import './style.scss'

const DialogX = ({ children, id, title, close, full = false, ...props }) => {

    const
        isInit = { mount: false }

    const Mount = () => {

        isInit.mount = true
        Kludge.visibleContentScroll(false, 'MODULE')

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
        Kludge.visibleContentScroll(true, 'MODULE')
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div id="DialogX" className={`dialog-id-${id}${full ? ' full': ''}`} {...props}>
        <div className='block'>
            <div className='header'>
                <div className='text'>{title}</div>
                <IconX name="x-lg" id='close' onClick={close} />
            </div>
            <div className='content'>{children}</div>
        </div>
    </div>)
}

export default DialogX