import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { DateTime, Kludge, Text, checkPermissionUserByRequest, checkUserStatus, formatName, parceTypeWorkUnit, setHeaderTitle, sortBy } from '../../../../FuncX'
import AlertX from '../../../../Components/AlertX'
import IconX from '../../../../Components/IconX'
import InputX from '../../../../Components/InputX'
import { animationElementClick, animationElementError, animationElementLoadingStart, animationElementLoadingStop } from '../../../../Components/Animations'
import Loading from '../../../../Components/Loading'
import Error from '../../../../Components/Error'
import ButtonX from '../../../../Components/ButtonX'
import ContentMenu from '../../../../Components/ContentMenu'
import DialogX from '../../../../Components/DialogX'
import RequestsViewWorksPrint from './print'
import API from '../../../../API'

import './index.scss'

const
    _def = { isInit: { mount: false, loading: false } },
    _ = _def

const RequestsViewWorks = ({ User }) => {

    const
        { reqId } = useParams(),

        [isDialog, setDialog] = useState(false),
        [isPrint, setPrint] = useState(false),
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [isRequest, setRequest] = useState(null),
        [isWorks, setWorks] = useState([]),
        [isCash, setCash] = useState([]),
        [isData, setData] = useState(null),
        [errorAdd, setErrorAdd] = useState(false),
        defaultCost = { id: false, count: 1, value: 'none', time: 0, type: 'unit', comment: '' },
        [isCost, setCost] = useState(defaultCost),
        [isEdit, setEdit] = useState({ status: false, idWork: false })

    const onClick = {

        add: async (e) => {

            let res

            setError({ status: false })
            setErrorAdd(false)
            setLoading({ status: true, msg: 'Добавление работы...' })
            animationElementClick(e.currentTarget)

            try {

                res = await API.Requests.WorkAdd(reqId, {

                    work: isCost.id,
                    count: document.querySelector('#work_count')?.value || -1,
                    value: document.querySelector('#work_cost')?.value || -1,
                    time: document.querySelector('#work_time')?.value || -1,
                    comment: document.querySelector('#work_comment')?.value
                })
            } catch (error) { res = { status: false, result: error.message } }

            if (!res.status) {

                setErrorAdd(<>Во время добавление работы произошла ошибка.<br /><br />{res.result}</>)
                setLoading({ status: false })
                return false
            }

            await getWorks()
            setCost(defaultCost)
            setLoading({ status: false })
            return true
        },

        delete: async (e, idWork) => {

            if (!idWork) return false

            const workDIV = document.querySelector(`.list.id_${idWork}`)
            let res

            setError({ status: false })
            setEdit({ status: false, idWork: false })
            animationElementLoadingStart(workDIV)

            try { res = await API.Requests.WorkRemove(idWork) }
            catch (error) { res = { status: false, result: error.message } }

            if (!res?.status) {

                setError({ status: true, title: 'удаления работы', msg: res.result })
                animationElementLoadingStop(workDIV)
                animationElementError(workDIV)
                return false
            }

            await getWorks(true)
            animationElementLoadingStop(workDIV)
            return true
        },

        edit: async (e, idWork) => {

            if (isEdit.status) {

                if (isEdit.idWork === idWork) setEdit({ status: false })
            } else setEdit({ status: true, idWork })
        },

        save: async (e, idWork) => {

            if (!idWork) return false

            const data = {

                count: Number(document.querySelector(`#work_count___${idWork}`)?.value) || -1,
                time: Number(document.querySelector(`#work_time___${idWork}`)?.value) || -1,
                value: Number(document.querySelector(`#work_cost___${idWork}`)?.value),
                comment: document.querySelector(`#work_comment___${idWork}`)?.value
            }

            const workDIV = document.querySelector(`.list.id_${idWork}`)
            let res

            setError({ status: false })
            setEdit({ status: false, idWork: false })
            animationElementLoadingStart(workDIV)

            try { res = await API.Requests.WorkUpdate(idWork, data) }
            catch (error) { res = { status: false, result: error.message } }

            if (!res?.status) {

                setError({ status: true, title: 'сохранение работы', msg: res.result })
                animationElementLoadingStop(workDIV)
                animationElementError(workDIV)
                return false
            }

            await getWorks(true)
            animationElementLoadingStop(workDIV)
            return true
        },

        paid: {

            _: async (e) => {

                setDialog({

                    id: 'paid_mode',
                    title: <><IconX name='wallet2' />Оплата</>,
                    content: <Loading module>Получение списка оплат...</Loading>
                })

                let query

                try { query = await API.Requests.PaidGet(reqId) }
                catch (error) { query = { status: false, result: error?.message } }

                return setDialog({

                    id: 'paid_mode',
                    title: <><IconX name='wallet2' />Оплата</>,
                    content: <>
                        <ButtonX iconUse full name="paid_add" onUse={onClick.paid.add.info}><IconX name='plus-lg' />Добавить</ButtonX>
                        <div className="delimiter"></div>
                        <div className="paids">
                            {!query?.status ?
                                <Error msg={query?.result} />
                                :
                                <>
                                    {query?.result?.length > 0 ?
                                        <>
                                            {query?.result?.map(({ _id, dateTime_fact, value, comment, expenses }) => (<div className="paid">
                                                <ButtonX red name="paid_delete" confirm="Удалить?" onUse={(e) => onClick.paid.delete(e, _id)}><IconX name='trash' /></ButtonX>
                                                <div className="dateTime_fact">{DateTime.fix(new Date(dateTime_fact)).text}</div>
                                                <div className="cost">{value?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</div>
                                                {expenses && <div className="expenses">Расходы</div>}
                                                <div className="comment">{comment}</div>
                                                <ButtonX name="paid_edit" onUse={(e) => onClick.paid.edit.info(e, { _id, dateTime_fact, value, comment, expenses })}><IconX name='pencil' /></ButtonX>
                                            </div>))}
                                        </>
                                        :
                                        <div className="paid no">Нет оплат</div>
                                    }
                                </>
                            }
                        </div>
                    </>
                })
            },

            add: {

                info: async (e, error = false) => setDialog({

                    id: 'paid_mode',
                    title: <><IconX name='wallet2' />Оплата {'>'} Добавить</>,
                    content: <>
                        <ButtonX iconUse full name="paid_back" onUse={onClick.paid._}><IconX name='arrow-left' />Вернуться</ButtonX>
                        <div className="delimiter"></div>
                        {error && <Error msg={error} />}
                        <InputX type="number" name="paid_value" label="Сумма" />
                        <InputX type="datetime-local" name="paid_dateTime_fact" label="Фактическая дата оплаты" />
                        <InputX textarea name="paid_comment" label="Комментарий (не обязательно)" />
                        <InputX checkbox name="paid_expenses" label="Расходы" />
                        <ButtonX iconUse full name="paid_add_complete" onUse={onClick.paid.add.set}><IconX name='plus-lg' />Добавить</ButtonX>
                    </>
                }),

                set: async (e) => {

                    const paid_value = document?.querySelector('.id_paid_value > input')
                    const paid_dateTime_fact = document?.querySelector('.id_paid_dateTime_fact > input')
                    const paid_comment = document?.querySelector('.id_paid_comment > textarea')
                    const paid_expenses = document?.querySelector('.id_paid_expenses > div > input')

                    if (!Number(paid_value?.value)) return animationElementError(paid_value, true, true)
                    if (!paid_dateTime_fact?.value) return animationElementError(paid_dateTime_fact, true, true)

                    setDialog({

                        id: 'paid_mode',
                        title: <><IconX name='wallet2' />Оплата {'>'} Добавить</>,
                        content: <Loading module>Добавление оплаты...</Loading>
                    })

                    let query

                    try {

                        query = await API.Requests.PaidAdd({

                            request: reqId,
                            user: isRequest?.corp ? null : isRequest?.client?._id,
                            corp: isRequest?.corp?._id,
                            value: Number(paid_value?.value),
                            dateTime_fact: new Date(paid_dateTime_fact?.value)?.toISOString(),
                            comment: paid_comment?.value,
                            expenses: Boolean(paid_expenses?.checked) || false
                        })
                    } catch (error) { query = { status: false, result: error.message } }

                    if (!query?.status) return onClick.paid.add.info(e, query?.result)

                    getWorks()
                    return onClick.paid._(e)
                }
            },

            edit: {

                info: async (e, data, error = false) => setDialog({

                    id: 'paid_mode',
                    title: <><IconX name='wallet2' />Оплата {'>'} Изменить</>,
                    content: <>
                        <ButtonX iconUse full name="paid_back" onUse={onClick.paid._}><IconX name='arrow-left' />Вернуться</ButtonX>
                        <div className="delimiter"></div>
                        {error && <Error msg={error} />}
                        <InputX type="number" name="paid_value" label="Сумма" value={data?.value} />
                        <InputX type="datetime-local" name="paid_dateTime_fact" label="Фактическая дата оплаты" value={DateTime.fixDateTimeLocal(new Date(data?.dateTime_fact))} />
                        <InputX textarea name="paid_comment" label="Комментарий (не обязательно)" value={data?.comment} />
                        <InputX checkbox name="paid_expenses" label="Расходы" value={data?.expenses} />
                        <ButtonX iconUse full name="paid_add_complete" onUse={(e) => onClick.paid.edit.set(e, data)}><IconX name='floppy' />Изменить</ButtonX>
                    </>
                }),

                set: async (e, data) => {

                    const paid_value = document?.querySelector('.id_paid_value > input')
                    const paid_dateTime_fact = document?.querySelector('.id_paid_dateTime_fact > input')
                    const paid_comment = document?.querySelector('.id_paid_comment > textarea')
                    const paid_expenses = document?.querySelector('.id_paid_expenses > div > input')

                    if (!Number(paid_value?.value)) return animationElementError(paid_value, true, true)
                    if (!paid_dateTime_fact?.value) return animationElementError(paid_dateTime_fact, true, true)

                    setDialog({

                        id: 'paid_mode',
                        title: <><IconX name='wallet2' />Оплата {'>'} Изменить</>,
                        content: <Loading module>Изменение оплаты...</Loading>
                    })

                    let query

                    try {
                        
                        query = await API.Requests.PaidEdit(data?._id, {
                            
                            user: isRequest?.corp ? null : isRequest?.client?._id,
                            corp: isRequest?.corp?._id,
                            value: Number(paid_value?.value),
                            dateTime_fact: new Date(paid_dateTime_fact?.value)?.toISOString(),
                            comment: paid_comment?.value,
                            expenses: Boolean(paid_expenses?.checked) || false
                        })
                    } catch (error) { query = { status: false, result: error?.message } }

                    if (!query?.status) return onClick.paid.edit.info(e, data, query?.result)

                    getWorks()
                    return onClick.paid._(e)
                }
            },

            delete: async (e, id) => {

                setDialog({

                    id: 'paid_mode',
                    title: <><IconX name='wallet2' />Оплата {'>'} Удалить</>,
                    content: <Loading module>Удаление оплаты...</Loading>
                })

                let query

                try { query = await API.Requests.PaidDelete(id) }
                catch (error) { query = { status: false, result: error?.message } }

                getWorks()
                return onClick.paid._(e)
            }
        },

        print: async (e) => {

            setDialog({

                loading: true,
                id: 'print_mode',
                title: <><IconX name='printer' />Печать</>,
                content: <>
                    <div className="contract">
                        <ButtonX name="print_contract" onUse={onClick.print_contract}>Счёт-договор</ButtonX>
                        <InputX name="print_contract_prepay" label="Предоплата" value={30} />
                    </div>
                    <ButtonX name="print_act_work" full>Акт выполненных работ</ButtonX>
                </>
            })
        },

        print_contract: (e) => {

            const inputPrepay = e?.parentElement?.querySelector('.id_print_contract_prepay>input')
            const num = `${DateTime.fix().text_v4}${isRequest?.code}`
            const title = `[МагТехПро] Счёт-договор № ${num} от ${DateTime.fix(new Date()).text_v3}`

            setPrint({ title, num, prepay: inputPrepay.value })
            return true
        }
    }

    const onChange = {

        works_select: (e) => {

            e.target.options[0].disabled = true
            setCost({

                id: e.target.options[e.target.selectedIndex].value,
                value: Number(e.target.options[e.target.selectedIndex].dataset.cost),
                count: 1,
                time: Number(e.target.options[e.target.selectedIndex].dataset.time),
                type: e.target.options[e.target.selectedIndex].dataset.type,
                comment: isCost?.comment || ''
            })
            return true
        }
    }

    const getWorksAll = () => new Promise(async (resolve) => {

        let res

        setError(false)
        setLoading({ status: true, msg: 'Загрузка всех работ...' })

        try { res = await API.Works.Get() }
        catch (error) { res = { status: false, result: error.message } }

        if (!res.status) {

            setError({ status: true, title: 'загрузки всех работ', msg: res.result })
            setLoading({ status: false })
            return resolve(false)
        }

        setWorks(res.result)
        setLoading({ status: false })
        return resolve(true)
    })

    const getWorks = (silent = false) => new Promise(async (resolve) => {

        setError(false)
        if (!silent) setLoading({ status: true, msg: 'Загрузка списка работ заявки...' })

        let res

        try {

            res = await API.Requests.GetWorks(reqId, silent ? null : (progressEvent) => {

                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                return setLoading({ status: true, msg: 'Загрузка списка работ заявки...', progress: percentage })
            })
        } catch (error) { res = { status: false, result: error.message } }

        if (!res.status) {

            setError({ status: true, title: 'загрузки списка работ заявки', msg: res.result })
            setLoading({ status: false })
            return false
        }

        setData(sortBy(res?.result?.works, 'dateTime_create', true))
        setCash(res?.result?.cash)
        setLoading({ status: false })
        _.isInit.loading = false
        return resolve(true)
    })

    const getData = () => new Promise(async (resolve) => {

        if (_.isInit.loading) return false

        _.isInit.loading = true

        setError(false)
        setLoading({ status: true, msg: 'Загрузка заявки...' })

        if (!reqId) {

            setError({ status: true, title: 'загрузки заявки', msg: 'Неверная заявка' })
            setLoading({ status: false })
            return resolve(false)
        }

        let res

        try {

            res = await API.Requests.Get(reqId, (progressEvent) => {

                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                return setLoading({ status: true, msg: 'Загрузка заявки...', progress: percentage })
            })
        } catch (error) { res = { status: false, result: error.message } }

        if (!res.status) {

            setError({ status: true, title: 'загрузки заявки', msg: res.result })
            setLoading({ status: false })
            return resolve(false)
        }

        res.result = res.result?.[0] || null

        setRequest(res.result)
        setHeaderTitle(`Заявка #${res.result?.code} > Список работ`)

        if (checkPermissionUserByRequest(User, res.result, ['admin'], false, false, true)) await getWorksAll()

        return resolve(await getWorks())
    })

    const parceWorksToPrint = (works) => {

        const tmp = []

        for (const wrk of works) {

            if (wrk.work_type !== 'income' && wrk.work_type !== 'expenses') tmp.push(wrk)
        }

        return tmp
    }

    const parcePayTitle = () => {

        if (isCash?.pay < 1) return <span className="notPaid">Нет оплат</span>
        else if (isCash?.pay >= isCash?.all) return <span className="paid">Оплачено</span>
        else return <span className="partlyPaid">{Kludge.toRUB(isCash?.pay)} из {Kludge.toRUB(isCash?.all)}</span>
    }

    const Mount = () => {

        _.isInit.mount = true
        getData()
        setHeaderTitle('Загрузка...')

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        _.isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <div id='RequestsViewWorks'>
            {isDialog &&
                <DialogX id={isDialog.id} title={isDialog.title} full={isDialog.full ?? false} close={() => { setDialog(false) }}>
                    {isDialog.content}
                </DialogX>
            }
            {isPrint && <RequestsViewWorksPrint
                title={isPrint?.title}
                num={isPrint?.num}
                request={isRequest}
                prepay={isPrint?.prepay}
                works={parceWorksToPrint(isData)}
                onUnload={() => setPrint(false)}
            />}
            <ContentMenu>
                <Link to={`/lk/req/${reqId}`}><IconX name='chevron-left' />К заявке</Link>
                {!isLoading.status && !isError.status && isData?.length > 0 && <>
                    {checkPermissionUserByRequest(User, isRequest, ['admin'], false, false, true) && <div onClick={onClick.paid._}><IconX name='wallet2' />Оплата ({parcePayTitle()})</div>}
                    {checkPermissionUserByRequest(User, isRequest, ['admin'], false, false, true) && <div onClick={onClick.print}><IconX name='printer' />Печать</div>}
                    <div className="info">Колличество работ: {isData.length}</div>
                </>}
            </ContentMenu>

            {isLoading.status ?
                <Loading module progress={isLoading.progress} >{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            {checkPermissionUserByRequest(User, isRequest, ['admin'], false, false, true) &&
                                <div className='newWork'>
                                    {errorAdd && <AlertX className='error' type='error' icon={<IconX name="server" />}>{errorAdd}</AlertX>}
                                    <>
                                        <InputX select name='work_type' type='number' label='Выберите услугу' required={true} handle={onChange.works_select} value={isCost.id ? isCost.id : 'none'}>
                                            <option data-cost='0' value="none">Выберите услугу</option>
                                            {isWorks.map((work) => (<option key={work?._id} data-cost={work?.cost} data-time={work?.time || 0} data-type={work?.type} value={work?._id}>{work?.name}</option>))}
                                        </InputX>
                                        {isCost.value !== "none" &&
                                            <>
                                                {(isCost.type !== 'income' && isCost.type !== 'expenses') &&
                                                    <>
                                                        {isCost.type !== 'timeHour' ?
                                                            <InputX name='work_count' type='number' label='Количество' required={true} value={isCost.count} />
                                                            :
                                                            <InputX name='work_time' type='number' label='Время' required={true} value={isCost.time} />
                                                        }
                                                    </>
                                                }
                                                <InputX name='work_cost' type='number' label='Цена' required={true} value={isCost.value} />
                                                <InputX textarea name='work_comment' label='Комментарий к сумме (необязательно)' required value={isCost.comment} />
                                                <ButtonX iconUse full onUse={onClick.add}><IconX name="plus-lg" /> Добавить</ButtonX>
                                            </>
                                        }
                                    </>
                                </div>
                            }
                            {isData?.length > 0 ?
                                <>
                                    {isData.map((work, idx) => (
                                        <div className={`list id_${work?._id}`}>
                                            <div className="value" key={work?._id}>
                                                <div className="head">
                                                    {checkPermissionUserByRequest(User, isRequest, ['admin'], false, false, true) && !isEdit.status && <ButtonX onUse={(e) => { onClick.edit(e, work?._id) }}><IconX name="pencil" /></ButtonX>}
                                                    {/* <img className="avatar" src={work.author.avatar} alt={work.author._id} /> */}
                                                    <div className="info">
                                                        <div className={`name${work?.work_type === 'income' ? ' green' : ''}${work?.work_type === 'expenses' ? ' red' : ''}`}>
                                                            <IconX name='cart' /> {work.work.name}
                                                        </div>
                                                        <div className="author">
                                                            <b>Автор: </b>{formatName(work?.author)}
                                                        </div>
                                                        <div className="dateTime_Create">
                                                            <b>Дата создания: </b>{DateTime.fix(new Date(work.dateTime_create)).text}
                                                        </div>
                                                        {checkUserStatus(['gAdmin'], User?.status) && <div className="dateTime_Create">
                                                            <b>ID: </b>{work?._id}
                                                        </div>}
                                                    </div>
                                                </div>
                                                {isEdit.status && isEdit.idWork === work?._id ?
                                                    <div className="data">
                                                        <div className="text">
                                                            {(work?.work_type !== 'income' && work?.work_type !== 'expenses') &&
                                                                <>
                                                                    {work?.work_type !== 'timeHour' ?
                                                                        <InputX name={`work_count___${work?._id}`} type='number' label='Количество' required={true} value={work?.count} />
                                                                        :
                                                                        <InputX name={`work_time___${work?._id}`} type='number' label='Время' required={true} value={work?.time} />
                                                                    }
                                                                </>
                                                            }
                                                            <InputX name={`work_cost___${work?._id}`} type='number' label='Цена' required={true} value={work?.value} />
                                                            <InputX textarea name={`work_comment___${work?._id}`} label='Комментарий к сумме (необязательно)' required value={work?.comment} />
                                                        </div>
                                                        <div className="buttons">
                                                            <ButtonX iconUse onUse={(e) => { onClick.edit(e, work?._id) }}><IconX name="chevron-left" /> Отменить</ButtonX>
                                                            <ButtonX iconUse green confirm="Сохранить эту запись?" onUse={(e) => { onClick.save(e, work?._id) }}><IconX name="floppy" /> Изменить</ButtonX>
                                                            <ButtonX iconUse red confirm="Удалить эту запись?" onUse={(e) => { onClick.delete(e, work?._id) }}><IconX name="trash" /> Удалить</ButtonX>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="data">
                                                        <div className="text">
                                                            <div className="cost">
                                                                <div><b>Цена:</b> {work?.value?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</div>
                                                            </div>
                                                            {(work.count > 0 || work.time > 0) &&
                                                                <>
                                                                    <div className="count">
                                                                        <b>Количество:</b> {work?.time > 0 ? work?.time : work?.count} {parceTypeWorkUnit(work?.work?.type)}
                                                                    </div>
                                                                    <div className="all">
                                                                        <b>Сумма:</b>&nbsp;
                                                                        {(work.work_type === 'timeHour' && work?.time > 0) && <>{(work?.time * work?.value).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</>}
                                                                        {work.count > 0 && <>{(work?.count * work?.value).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</>}
                                                                    </div>
                                                                </>
                                                            }
                                                            {work?.comment?.length > 0 && <div className="comment" dangerouslySetInnerHTML={{ __html: Text.replaceAll(work.comment, '\n', '<br />') }}></div>}
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="number">#{isData?.length - idx}</div>
                                        </div>
                                    ))}
                                </>
                                :
                                <AlertX type='info' icon={<IconX name="info" />}>Работы этой заявки пусты</AlertX>
                            }
                        </>
                    }
                </>
            }
        </div>
    </>)
}

export default RequestsViewWorks