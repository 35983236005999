import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { setHeaderTitle } from '../../../../FuncX'
import Loading from '../../../../Components/Loading'
import Error from '../../../../Components/Error'
import IconX from '../../../../Components/IconX'
import { animationElementClick, animationElementError } from '../../../../Components/Animations'
import InputX from '../../../../Components/InputX'
import ButtonX from '../../../../Components/ButtonX'
import API from '../../../../API'

import './style.scss'
import ContentMenu from '../../../../Components/ContentMenu'

const Admin_News = ({ User }) => {

    const
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [isCreated, setCreated] = useState({ status: false, id: 'none' }),
        [title, setTitle] = useState(''),
        [description, setDescription] = useState('')

    const onClick = {

        create: async (e) => {


            if (isLoading.status) return false

            setError({ status: false })
            animationElementClick(e.currentTarget)
            setLoading({ status: true, msg: 'Создание новости...' })

            const completed = checkCompleted()

            if (completed !== 'ok') {

                animationElementError(document.querySelector(`#inputX.id_${completed}`), true)
                return setLoading({ status: false })
            }

            let response

            try { response = await API.News.Create({ title, description }) }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                setError({ status: true, title: 'создания новости', msg: response.result })
                setLoading({ status: false })
                return false
            }

            setCreated({ status: true, id: response?.result?.id || '' })
            setLoading({ status: false })
            return true
        }
    }

    const onChange = {

        title: (e) => {

            setTitle(e.target.value)
            return true
        },

        description: (e) => {

            setDescription(e.target.value.replace(/<(?!img|code>|\/code>|b>|\/b>|i>|\/i>|u>|\/u>|s>|\/s>).*?>/gm, ''))
            return true
        }
    }

    const checkCompleted = () => {

        if (!title) return 'title'
        if (!description) return 'description'

        return 'ok'
    }

    setHeaderTitle('Управление > Новости > Создание')

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>МТП | Личный кабинет | Управление | Новости | Создать</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div id="Admin_News_New">
            <ContentMenu>
                <Link to="/lk/adm/news"><IconX name="chevron-left" />Вернуться</Link>
            </ContentMenu>
            
            {isLoading?.status ?
                <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            {!isCreated.status ?
                                <div className="info">
                                    <InputX name="title" label="Заголовок" handle={onChange.title} />
                                    <InputX name="description" textareaHTML label="Описание" handle={onChange.description} />
                                    <ButtonX onUse={onClick.create} full><IconX name='plus-lg' /> Создать</ButtonX>
                                </div>
                                :
                                <div className="created">
                                    <b>Новость успешно создана!</b>
                                    <a href={`/lk/adm/news/${isCreated.id}`}><div className="btn_default">Перейти к новости - {title}</div></a>
                                </div>
                            }
                        </>
                    }
                </>
            }
        </div >
    </>)
}

export default Admin_News
