import React, { useEffect } from 'react'

import { Kludge } from '../../FuncX'
import ProgressBar from '../ProgressBar'

import './style.scss'



const Loading = ({ children, full = false, module = false, progress = -1 }) => {

    const
        isInit = { mount: false }

    const Mount = () => {

        isInit.mount = true

        if (full) Kludge.visibleContentScroll(false)
        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
        
        if (full) Kludge.visibleContentScroll(true)
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div id="Loading" className={`${full ? ' full' : ''}${module ? ' module' : ''}`}>
        {progress !== -1 ?
            <ProgressBar progress={progress} loading />
            :
            <div id="loadingContent">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        }
        <div className="text">{children}</div>
    </div>)
}

export default Loading