import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Loading from '../../Components/Loading'
import Error from '../../Components/Error'
import DialogX from '../../Components/DialogX'
import IconX from '../../Components/IconX'
import AlertX from '../../Components/AlertX'
import { animationElementClick } from '../../Components/Animations'
import { Kludge, formatName, setHeaderTitle } from '../../FuncX'
import ButtonX from '../../Components/ButtonX'
import ListX from '../../Components/ListX'
import ContentMenu from '../../Components/ContentMenu'
import API from '../../API'

import './style.scss'

const
    _def = { isInit: { mount: false, loading: false }, isTimer: { update: { _: null, active: false, time: 60000 } } },
    _ = _def

const Requests = ({ User }) => {

    const
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [loadingRequests, set___loadingRequests] = useState(false),
        [isDialog, setDialog] = useState(false),
        [items, setItems] = useState(false),
        [fiterTypes, set___filterTypes] = useState(false)

    const onClick = {

        update: async (e) => {

            if (loadingRequests) return false

            animationElementClick(e.currentTarget)
            await updateShell(true)
            return true
        },

        filter: {

            get: async (e) => {

                animationElementClick(e.currentTarget)

                setDialog({

                    loading: true,
                    id: 'change_filter',
                    title: 'Фильтр заявок',
                    content: <Loading module>Загрузка списка фильтров заявки...</Loading>
                })

                let res

                try { res = await API.Requests.GetFilters() }
                catch (error) { res = { status: false, result: error.message } }

                if (!res.status) {

                    return setDialog((prevData) => {

                        return {

                            ...prevData,
                            loading: false,
                            content: <Error title="загрузки списка заявок" msg={res.result} />
                        }
                    })
                }

                return setDialog((prevData) => {

                    return {
                        ...prevData,
                        loading: false,
                        content: <div className="list">
                            {res.result.map((item) => (
                                <ButtonX
                                    key={item._id}
                                    name={`filterSelect_${item._id}`}
                                    onUse={(e) => onClick.filter.select(e, item)}
                                    noUse={filter_getActive(item._id)}
                                    green={filter_getActive(item._id)}
                                >{item.name}</ButtonX>
                            ))}
                        </div>
                    }
                })
            },

            select: async (e, item) => {

                if (!item) return false

                setDialog({

                    loading: true,
                    id: 'change_filter',
                    title: 'Фильтр заявок',
                    content: <Loading module>Применение фильтра <b>'{item.name}'</b>...</Loading>
                })

                let new_fiterTypes = Kludge.copyObject(fiterTypes)

                if (new_fiterTypes) {

                    for (const a of new_fiterTypes) {

                        if (String(a) === String(item._id)) return false
                    }

                    new_fiterTypes.push(String(item._id))
                } else new_fiterTypes = [String(item._id)]

                if (item._id === '637046a9ec4a9ddf8cc6adfb') new_fiterTypes = []

                let res

                try { res = await API.Users.SetFilters(new_fiterTypes) }
                catch (error) { res = { status: false, result: error.message } }

                if (!res.status) {

                    setDialog((prevData) => {

                        return {

                            ...prevData,
                            loading: false,
                            content: <Error title={`применения фильтра - ${item.name}`} msg={res.result} />
                        }
                    })
                    return false
                }

                User.requestFilters = new_fiterTypes
                await updateShell()
                setDialog(false)
                return true
            }
        }
    }


    const getRequests = (shell = false) => new Promise(async (resolve) => {

        if (_.isInit.loading) return false

        _.isInit.loading = true

        if (!shell) setLoading({ status: true, msg: 'Загрузка заявок...' })

        setError({ status: false })

        let res

        try {

            res = await API.Requests.Get(null, (progressEvent) => {

                if (shell) return false

                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                if (_.isInit.loading) setLoading({ status: true, msg: 'Загрузка заявок...', progress: percentage })

                return true
            })
        } catch (error) { res = { status: false, result: error.message } }

        if (!res?.status) {

            if (!shell) {

                setError({ status: true, title: 'загрузки заявок', msg: res.result })
                setLoading({ status: false })
            }

            return resolve(false)
        }

        res.result = setFilter(res.result)
        setLoading({ status: false })
        _.isInit.loading = false
        return resolve(res)
    })

    const updateShell = async (noRetry = false) => new Promise(async (resolve) => {

        set___loadingRequests(true)

        if (isLoading.status) {

            if (!noRetry) _.isTimer.update._ = setTimeout(updateShell, _.isTimer.update.time)

            set___loadingRequests(false)
            return resolve(false)
        }

        const requests = await getRequests(true)

        if (requests.status) setItems(requests.result)
        if (!noRetry) _.isTimer.update._ = setTimeout(updateShell, _.isTimer.update.time)

        set___loadingRequests(false)
        return resolve(true)
    })

    const update = async (e = null, firstLoad = false) => {

        setError({ status: false })
        if (!firstLoad && isLoading.status) return false

        set___loadingRequests(true)

        const requests = await getRequests()

        console.log("requests |", requests)

        if (requests?.status) setItems(requests.result)

        setLoading({ status: false })
        set___loadingRequests(false)
        // _.isTimer.update._ = setTimeout(updateShell, _.isTimer.update.time)
        return true
    }

    const filter_getActive = (id) => {

        if (!fiterTypes) return false

        for (const a of fiterTypes) {

            if (String(a) === String(id)) return true
        }

        return false
    }

    const setFilter = (requests) => {

        const idUser = localStorage.getItem('MTP|Auth').split('|')
        let newRequests = Kludge.copyObject(requests || [])

        for (const a of User?.requestFilters || []) {

            if (String(a) === '637046a9ec4a9ddf8cc6adfb') return newRequests
            if (String(a) === '637051b8ec4a9ddf8cc6ae04') newRequests = newRequests.filter((request) => request.status.className !== 'closed')
            if (String(a) === '637061abec4a9ddf8cc6ae0d') newRequests = newRequests.filter((request) => request.author && request.author._id === idUser[0])
            if (String(a) === '637061c4ec4a9ddf8cc6ae0e') newRequests = newRequests.filter((request) => request.client && request.client._id === idUser[0])
            if (String(a) === '637061d3ec4a9ddf8cc6ae0f') newRequests = newRequests.filter((request) => request.responsible && request.responsible._id === idUser[0])
        }

        set___filterTypes(User?.requestFilters || [])
        return newRequests
    }

    const Mount = () => {

        _.isInit.mount = true

        setHeaderTitle(`Заявки`)

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        clearTimeout(_.isTimer.update._)
        _.isInit = _def.isInit
        _.isTimer = _def.isTimer
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { if (User) update(null, true) }, [User]) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>

        <Helmet>
            <meta charSet="utf-8" />
            <title>МТП | Личный кабинет | Заявки</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>

        {isDialog &&
            <DialogX id={isDialog.id} title={isDialog.title} full={isDialog.full ?? false} close={() => { setDialog(false) }}>
                {isDialog.content}
            </DialogX>
        }

        <div id="Requests">
            <ContentMenu>
                <Link to="/lk/req/new"><IconX name="plus-lg" /> Создать</Link>
                {!isLoading.status && <div className={loadingRequests ? 'elementLoading' : ''} onClick={onClick.update}><IconX name="arrow-clockwise" /> Обновить</div>}
                {!isLoading.status && !isError.status && <div onClick={onClick.filter.get}><IconX name={fiterTypes?.length > 0 ? 'funnel-fill' : 'funnel'} /> Фильтр</div>}
            </ContentMenu>
            {isLoading.status ?
                <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            {items?.length > 0 ?

                                <ListX
                                    name="requestsList"
                                    header={<>
                                        <div>#</div>
                                        <div>Статус</div>
                                        <div>Название</div>
                                        <div>Клиент</div>
                                        <div>Ответственный</div>
                                    </>}
                                    footer={`Отображено заявок: ${items?.length}`}
                                >
                                    {items.map((item, index) => (

                                        <Link className={`content ${item?.status?.className}`} key={index} to={`/lk/req/${item?._id}`}>
                                            <div>{item?.code}</div>
                                            <div className={item?.status?.className}><IconX name={item?.status?.icon} /> <span>{item?.status?.name}</span></div>
                                            <div>{item?.title}</div>
                                            <div>{item?.corp ? item?.corp?.name : item?.client ? formatName(item?.client, true, false) : 'Не назначен'}</div>
                                            <div>{item?.responsible ? formatName(item?.responsible, true, false) : 'Не назначен'}</div>
                                        </Link>
                                    ))}
                                </ListX>
                                :
                                <AlertX type="info" icon={<IconX name="info-lg" />}>Список заявок пуст.</AlertX>
                            }
                        </>
                    }
                </>
            }
        </div>
    </>)
}

export default Requests