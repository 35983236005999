import React from 'react'

import FeedBack from './FeedBack'
import Contacts from './Contacts'

import './style.scss'



const BlockMiddle = ({ selectService }) => (<div id="BlockMiddle">
    <FeedBack selectService={selectService} />
    {/* <Contacts /> */}
</div>)

export default BlockMiddle