import React, { useState, useEffect, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import Loading from './Components/Loading'
import { generateToken } from './firebase'
import API from './API'

import LAYOUT_DEFAULT from './Layouts/Default'
import LAYOUT_LK from './Layouts/LK'
import PAGE_OFFLINE from './Pages/OFF-Line'

import DefaultHome from './Pages/Default/Home'
import DefaultError from './Pages/Default/Error'

import LK_MAIN from './Modules/Main'

import LK_ACCESS from './Modules/Access'
import LK_ACCESS_VIEW from './Modules/Access/View'

import LK_ADM from './Modules/Admin'
import LK_ADM_NEWS from './Modules/Admin/News'
import LK_ADM_NEWS_NEW from './Modules/Admin/News/Create'
import LK_ADM_WORKS from './Modules/Admin/Works'
import LK_ADM_WORKS_NEW from './Modules/Admin/Works/Create'
import LK_ADM_WORKS_VIEW from './Modules/Admin/Works/View'
import LK_ADM_FINANCE from './Modules/Admin/Finance'
import LK_ADM_REPORTS from './Modules/Admin/Reports'

import LK_APPS from './Modules/Apps'
import LK_APPS_IOCHECK from './Modules/Apps/IO.check'
import LK_APPS_IOCHECK_VIEW from './Modules/Apps/IO.check/View'
import LK_APPS_GMFL from './Modules/Apps/GMFL'

import LK_REQ from './Modules/Requests'
import LK_REQ_CREATE from './Modules/Requests/Create'
import LK_REQ_VIEW from './Modules/Requests/View'
import LK_REQ_VIEW_HISTORY from './Modules/Requests/View/History'
import LK_REQ_VIEW_WORKS from './Modules/Requests/View/Works'

import LK_USER from './Modules/Profile'

import LK_SETTINGS from './Modules/Settings'

import LK_DIALOGS from './Modules/Dialogs'
import LK_DIALOGS_VIEW from './Modules/Dialogs/View'

import SONYA from './_1608'

import "bootstrap-icons/font/bootstrap-icons.css"
import './App.scss'

// import './firebase'
// import { onMessage, messaging } from 'firebase/messaging'

const
  _def = { isInit: { mount: false, loading: false }, isTimer: { update: { _: null, active: false, time: 60000 } } },
  _ = _def

const App = () => {

  const status = true

  const
    [isLoading, setLoading] = useState(true),
    [Auth, setAuth] = useState(false),
    [APIstatus, setAPIstatus] = useState(true)

  const update = (stealth = false) => new Promise(async (resolve) => {

    if (_.isInit.loading) return false

    _.isInit.loading = true

    if (!stealth) setLoading(true)
    if (!localStorage.getItem('MTP|Auth')) {

      setLoading(false)
      setAuth(null)
      return resolve(false)
    }

    let res

    try {

      res = await API.Auth.Check(await generateToken())
      setAPIstatus(true)
    } catch (error) {

      setAPIstatus(false)
      setLoading(false)
    }

    if (res.status) setAuth(res.result)
    else setAuth(null)

    // _.isTimer.update._ = setTimeout((_) => update(true), _.isTimer.update.time)
    _.isInit.loading = false
    return resolve(setLoading(false))
  })

  const Mount = () => {

    _.isInit.mount = true
    update()
    // onMessage(messaging, (playload) => {

    //   console.log('test | onMessage', playload)
    // })

    if (_.isInit.mount) return UnMount
  }

  const UnMount = () => {

    // clearTimeout(_.isTimer.update._)
    _.isInit.mount = false
  }

  useLayoutEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (<>
    {/* <YMInitializer accounts={[97144784]} options={{ webvisor: true, clickmap: true, accurateTrackBounce: true, trackLinks: true, disabled: false }} version="2" /> */}
    {isLoading && <Loading full>Проверка авторизации...</Loading>}

    {/* <Loading full>Проверка авторизации...</Loading> */}
    <Router>
      <Routes>
        <Route path='/_1608' element={<SONYA />} />

        {status ?
          <Route exact path="/" element={<LAYOUT_DEFAULT APIstatus={APIstatus} User={Auth} />}>
            <Route index element={<DefaultHome />} />
            <Route path="*" element={<DefaultError />} />
          </Route>
          :
          <Route exact path="*" element={<LAYOUT_DEFAULT APIstatus={APIstatus} User={Auth} />}>
            <Route index element={<PAGE_OFFLINE />} />
          </Route>
        }

        <Route exact path="/private" element={<LAYOUT_LK APIstatus={APIstatus} User={Auth} />}>
          <Route path='/private/requests/:reqId' element={<LK_REQ_VIEW User={Auth} />} />
        </Route>

        <Route exact path="/lk" element={<LAYOUT_LK APIstatus={APIstatus} User={Auth} />}>

          <Route index element={<LK_MAIN />} />

          <Route path="/lk/access" element={<LK_ACCESS User={Auth} />} />
          <Route path='/lk/access/:accessId' element={<LK_ACCESS_VIEW User={Auth} />} />

          <Route path="/lk/adm" element={<LK_ADM User={Auth} />} />
          <Route path="/lk/adm/main" element={<LK_ADM User={Auth} />} />
          <Route path="/lk/adm/news" element={<LK_ADM_NEWS User={Auth} />} />
          <Route path="/lk/adm/news/new" element={<LK_ADM_NEWS_NEW User={Auth} />} />
          <Route path="/lk/adm/works" element={<LK_ADM_WORKS User={Auth} />} />
          <Route path="/lk/adm/works/new" element={<LK_ADM_WORKS_NEW User={Auth} />} />
          <Route path="/lk/adm/works/:id" element={<LK_ADM_WORKS_VIEW User={Auth} />} />
          <Route path="/lk/adm/permissions" element={<LK_ADM User={Auth} />} />
          <Route path="/lk/adm/users" element={<LK_ADM User={Auth} />} />
          <Route path="/lk/adm/finance" element={<LK_ADM_FINANCE User={Auth} />} />
          <Route path="/lk/adm/reports" element={<LK_ADM_REPORTS User={Auth} />} />

          <Route path="/lk/apps" element={<LK_APPS User={Auth} />} />
          <Route path="/lk/apps/IO.check" element={<LK_APPS_IOCHECK User={Auth} />} />
          <Route path="/lk/apps/IO.check/:deviceId" element={<LK_APPS_IOCHECK_VIEW User={Auth} />} />
          <Route path="/lk/apps/GMFL" element={<LK_APPS_GMFL User={Auth} />} />

          <Route path='/lk/profile/:userId' element={<LK_USER User={Auth} />} />
          <Route path='/lk/user/:userId' element={<LK_USER User={Auth} />} />

          <Route path="/lk/req" element={<LK_REQ User={Auth} />} />
          <Route path="/lk/req/new" element={<LK_REQ_CREATE User={Auth} />} />
          <Route path='/lk/req/:reqId' element={<LK_REQ_VIEW User={Auth} />} />
          <Route path='/lk/req/:reqId/history' element={<LK_REQ_VIEW_HISTORY User={Auth} />} />
          <Route path='/lk/req/:reqId/works' element={<LK_REQ_VIEW_WORKS User={Auth} />} />

          <Route path="/lk/settings" element={<LK_SETTINGS User={Auth} />} />

          <Route path="/lk/dialogs" element={<LK_DIALOGS User={Auth} />} />
          <Route path="/lk/dialogs/:dialogId" element={<LK_DIALOGS_VIEW User={Auth} />} />

          {/* <Route path="*" element={<DefaultError />} /> */}
        </Route>
      </Routes>
    </Router>
  </>)
}

export default App