import React, { useState, useEffect, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import ContentMenu from '../../../Components/ContentMenu'
import Loading from '../../../Components/Loading'
import Error from '../../../Components/Error'
import IconX from '../../../Components/IconX'
import { DateTime, Kludge, Text, formatName, parceDeviceTypeClient, parceMessageFormatedSync, setHeaderTitle } from '../../../FuncX'
import InputX from '../../../Components/InputX'
import ButtonX from '../../../Components/ButtonX'
import { animationElementClick, animationElementLoadingStart, animationElementLoadingStop } from '../../../Components/Animations'
import API from '../../../API'

import './style.scss'

const DialogsView = ({ User }) => {

    const
        _def = { isInit: { mount: false, loading: false }, isScroll: { current: 0 }, isTimer: { update: { _: null, active: false, time: 3000 } } },
        _ = _def

    const
        { dialogId } = useParams(),
        bottomRef = useRef(),
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [isSending, setSending] = useState({ status: false, msg: '', progress: 0 }),
        [isData, setData] = useState([]),
        [isScrollPosition, setScrollPosition] = useState(0),
        [isDialogSave, setDialogSave] = useState(JSON.parse(localStorage?.getItem('MTP|Dialogs') || '{ "messageSupport": {} }'))

    const onClick = {

        read: async (e, messageId, read) => {

            if (read.status) return false

            animationElementClick(e?.currentTarget)
            animationElementLoadingStart(e?.currentTarget)

            let res

            try { res = await API.Dialogs.SetReadMesasge(messageId) }
            catch (error) { res = { status: false, result: error.message } }

            if (!res.status) {

                setError({ status: true, title: 'установки статуса прочтения', msg: res.result })
                return false
            }

            await update(true, false)
            animationElementLoadingStop(e?.currentTarget)
            return true
        },

        send: async () => {

            const input = document?.querySelector('#messageSupport')

            if (!input || !input?.value) return false

            setSending({ status: true, msg: 'Отправка сообщения...' })

            let response

            try { response = await API.Dialogs.SendMessageText(dialogId, 'whatsapp', input?.value) }
            catch (error) { response = { status: false, result: error.message } }

            if (!response?.status) {

                setError({ status: true, title: 'отправки сообщения', msg: response?.result })
                return false
            }

            await update(true, false)
            setDialogSave((old) => {

                const messageSupport = {

                    ...old?.messageSupport,
                    [dialogId]: null
                }

                localStorage.setItem('MTP|Dialogs', JSON.stringify({

                    messageSupport
                }))
                return { ...old, messageSupport }
            })
            setSending({ status: false })
            return true
        },

        close: async (e) => {

            animationElementClick(e?.currentTarget)
            return true
        },

        scrollToDown: (e) => {

            bottomRef?.current?.scrollIntoView({ behavior: 'smooth' })
            return true
        }
    }

    const onChange = {

        messageSupport: (e) => {

            const value = e?.currentTarget?.value

            setDialogSave((old) => {

                const messageSupport = {

                    ...old?.messageSupport,
                    [dialogId]: value?.length > 0 ? Text.replaceAll(value, `Здравствуйте, ${formatName(isData?.user, true, false, false)}!`, '').replace(/\s+/g, ' ').trim() : ''
                }

                localStorage.setItem('MTP|Dialogs', JSON.stringify({

                    messageSupport
                }))
                return { ...old, messageSupport }
            })
        }
    }

    const update = (stealth = true, retry = true) => new Promise(async (resolve) => {

        if (!_.isInit.mount) return resolve(false)
        if (_.isInit.loading) return resolve(false)
        if (isLoading.status) return resolve(false)
        if (!stealth) {

            setError({ status: false })
            setLoading({ status: true, msg: 'Загрузка диалога...' })
        }

        _.isInit.loading = true

        let res

        try { res = await API.Dialogs.GetOne(dialogId) }
        catch (error) { res = { status: false, result: error.message } }

        if (!res.status) {

            if (!stealth) {

                setError({ status: true, title: 'загрузки диалога', msg: res.result })
                setLoading({ status: false })
            } else if (retry) _.isTimer.update._ = setTimeout(update, _.isTimer.update.time)

            return resolve(false)
        }

        if (retry) _.isTimer.update._ = setTimeout(update, _.isTimer.update.time)
        if (Kludge.compareX(isData, res.result)) return resolve(false)

        res.result.messages = parceMessageFormatedSync(res.result?.messages || [])
        setData(res.result)

        if (!stealth) {

            setLoading({ status: false })
            setTimeout(() => {

                bottomRef?.current?.scrollIntoView()
                document.querySelector('#DialogsView>.messageView').addEventListener('scroll', handleScroll)
            }, 50)
            setHeaderTitle(`Диалоги > ${formatName(res.result?.user, false, false)}`)
        }
        _.isInit.loading = false
        return resolve(true)
    })

    const getUnReadCount = (messages) => {

        let count = 0

        for (const message of messages) {

            if (!message?.read) count++
        }

        return count
    }

    const handleScroll = (e) => {

        const scroll = (e.target.scrollHeight - e.target.offsetHeight) - e.target.scrollTop
        setScrollPosition(scroll)
        _.isScroll.current = scroll
    }

    const Mount = () => {

        _.isInit.mount = true
        setHeaderTitle('Загрузка...')
        update(false)

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        clearTimeout(_.isTimer.update._)

        const messageView = document.querySelector('#DialogsView>.messageView')

        if (messageView) messageView.removeEventListener("scroll", handleScroll)

        _.isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { if (_.isInit.mount && (_.isScroll.current < 100)) bottomRef?.current?.scrollIntoView({ behavior: 'smooth' }) }, [isData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>МТП | Личный кабинет | Диалоги | {isData?.user ? `${formatName(isData?.user, false, false)}` : 'Загрузка...'}</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div id="DialogsView">
            <ContentMenu>
                <Link to="/lk/dialogs"><IconX name="chevron-left" />Диалоги</Link>
                {!isLoading?.status && <div onClick={onClick.close}><IconX name="x-circle" /></div>}
                {!isLoading?.status && isData?.unReadCount > 0 && <div>Непрочитанных: {isData?.unReadCount}</div>}
            </ContentMenu>

            {isScrollPosition >= 100 && <div className="alertNewMessage" onClick={onClick.scrollToDown}>{isData?.unReadCount > 0 && <div><IconX name="envelope-plus" /> {isData?.unReadCount}</div>}<IconX name="arrow-bar-down" /></div>}
            {isLoading?.status ? <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading> : isError.status ? <Error title={isError.title} msg={isError.msg} /> :
                <>
                    <div className="messageView">
                        {isData?.messages?.map(({ _id, dateTime_create, from, system, read, send, social, device, type, content }, idx) => (
                            <>
                                {!system ?
                                    <div key={idx} id={`msg_idx_${idx}`} className={`message${from?._id === isData?.user?._id ? ' user' : ' support'}${from?._id === User?._id ? ' me' : ''}`}>
                                        <div className="info">
                                            <div className="avatar"></div>
                                            <div className="user">{parceDeviceTypeClient(device)}<IconX name={social} /> {formatName(from, false, true, false)}</div>
                                        </div>
                                        <div className="content">{content?.text}</div>
                                        <div className="dateTime">
                                            {DateTime.fix(new Date(dateTime_create)).text}
                                            {from?._id === isData?.user?._id ?
                                                <div className={`read_${read?.status}`} onClick={(e) => onClick.read(e, _id, read)}><IconX name="check-circle" /></div>
                                                :
                                                <div className={`send_${send}`}><IconX name="send" /></div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div key={idx} id={`msg_idx_${idx}`} className="message system">
                                        <div className="info">
                                            <div className="avatar"></div>
                                            <div className="user"><IconX name={social} /> Система</div>
                                        </div>
                                        <div className="content">{content?.text}</div>
                                        <div className="dateTime">{DateTime.fix(new Date(dateTime_create)).text}</div>
                                    </div>
                                }
                            </>
                        ))}
                        <p ref={bottomRef}></p>
                    </div>
                    <div className="messageCreate">
                        {isSending?.status ?
                            <Loading module>{isSending?.msg}</Loading>
                            :
                            <>
                                <InputX textarea name="messageSupport" label="Сообщение" value={isData?.open ? `${isDialogSave?.messageSupport?.[dialogId] || ''}` : `Здравствуйте, ${formatName(isData?.user, true, false, false)}! ${isDialogSave?.messageSupport?.[dialogId] || ''}`} handle={onChange.messageSupport} />
                                <ButtonX name="messageSupportSend" onUse={onClick.send}><IconX name='send' /></ButtonX>
                            </>
                        }
                    </div>
                </>
            }
        </div>
    </>)
}

export default DialogsView