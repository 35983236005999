import "./Animations.scss"

const animationElementClick = (e) => {

    if (e === undefined || e === null) return false

    setTimeout(() => {

        e.classList.remove('elementClick')
        e.classList.remove('elementShow')
        e.classList.remove('elementHide')

        for (const className of e.classList) {

            if (className.indexOf('elementError') !== -1) e.classList.remove(className)
        }

        e.classList.add('elementClick')
        setTimeout(() => { e.classList.remove('elementClick') }, 500)
    })
    return true
}

const animationElementShow = (e) => {

    if (e === undefined || e === null) return false

    e.classList.add('elementShow')
    return true
}

const animationElementHide = (e) => {

    if (e === undefined || e === null) return false

    e.classList.remove('elementShow')
    e.classList.add('elementHide')
    setTimeout(() => { e.classList.remove('elementHide') }, 300)
    return true
}

const animationElementLoadingStart = (e) => {

    if (e === undefined || e === null) return false

    setTimeout(() => { e.classList.add('elementLoading') })
    return true
}

const animationElementLoadingStop = (e) => {

    if (e === undefined || e === null) return false

    setTimeout(() => { e.classList.remove('elementLoading') })
    return true
}

const animationElementError = (e, focus = false, focusCurrent = false) => {

    if (e === undefined || e === null) return false
    if (focus) e?.querySelector('input')?.focus() || e?.querySelector('textarea')?.focus()
    if (focus && focusCurrent) e?.focus()

    let find = false

    e.classList.forEach(a => {

        if (a.indexOf('elementError') !== -1) {

            e.classList.remove(a)
            setTimeout(() => { e.classList.add(a) })
            find = true
        }
    })

    if (!find) e.classList.add('elementError')

    return true
}

export {

    animationElementClick,
    animationElementShow,
    animationElementHide,
    animationElementLoadingStart,
    animationElementLoadingStop,
    animationElementError
}