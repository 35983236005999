import React, { useState, useEffect } from 'react'

import API from '../../../../API'
import IconX from '../../../../Components/IconX'
import Loading from '../../../../Components/Loading'
import Error from '../../../../Components/Error'
import ButtonX from '../../../../Components/ButtonX'
import InputX from '../../../../Components/InputX'
import { animationElementClick, animationElementError } from '../../../../Components/Animations'
import { DateTime, Kludge } from '../../../../FuncX'
import DialogX from '../../../../Components/DialogX'

import './style.scss'

const RequestsViewTodo = ({ User, reqId, isDataView, isEditView }) => {

    const
        isInit = { mount: false }

    const
        [isDialog, setDialog] = useState(false),
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [isData, setData] = useState([]),
        [isEdit, setEdit] = useState(false)

    const onClick = {

        todoListAdd: async (e) => {

            const dateTime = DateTime.fix()

            animationElementClick(e.currentTarget)
            setDialog({

                id: 'todo_list_add',
                title: 'Создание нового списка задач',
                content: <>
                    <InputX name="listName" label="Название списка" value={`Задачи (${dateTime.d}.${dateTime.m}.${dateTime.y})`} autoFocus />
                    <div className='btn_default' onClick={async (e) => {

                        const listName = document.querySelector('.id_listName>input')

                        if (listName.value.length < 3) return animationElementError(listName)

                        setDialog({

                            id: 'todo_list_add',
                            title: 'Создание нового списка задач',
                            content: <Loading module>Создание списка - {listName.value}</Loading>
                        })

                        let response

                        try { response = await API.Requests.TodoListAdd(reqId, listName.value) }
                        catch (error) {

                            setDialog({

                                id: 'todo_list_add',
                                title: 'Создание нового списка задач',
                                content: <Error title="создание списка" msg={error.message} />
                            })
                            return false
                        }

                        if (!response.status) {

                            setDialog({

                                id: 'todo_list_add',
                                title: 'Создание нового списка задач',
                                content: <Error title="создание списка" msg={response.result} />
                            })
                            return false
                        }

                        await getData()
                        return setDialog(false)
                    }}><IconX name="plus-lg" /> Создать список</div>
                </>
            })
            return true
        },

        todoListItemAdd: async (e, id) => {

            if (!id) return false

            animationElementClick(e.currentTarget)
            setDialog({

                id: 'todo_list_item_add',
                title: 'Создание новой задачи в списке',
                content: <>
                    <InputX textarea name="listItemName" label="Название задачи" autoFocus />
                    <div className='btn_default' onClick={async (e) => {

                        const listItemName = document.querySelector('.id_listItemName>textarea')

                        if (listItemName.value.length < 3) return animationElementError(listItemName)

                        setDialog((prevData) => {

                            return {

                                ...prevData,
                                content: <Loading module>Создание задачи - {listItemName.value}</Loading>
                            }
                        })

                        let res

                        try { res = await API.Requests.TodoListItemAdd(id, listItemName.value) }
                        catch (error) { res = { status: false, result: error.message } }

                        if (!res.status) {

                            return setDialog((prevData) => {

                                return {

                                    ...prevData,
                                    content: <Error title="создание задачи" msg={res.result} />
                                }
                            })
                        }

                        await getData()
                        return setDialog(false)
                    }}><IconX name="plus-lg" /> Создать задачу</div>
                </>
            })
            return true
        },

        todoListNameEdit: async (e, id) => {

            const currentName = document?.querySelector(`#inputX.id_todoListName_${id}>input`)?.value || null

            if (!id) return false
            if (!currentName) return false

            let response

            setLoading({ status: true, msg: 'Сохранение названия TODO листа...' })

            try { response = await API.Requests.TodoListName(id, currentName) }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                setError({ status: true, title: 'сохранения названия TODO листа', msg: response?.result })
                setLoading({ status: false })
                return false
            }

            return await getData()
        },

        todoListItemNameEdit: async (e, id, idx) => {

            const currentName = document?.querySelector(`#inputX.id_todoListItemName_${id}_${idx}>textarea`)?.value || null

            if (!id) return false
            if (!currentName) return false

            let response

            setLoading({ status: true, msg: 'Сохранение названия в элементе TODO листа...' })

            try { response = await API.Requests.TodoListItemName(id, idx, currentName) }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                setError({ status: true, title: 'сохранения названия в элементе TODO листа', msg: response?.result })
                setLoading({ status: false })
                return false
            }

            return await getData()
        },


        todoListDelete: async (e, id) => {

            if (!id) return false

            animationElementClick(e.currentTarget)
            setLoading({ status: true, msg: 'Удаление TODO листа...' })

            let response

            try { response = await API.Requests.TodoRemove(id, -1) }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                setError({ status: true, title: 'удаления TODO листа', msg: response?.result })
                setLoading({ status: false })
                return false
            }

            return await getData()
        },

        todoListItemDelete: async (e, id, idx) => {

            let response

            if (!id) return false

            animationElementClick(e.currentTarget)
            setLoading({ status: true, msg: 'Удаление элемента из TODO листа...' })

            try { response = await API.Requests.TodoRemove(id, idx) }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                setError({ status: true, title: 'удаления элемента из TODO листа', msg: response?.result })
                setLoading({ status: false })
                return false
            }

            return await getData()
        }
    }

    const onChange = {

        todoListTitle: (e) => {

            const idTodo = e.target.dataset.id
            const value = e.target.value

            if (!idTodo) return false

            const todoList = getTodoList(idTodo)

            if (!todoList) return false
            if (todoList.name === value) {

                setEdit((prevData) => {

                    if (!prevData.todoListItem) {

                        return { active: false }
                    }

                    if (prevData.todoList) {

                        prevData.todoList = Kludge.deleteX(prevData?.todoList, [idTodo])

                        if (Object.keys(prevData?.todoList)?.length < 1) prevData = Kludge.deleteX(prevData, ['todoList'])
                    }

                    return { ...prevData, active: true }
                })
                return false
            }

            setEdit((prevData) => {

                return {

                    ...prevData,
                    active: true,
                    todoList: {

                        ...prevData?.todoList,
                        [idTodo]: value
                    }
                }
            })
            return true
        },

        todoListItemTitle: (e) => {

            const idTodo = e.target.dataset.id
            const idxTodo = e.target.dataset.idx
            const value = e.target.value

            if (!idTodo) return false
            if (!idxTodo) return false

            const todoList = getTodoList(idTodo)

            if (!todoList) return false
            if (!todoList?.list[idxTodo]?.name) return false
            if (todoList?.list[idxTodo].name === value) {

                setEdit((prevData) => {

                    if (!prevData.todoList && !prevData.todoListItem) {

                        return { active: false }
                    }

                    prevData.todoListItem[idTodo] = Kludge.deleteX(prevData?.todoListItem[idTodo], [idxTodo])

                    if (Object.keys(prevData?.todoListItem[idTodo])?.length < 1) prevData.todoListItem = Kludge.deleteX(prevData?.todoListItem, [idTodo])
                    if (Object.keys(prevData?.todoListItem)?.length < 1) prevData = Kludge.deleteX(prevData, ['todoListItem'])

                    return { ...prevData, active: true }
                })
                return false
            }

            setEdit((prevData) => {

                return {

                    ...prevData,
                    active: true,
                    todoListItem: {

                        ...prevData?.todoListItem,
                        [idTodo]: {

                            ...prevData?.todoListItem?.[idTodo],
                            [idxTodo]: value
                        }
                    }
                }
            })
            return true
        },

        todoListItemStatus: async (e) => {

            const idTodo = e.target.dataset.id
            const idxTodo = e.target.dataset.idx
            const value = e.target.checked
            let response

            if (!idTodo) return false
            if (!idxTodo) return false

            animationElementClick(e.currentTarget)

            try { response = await API.Requests.TodoListItemStatus(idTodo, idxTodo, value) }
            catch (error) {

                // return false
            }

            if (!response.status) {

                // return false
            }

            await getData()
            return true
        }
    }

    const checkPermissionEdit = (typeList = [], edit = false, newRequest = false, closeRequest = false) => {

        if (!User) return false
        if (!isDataView) return false

        for (const type of typeList) {

            switch (type) {

                case 'admin': {

                    if (closeRequest && isDataView.status.className === 'closed') return false
                    if (User.status === 'gAdmin') return true
                    if (User.status === 'moder') return true

                    break
                }

                case 'author': {

                    if (closeRequest && isDataView.status.className === 'closed') return false
                    if (User.status === 'gAdmin') return true
                    if (User.status === 'moder') return true
                    if (edit) {

                        if (User._id === isDataView.author._id && (newRequest && isDataView.status.className === 'new')) return true
                    }
                    else if (User._id === isDataView.author._id) return true

                    break
                }

                case 'client': {

                    if (closeRequest && isDataView.status.className === 'closed') return false
                    if (User.status === 'gAdmin') return true
                    if (User.status === 'moder') return true
                    if (edit) {

                        if (User._id === isDataView.client._id && (newRequest && isDataView.status.className === 'new')) return true

                    } else if (User._id === isDataView.client._id) return true

                    break
                }

                default: { }
            }
        }

        return false
    }

    const getTodoListItemStatusComplete = (idTodo = null) => {

        let complete = 0

        if (idTodo === null) return complete
        if (!isData[idTodo]?.list) return complete

        for (const todoItem of isData[idTodo]?.list) {

            if (todoItem.status) complete++
        }

        return complete
    }

    const getData = () => new Promise(async (resolve) => {

        let response

        setEdit(false)
        setData([])
        setLoading({ status: true, msg: 'Загрузка списков TODO...' })
        setError({ status: false })

        try {

            response = await API.Requests.TodoGet(
                reqId,
                (progressEvent) => setLoading({

                    status: true,
                    msg: 'Загрузка списков TODO...',
                    progress: Math.round((progressEvent.loaded * 100) / progressEvent.total)
                })
            )
        }
        catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            setError({ status: true, title: 'загрузки списков TODO', msg: response.result })
            return resolve(false)
        }

        setData(response.result)
        setLoading({ status: false })
        return resolve(response.result)
    })

    const getTodoList = (id) => {

        for (const todo of isData) {

            if (todo?._id === id) return todo
        }

        return false
    }

    const Mount = () => {

        isInit.mount = true
        getData()

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>

        {isDialog &&
            <DialogX id={isDialog.id} title={isDialog.title} full={isDialog.full ?? false} close={() => { setDialog(false) }}>
                {isDialog.content}
            </DialogX>
        }

        <div id='RequestsViewTodo'>
            {isLoading.status ?
                <Loading module progress={isLoading.progress} >{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            {isData.length > 0 && isData?.map((todo, todo_idx) => (
                                <div key={todo?._id} className="todoList" data-id={todo?._id}>
                                    {isEditView.active && checkPermissionEdit(['author', 'client'], true, true, true) ?
                                        <div className="editTitle">
                                            <ButtonX name="todoListDelete" confirm="Удалить список?" onUse={(e) => onClick.todoListDelete(e, todo?._id)} red><IconX name='trash' /></ButtonX>
                                            <InputX
                                                data-id={todo?._id}
                                                name={`todoListName_${todo?._id}`}
                                                label={`Название списка #${Number(todo_idx) + 1}`}
                                                value={todo.name}
                                                handle={onChange.todoListTitle}
                                                required={true}
                                            />
                                            {isEdit?.active && isEdit?.todoList?.[todo?._id] && <ButtonX name="todoListDelete" onUse={(e) => onClick.todoListNameEdit(e, todo?._id)}><IconX name='floppy' /></ButtonX>}
                                        </div>
                                        :
                                        <div className="title">{todo.name} ({getTodoListItemStatusComplete(todo_idx)} из {todo?.list?.length})</div>
                                    }
                                    <div className="list">
                                        {todo?.list?.map((todoListItem, todoListItem_idx) => (
                                            <div key={`${todo?._id}_${todoListItem_idx}`} className='item'>
                                                {isEditView.active && checkPermissionEdit(['author', 'client'], true, true, true) ?
                                                    <div className="editTitle">
                                                        <ButtonX name="todoListItemDelete" confirm="Удалить задачу?" onUse={(e) => onClick.todoListItemDelete(e, todo?._id, todoListItem_idx)} red><IconX name='trash' /></ButtonX>
                                                        <InputX
                                                            textarea
                                                            data-id={todo?._id}
                                                            data-idx={todoListItem_idx}
                                                            name={`todoListItemName_${todo?._id}_${todoListItem_idx}`}
                                                            label={`Название задачи #${Number(todoListItem_idx) + 1}`}
                                                            value={todoListItem.name}
                                                            handle={onChange.todoListItemTitle}
                                                            required={true}
                                                        />
                                                        {isEdit?.active && isEdit?.todoListItem?.[todo?._id]?.[todoListItem_idx] &&
                                                            <ButtonX name="todoListDelete" onUse={(e) => onClick.todoListItemNameEdit(e, todo?._id, todoListItem_idx)}><IconX name='floppy' /></ButtonX>
                                                        }
                                                    </div>
                                                    :
                                                    <InputX
                                                        checkbox
                                                        data-id={todo?._id}
                                                        data-idx={todoListItem_idx}
                                                        name={`todoListItemStatus_${todo?._id}_${todoListItem_idx}`}
                                                        label={todoListItem.name}
                                                        value={todoListItem.status}
                                                        handle={User.status === 'client' ? (e) => e.target.checked = todoListItem.status : onChange.todoListItemStatus}
                                                    />
                                                }
                                            </div>
                                        ))}
                                    </div>
                                    {isEditView.active && checkPermissionEdit(['author', 'client'], true, true, true) &&
                                        <ButtonX iconUse name="todoListItemAdd" onUse={(e) => onClick.todoListItemAdd(e, todo?._id)}><IconX name='plus-lg' />Добавить задачу</ButtonX>
                                    }
                                </div>
                            ))}
                            {isEditView.active && checkPermissionEdit(['author', 'client'], true, true, true) &&
                                <ButtonX iconUse full name="todoListAdd2" onUse={onClick.todoListAdd}><IconX name='plus-lg' />Добавить список</ButtonX>
                            }
                        </>
                    }
                </>
            }
        </div>
    </>)
}

export default RequestsViewTodo