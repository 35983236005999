import React, { useRef, useState, useEffect } from 'react'

import { ReactComponent as Logotype } from '../../../Images/Logo/v4.svg'
import Auth from '../../../Components/Auth'
import LinkX from '../../../Components/LinkX'
import { animationElementClick, animationElementHide, animationElementShow } from '../../../Components/Animations'

import './style.scss'

const Header = ({ User }) => {

    const
        btnMenu = useRef(null),
        [isUser, setUser] = useState(User),
        [Menu, setMenu] = useState(false),
        [isNavActive, setNavActive] = useState(false)

    const checkPositionScroll = (position) => {

        if (position > 0) {

            document.querySelector('nav').classList.add('active')
            setNavActive(true)
            return
        }

        if (!Menu) {

            document.querySelector('nav').classList.remove('active')
            setNavActive(false)
        }
    }

    const menuOpen = (_) => {

        animationElementShow(document.querySelector('nav>.navLinks'))
        document.querySelector('nav>.navLinks').classList.add('active')
        document.querySelector('body').classList.add('disableScroll')

        const links = document.querySelectorAll('nav>.navLinks>ul>li>a')
        links.forEach((e) => {

            e.addEventListener("click", (e) => {

                if (Menu) onClick.btnMenu()
            })
        })

        setMenu(true)
        checkPositionScroll(1)
    }

    const menuClose = (_) => {

        if (!Menu) return false

        animationElementHide(document.querySelector('nav>.navLinks'))
        setTimeout(() => {

            document.querySelector('nav>.navLinks').classList.remove('active')
            document.querySelector('body').classList.remove('disableScroll')
        }, 400)

        const links = document.querySelectorAll('nav>.navLinks>ul>li>a')
        links.forEach((e) => { e.removeEventListener("click", null) })

        setMenu(false)
        return true
    }

    const onClick = {

        btnMenu: (enabled = true) => {

            animationElementClick(btnMenu.current)

            if (window.innerWidth >= 720) return false
            if (!enabled || Menu) return menuClose()

            return menuOpen()
        }
    }

    const eventListener = {

        scroll: (e) => {

            checkPositionScroll(e.srcElement.scrollTop)
        },

        resize: (e) => {

            const body = document.querySelector('body')

            checkPositionScroll(body.scrollTop)

            if (body.innerWidth >= 720 && Menu) menuClose()
        }
    }

    useEffect((_) => { setUser(User) }, [User])  // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {

        document.querySelector('body').addEventListener('scroll', eventListener.scroll)
        window.addEventListener('resize', eventListener.resize)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect((_) => {

        checkPositionScroll(window.scrollY)
        window.addEventListener('resize', eventListener.resize)
        return () => { window.removeEventListener('resize', eventListener.resize) }
    }, [Menu]) // eslint-disable-line react-hooks/exhaustive-deps

    return (<nav className="default">
        {/* <div ref={btnMenu} className={`btnMenu${Menu ? ' active' : ''}`} onClick={onClick.btnMenu}>
            {Menu ? <IconX name="x-lg" /> : <IconX name="list" />}
        </div> */}

        <LinkX className="logoLink" to="/">
            <Logotype /><div className={isNavActive ? 'active' : ''}>МагТехПро</div>
        </LinkX>

        {/* <div className="navLinks">
            <ul>
                <li>
                    <LinkX to="/" onClick={onClick.btnMenu}><IconX name="house" /> Главная</LinkX>
                </li>
                <li>
                    <LinkX to="/apps" onClick={onClick.btnMenu}><IconX name="clipboard-check" /> Готовые решения</LinkX>
                </li>
                <li>
                    <LinkX to="/requisites" onClick={onClick.btnMenu}><IconX name="people" /> Реквизиты</LinkX>
                </li>
            </ul>
        </div> */}

        <Auth User={isUser} />
    </nav >)
}

export default Header