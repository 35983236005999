import { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import Loading from '../../../../Components/Loading'
import Error from '../../../../Components/Error'
import API from '../../../../API'

import './style.scss'
import { setHeaderTitle } from '../../../../FuncX'

const AdminReportsIncome = ({ User }) => {

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend,
        {
            id: 'lineVertical',
            afterDraw: function (chart, easing) {

                const activeEle = chart.getActiveElements()

                if (activeEle.length <= 0) return

                const { ctx, scales } = chart
                const { x } = activeEle[0].element
                const topY = scales.y.top
                const bottomY = scales.y.bottom

                ctx.save()
                ctx.setLineDash([5, 5])
                ctx.beginPath()
                ctx.moveTo(x, topY)
                ctx.lineTo(x, bottomY)
                ctx.lineWidth = 3
                ctx.strokeStyle = 'rgb(255, 100, 0)'
                ctx.stroke()
                ctx.restore()
            }
        }
    )

    const
        isInit = { mount: false }

    const
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: true, msg: 'Загрузка отчёта...', progress: -1 }),
        [isData, setData] = useState(false)

    const
        options = {

            interaction: {

                // intersect: false,
                // includeInvisible: true,
                // mode: 'nearest',
                // axis: 'x',

                mode: 'index',
                intersect: false
            },
            tension: .2,
            fill: 'lightGreen',
            responsive: true,
            plugins: {

                legend: {

                    position: 'top'
                },
                title: {

                    display: true,
                    text: `Всего: ${isData?.totalCost?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })} (${isData?.totalCount} шт.)`
                }
            }
        },
        labels = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        data = {

            labels,
            datasets: Object.keys(isData).filter(key => (key !== 'totalCost' && key !== 'totalCount')).map((year, idx) => {

                const color = String(new Date().getFullYear()) === year ? 'rgb(0, 255, 0' : 'rgb(255, 0, 0'

                return {
                    fill: true,
                    label: `${year} (${isData?.[year]?._?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })})`,
                    data: labels.map((name, idx) => {

                        if (isData?.[year]?.[`${idx + 1}`]) return isData?.[year]?.[`${idx + 1}`]._
                        else return 0
                    }),
                    borderColor: color + ')',
                    backgroundColor: color + ', .1)',
                }
            })
        }

    const parce = (inData) => {

        // const datasets = []

        // for (const year of Object.keys(inData)) {

        //     if (year === '_') continue

        //     data.datasets.push({
        //         fill: true,
        //         label: `${year} - (${inData?.[year]?._?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })})`,
        //         data: labels.map((name, idx) => {

        //             if (inData?.[year]?.[`${idx + 1}`]) return inData?.[year]?.[`${idx + 1}`]._
        //             else return 0
        //         }),
        //         borderColor: 'rgb(255, 0, 0)',
        //         backgroundColor: 'rgba(255, 0, 0, .1)',
        //     })

        // }
        console.log('data', data)
    }

    const update = async () => {

        setError({ status: false })
        setLoading({ status: true, msg: 'Загрузка отчёта...' })

        let res

        try {

            res = await API.Reports.Income.Get(null, (progressEvent) => {

                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                return setLoading({ status: true, msg: 'Загрузка отчёта...', progress: percentage })
            })
        } catch (error) { res = { status: false, result: error.message } }

        if (!res.status) {

            setError({ status: true, title: 'загрузки отчёта', msg: res.result })
            setLoading({ status: false })
            return false
        }

        setData(res.result)
        setLoading({ status: false })
        return true
    }

    const Mount = () => {

        isInit.mount = true
        update()
        setHeaderTitle('Управление > Отчёты > Поступления')

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div id="Admin_Reports_Income">
        {isLoading?.status ?
            <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading>
            :
            <>
                {isError.status ?
                    <Error title={isError.title} msg={isError.msg} />
                    :
                    <>
                        <Line options={options} data={data} updateMode='resize' />
                    </>
                }
            </>
        }
    </div>)
}

export default AdminReportsIncome