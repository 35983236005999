import React, { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { setHeaderTitle } from '../../../FuncX'
import { animationElementClick } from '../../../Components/Animations'
import Loading from '../../../Components/Loading'
import Error from '../../../Components/Error'
import IconX from '../../../Components/IconX'
import ContentMenu from '../../../Components/ContentMenu'
import TabsX from '../../../Components/TabsX'
import DialogX from '../../../Components/DialogX'
import ButtonX from '../../../Components/ButtonX'
import InputX from '../../../Components/InputX'
import AdminFinanceIncome from './income'
import AdminFinanceExpenses from './expenses'

import './index.scss'

const
    _def = {
        isInit: { mount: false, loading: false },
        isScroll: { current: 0 },
        isTimer: { update: { _: null, active: false, time: 3000 } }
    }, _ = _def

const AdminFinance = ({ User }) => {

    const
        [isParamsURL, setParamsURL] = useSearchParams(),
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: -1 }),
        [isDialog, setDialog] = useState(false)

    const onClick = {

        create: {

            income: (e) => {

                setDialog({
    
                    id: 'finance_add',
                    title: <><IconX name="wallet2" />Добавить доход</>,
                    content: <>
                        <InputX name="finance_add_cost" type="number" label="Сумма" />
                        <InputX type="datetime-local" name="finance_add_dateTime_fact" label="Фактическая дата" />
                        <InputX textarea name="finance_add_comment" label="Комментарий" />
                        <ButtonX iconUse full><IconX name="plus-lg" />Добавить</ButtonX>
                    </>
                })
            },

            expenses: (e) => {

                setDialog({
    
                    id: 'finance_add',
                    title: <><IconX name="piggy-bank" />Добавить расход</>,
                    content: <>
                        <InputX name="finance_add_cost" type="number" label="Сумма" />
                        <InputX type="datetime-local" name="finance_add_dateTime_fact" label="Фактическая дата" />
                        <InputX textarea name="finance_add_comment" label="Комментарий" />
                        <ButtonX iconUse full><IconX name="plus-lg" />Добавить</ButtonX>
                    </>
                })
            },
        }
    }

    const Mount = () => {

        _.isInit.mount = true
        setHeaderTitle('Управление > Финансы')

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        _.isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>МТП | ЛК | Управление | Финансы</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        {isDialog &&
            <DialogX id={isDialog.id} title={isDialog.title} full={isDialog.full ?? false} close={() => { setDialog(false) }}>
                {isDialog.content}
            </DialogX>
        }
        <div id="Admin">
            <ContentMenu>
                <Link to="/lk/adm"><IconX name="chevron-left" />Управление</Link>
                {isParamsURL?.get('type') === '0' && <div onClick={onClick.create.income}><IconX name="plus-lg" />Добавить доход</div>}
                {isParamsURL?.get('type') === '1' && <div onClick={onClick.create.expenses}><IconX name="plus-lg" />Добавить расход</div>}

                {!isLoading.status && !isError.status && <>
                    {/* <div onClick={onClick.print}><IconX name='printer' />Печать</div> */}
                    {/* <div className="info">Колличество работ: {isData.length}</div> */}
                </>}
            </ContentMenu>
            {isLoading?.status ?
                <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            <TabsX
                                name="finances"
                                head={['Доходы', 'Расходы', 'Выплаты', 'Прибыль']}
                                content={[<AdminFinanceIncome />, <AdminFinanceExpenses />]}
                                select={Number(isParamsURL?.get('type') || -1)}
                                onClickTab={(idx) => setParamsURL({ ['type']: idx })}
                            />
                        </>
                    }
                </>
            }
        </div >
    </>)
}

export default AdminFinance