import React, { useState, useEffect } from 'react'

import IconX from '../IconX'
import { Text } from '../../FuncX'
import { animationElementClick } from '../Animations'

import './style.scss'



const InputX = ({

    children,
    type = 'text',
    name = 'noNAME',
    label = '',

    input = true,
    textarea = false,
    textareaHTML = false,
    select = false,
    checkbox = false,
    phone = false,
    code = false,
    slider = false,

    value = '',

    readonly = false,
    focus = false,
    noMargin = false,
    handle = (e) => { },
    phoneCurrentData = (phone) => { },
    onFill = (e) => { },
    ...props
}) => {

    const
        [inputValue, setInputValue] = useState(value),
        [phoneValue, setPhoneValue] = useState(typeof value !== 'object' ? {} : value),
        [codeValue, setCodeValue] = useState(typeof value !== 'object' ? {} : value),
        [isUsed, setUsed] = useState(false)

    if (checkbox) input = false

    const handleChange = (e) => {

        if (phone) {

            const valueCurrent = e.target.value

            if (valueCurrent.length > 1) e.target.value = valueCurrent.slice(-1)

            const
                tmpPhone = JSON.parse(JSON.stringify(phoneValue)),
                id = typeof Number(e.target.dataset.id) === 'number' ? Number(e.target.dataset.id) : -1,
                next = e.target.nextElementSibling

            tmpPhone[id] = e.target.value
            setPhoneValue(tmpPhone)

            if (valueCurrent.length !== 0) {

                if (next) {

                    if (next.type !== 'number') next.nextElementSibling.focus()
                    else next.focus()
                } else e.target.blur()
            }

            return handle(e)
        }

        if (code) {

            const valueCurrent = e.target.value

            if (valueCurrent.length > 1) e.target.value = valueCurrent.slice(-1)

            const
                tmpCode = JSON.parse(JSON.stringify(codeValue)),
                id = typeof Number(e.target.dataset.id) === 'number' ? Number(e.target.dataset.id) : -1,
                next = e.target.nextElementSibling

            tmpCode[id] = e.target.value
            setCodeValue(tmpCode)

            if (valueCurrent.length !== 0) {

                if (next) {

                    if (next.type !== 'number') next.nextElementSibling.focus()
                    else next.focus()
                } else {

                    e.target.blur()
                    setTimeout((_) => onFill(e.target), 500)
                }
            }

            return handle(e)
        }

        if (!select && !slider) {

            if (e.target.value.length > 0) setUsed(true)
            else setUsed(false)
        }

        setInputValue(e.target.value)
        return handle(e)
    }

    const onFocus = (e) => {

        if (phone || code) e.target.select()

        return true
    }

    const onKeyDown = (e) => {

        if (phone || code) {

            if (e.keyCode === 8) {

                const prev = e.target.previousElementSibling

                if (e.target.value.length < 1 && prev) {

                    if (prev.type !== 'number') prev.previousElementSibling.focus()
                    else prev.focus()
                }
            }
        }

        return true
    }

    const onClick = {

        formatedBold: (e) => formated(e, 'b'),
        formatedItalic: (e) => formated(e, 'i'),
        formatedUnderline: (e) => formated(e, 'u'),
        formatedStrikethrough: (e) => formated(e, 's'),
        clearField: (e) => {

            const input = document.querySelector(`.id_${name} > input`)

            setUsed(false)
            input.value = ''
            return handle({ target: input, currentTarget: input })
        }
    }

    const formated = (e, tag) => {

        if (!e) return false
        if (!tag) return false

        const textarea = e?.target?.parentNode?.parentNode?.querySelector('textarea')

        if (textarea) {

            animationElementClick(e?.currentTarget)

            const
                start = textarea.selectionStart,
                end = textarea.selectionEnd,
                selectText = textarea.value.substring(start, end),
                finText = textarea.value.substring(0, start) + `<${tag}>${selectText}</${tag}>` + textarea.value.substring(end)

            textarea.value = finText
            handleChange({ target: textarea })
            textarea.focus()
            textarea.selectionEnd = end + `<${tag}>`.length
        }

        return true
    }

    useEffect(() => {

        if (!select && !checkbox && !phone && !code && !slider) document.querySelector(`.id_${name} > label`).innerHTML = label
        if (phone || code) document.querySelector(`.id_${name} > div > .label`).innerHTML = label
    }, [label]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (focus) {

            const e = document.querySelector(`.id_${name}`)

            if (phone || code) {

                const inputElements = e.querySelectorAll('input')
                let inputActive = null

                for (const inputElement of inputElements) {

                    if (inputElement?.value?.length > 0) continue

                    inputActive = inputElement
                    break
                }

                const pos = inputActive?.getBoundingClientRect()

                // if (pos?.top) window.scrollTo({ top: pos?.top })

                setTimeout((_) => { inputActive?.focus() })
            }
        }
    }, [focus]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (!phone && !code && !slider) {

            const input = document.querySelector(`.id_${name} > input`)

            if (input) input.value = value

            setInputValue(value)
        } else setPhoneValue(value)

        if (value?.length > 0 || value > 0) setUsed(true)
    }, [value]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => { if (!checkbox && !phone && !code && !slider) document.querySelector(`.id_${name} > #${name}`).readOnly = readonly }, [readonly]) // eslint-disable-line react-hooks/exhaustive-deps
    // useEffect(() => { phoneCurrentData(Text.inputPhoneToText(phoneValue)) }, [phoneValue]) // eslint-disable-line react-hooks/exhaustive-deps



    return (
        <>
            <div id="inputX" className={`id_${name}${noMargin ? ' noMargin' : ''}`}>
                {textarea &&
                    <textarea
                        onChange={handleChange}
                        name={name}
                        id={name}
                        autoComplete="off"
                        defaultValue={inputValue}
                        {...props}
                    />
                }
                {textareaHTML &&
                    <>
                        <textarea
                            onChange={handleChange}
                            name={name}
                            id={name}
                            className="HTML"
                            autoComplete="off"
                            defaultValue={inputValue}
                            {...props}
                        />
                        <div className="formated">
                            <IconX name="type-bold" onClick={onClick.formatedBold} />
                            <IconX name="type-italic" onClick={onClick.formatedItalic} />
                            <IconX name="type-underline" onClick={onClick.formatedUnderline} />
                            <IconX nmae="type-strikethrough" onClick={onClick.formatedStrikethrough} />
                        </div>
                    </>
                }
                {input && !select && !textarea && !textareaHTML && !phone && !code && !slider &&
                    <>
                        <input
                            onChange={handleChange}
                            name={name}
                            id={name}
                            type={type}
                            autoComplete="off"
                            defaultValue={inputValue}
                            {...props}
                        />
                        {!readonly && <div className={inputValue?.length > 0 && isUsed ? 'clearField used' : 'clearField'} onClick={onClick.clearField}><IconX name="x-circle" /></div>}
                    </>
                }
                {select &&
                    <select
                        onChange={handleChange}
                        name={name}
                        id={name}
                        defaultValue={inputValue}
                        {...props}
                    >
                        {children}
                    </select>
                }
                {checkbox &&
                    <div className="checkBox">
                        <input onChange={handleChange} type="checkbox" id={name} name={name} defaultChecked={inputValue} {...props} />
                        <label htmlFor={name}>{label}</label>
                    </div>
                }
                {phone &&
                    <div className="phoneInput" name={name} data-phone={Text.inputPhoneToText(phoneValue)}>
                        <div className="label">{label}</div>
                        <div className="sing">+</div>
                        <input
                            type="number"
                            data-id="0"
                            autoComplete="someRandomString"
                            defaultValue={phoneValue?.[0]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !phoneValue?.[0] ? true : false}
                        />
                        <div className="sing">(</div>
                        <input
                            type="number"
                            data-id="1"
                            autoComplete="someRandomString"
                            defaultValue={phoneValue?.[1]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !phoneValue?.[1] ? true : false}
                        />
                        <input
                            type="number"
                            data-id="2"
                            autoComplete="someRandomString"
                            defaultValue={phoneValue?.[2]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !phoneValue?.[2] ? true : false}
                        />
                        <input
                            type="number"
                            data-id="3"
                            autoComplete="someRandomString"
                            defaultValue={phoneValue?.[3]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !phoneValue?.[3] ? true : false}
                        />
                        <div>)</div>
                        <input
                            type="number"
                            data-id="4"
                            autoComplete="someRandomString"
                            defaultValue={phoneValue?.[4]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !phoneValue?.[4] ? true : false}
                        />
                        <input
                            type="number"
                            data-id="5"
                            autoComplete="someRandomString"
                            defaultValue={phoneValue?.[5]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !phoneValue?.[5] ? true : false}
                        />
                        <input
                            type="number"
                            data-id="6"
                            autoComplete="someRandomString"
                            defaultValue={phoneValue?.[6]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !phoneValue?.[6] ? true : false}
                        />
                        <div>-</div>
                        <input
                            type="number"
                            data-id="7"
                            autoComplete="someRandomString"
                            defaultValue={phoneValue?.[7]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !phoneValue?.[7] ? true : false}
                        />
                        <input
                            type="number"
                            data-id="8"
                            autoComplete="someRandomString"
                            defaultValue={phoneValue?.[8]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !phoneValue?.[8] ? true : false}
                        />
                        <div>-</div>
                        <input
                            type="number"
                            data-id="9"
                            autoComplete="someRandomString"
                            defaultValue={phoneValue?.[9]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !phoneValue?.[9] ? true : false}
                        />
                        <input
                            type="number"
                            data-id="10"
                            autoComplete="someRandomString"
                            defaultValue={phoneValue?.[10]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !phoneValue?.[10] ? true : false}
                        />
                    </div>
                }
                {code &&
                    <div className="codeInput" name={name} data-code={Text.inputPhoneToText(codeValue)}>
                        <div className="label">{label}</div>
                        <input
                            type="number"
                            data-id="0"
                            defaultValue={codeValue?.[0]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !codeValue?.[0] ? true : false}
                        />
                        <input
                            type="number"
                            data-id="1"
                            defaultValue={codeValue?.[1]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !codeValue?.[1] ? true : false}
                        />
                        <input
                            type="number"
                            data-id="2"
                            defaultValue={codeValue?.[2]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !codeValue?.[2] ? true : false}
                        />
                        <input
                            type="number"
                            data-id="3"
                            defaultValue={codeValue?.[3]}
                            onChange={handleChange}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            autoFocus={focus && !codeValue?.[3] ? true : false}
                        />
                    </div>
                }
                {slider &&
                    <div className="slider">
                        <label htmlFor={name}>
                            <input onChange={handleChange} type="checkbox" id={name} name={name} defaultChecked={inputValue} {...props} />
                            <div className="custom"></div>
                            <div className="label">{label}</div>
                        </label>
                    </div>
                }
                {!select && !checkbox && !phone && !code && !slider && <label className={isUsed ? 'used' : ''}>{label}</label>}
            </div>
        </>
    )
}

export default InputX