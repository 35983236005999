import React, { useEffect, useState } from 'react'

import {
    animationElementClick,
    animationElementError,
    animationElementHide
} from '../Animations'
import { Text, checkUserStatus } from '../../FuncX'
import Loading from '../Loading'
import LinkX from '../LinkX'
import DialogX from '../DialogX'
import InputX from '../InputX'
import ButtonX from '../ButtonX'
import IconX from '../IconX'
import API from '../../API'

import './style.scss'

const Auth = ({ User, compact = false }) => {

    const
        [isUser, setUser] = useState(User),
        [isMenu, setMenu] = useState(false),
        [isDialog, setDialog] = useState(false)

    const EventListener = {

        click: (e) => {

            if (isMenu && (new Date().getTime() - isMenu) > 500) onClick.button(document.querySelector('#Auth>.button'))

            return true
        }
    }

    const Events = {

        Reg: async () => {

            document.addEventListener('click', EventListener.click)
            return true
        },

        unReg: () => {

            document.removeEventListener('click', EventListener.click)
            return true
        },
    }

    const onClick = {

        button: (e) => {

            if (isMenu) {

                animationElementHide(document.querySelector('#Auth>.menu'))
                return setTimeout((_) => setMenu(false), 500)
            }
            if (!isUser) {

                document.querySelector('body').classList.add('disableScroll')
                setDialog({

                    id: 'auth_start',
                    title: 'Авторизация',
                    content: <>
                        <InputX name='authPhone' phone value={{ 0: '7' }} label='Номер телефона' focus noMargin />
                        <div className='btn_default' onClick={onClick.codeSend}><IconX name="send" /> Получить код</div>
                    </>
                })
                return false
            }

            return setMenu(new Date().getTime())
        },

        codeSend: async (e) => {

            animationElementClick(e.currentTarget)

            const input = document.querySelector(`.id_authPhone > .phoneInput`)

            if (input.dataset.phone.length < 11) return animationElementError(input)

            setDialog({

                id: 'auth_start',
                title: 'Авторизация',
                content: <>
                    <Loading module>Отправка кода на номер +{input.dataset.phone}...</Loading>
                </>
            })

            let response

            try { response = await API.Auth.CodeSend(input.dataset.phone) }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                setDialog({

                    id: 'auth_start',
                    title: 'Авторизация',
                    content: <>
                        <InputX name='authPhone' phone value={Text.toInputPhone(input.dataset.phone)} label='Номер телефона' focus noMargin />
                        <div className='error'>{response.result}</div>
                        <div className='btn_default' onClick={onClick.codeSend}><IconX name="send" /> Получить код</div>
                    </>
                })
                return false
            }

            setDialog({

                id: 'auth_start',
                title: 'Авторизация',
                content: <>
                    <InputX name='authCode' code label={`Введите код который был вам отправлен в WhatsApp или Telegram на номер: +${input.dataset.phone}`} onFill={(e) => onClick.codeVerify(e, input.dataset.phone)} focus noMargin />
                </>
            })

            return true
        },

        codeVerify: async (e, phone) => {

            animationElementClick(e.currentTarget)

            const input = document.querySelector(`.id_authCode > .codeInput`)

            if (input?.dataset?.code?.length < 4) return animationElementError(input)

            setDialog({

                id: 'auth_start',
                title: 'Авторизация',
                content: <>
                    <Loading module>Проверка кода...</Loading>
                </>
            })

            let response

            try { response = await API.Auth.CodeVerify(phone, input.dataset.code) }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                setDialog({

                    id: 'auth_start',
                    title: 'Авторизация',
                    content: <>
                        <InputX name='authCode' code label='Введите код который был вам отправлен в WhatsApp или Telegram' onFill={(e) => onClick.codeVerify(e, phone)} focus noMargin />
                        <div className='error'>{response.result}</div>
                    </>
                })
                return false
            }

            localStorage.setItem('MTP|Auth', response.result.key)
            return window.location = '/lk'
        },

        LogOut: async (_) => {

            localStorage.removeItem('MTP|Auth')
            localStorage.removeItem('MTP|Module.Access|isClient')
            localStorage.removeItem('MTP|Module.Access|isCorp')
            localStorage.removeItem('MTP|Module.Access|isPath')
            localStorage.removeItem('MTP|Module.Access|isPathHistory')
            setMenu(false)
            return window.location = '/'
        }
    }

    useEffect((_) => { setUser(User) }, [User])
    useEffect(() => {

        Events.Reg()
        return () => { Events.unReg() }
    }, [isMenu]) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>

        {isDialog &&
            <DialogX id={isDialog.id} title={isDialog.title} close={() => { setDialog(false); document.querySelector('body').classList.remove('disableScroll') }}>
                {isDialog.content}
            </DialogX>
        }
        
        <div id="Auth">
            <div className={`button${isMenu ? ' active' : ''}`} onClick={onClick.button}>
                {isUser ?
                    <>
                        {compact ?
                            <>
                                <img src={API.Files.GetPrivate(isUser?.avatar)} alt={`Avatar by ${isUser._id}`} />
                            </>
                            :
                            <>
                                <p>Личный кабинет</p>
                                <img src={API.Files.GetPrivate(isUser?.avatar)} alt={`Avatar by ${isUser._id}`} />
                            </>
                        }
                    </>
                    :
                    <>
                        <p>Войти</p>
                        <IconX name="box-arrow-in-right" />
                    </>
                }
            </div>
            {isUser && isMenu &&
                <div className="menu elementShow">
                    <div>
                        Здравствуйте, <b>{isUser.name.first} {isUser.name.middle}</b>!
                    </div>
                    {checkUserStatus(['gAdmin', 'moder'], isUser?.status) &&
                        <LinkX to="/lk/dialogs">
                            <ButtonX iconUse full>
                                <IconX name="envelope" /> Диалоги
                            </ButtonX>
                        </LinkX>
                    }
                    <LinkX to="/lk">
                        <ButtonX iconUse full>
                            <IconX name="house" /> Главная
                        </ButtonX>
                    </LinkX>
                    <LinkX to="/lk/req">
                        <ButtonX iconUse full>
                            <IconX name="list-task" /> Заявки
                        </ButtonX>
                    </LinkX>
                    <LinkX to="/lk/access">
                        <ButtonX iconUse full>
                            <IconX name="key" /> Доступы
                        </ButtonX>
                    </LinkX>
                    <LinkX to="/lk/apps">
                        <ButtonX iconUse full>
                            <IconX name="list-task" /> Приложения
                        </ButtonX>
                    </LinkX>
                    <LinkX to="/lk/settings">
                        <ButtonX iconUse full>
                            <IconX name="gear" /> Настройки
                        </ButtonX>
                    </LinkX>
                    {checkUserStatus(['gAdmin'], isUser?.status) &&
                        <LinkX to="/lk/adm">
                            <ButtonX iconUse full>
                                <IconX name="building-gear" /> Управление
                            </ButtonX>
                        </LinkX>
                    }
                    <ButtonX onUse={onClick.LogOut} iconUse full red>
                        <IconX name="escape" /> Выйти из аккаунта
                    </ButtonX>
                </div>
            }
        </div>
    </>)
}

export default Auth