import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { DateTime } from './FuncX'
import './_1608.scss'

const _1608 = (_) => {

    const
        [Time, setTime] = useState(false),
        date = '2021-08-16T08:45:00.000+00:00',
        parce = () => setTime(DateTime.msToTime(new Date().getTime() - new Date(date).getTime()))

    useEffect(() => { setInterval(parce) }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div id='_1608'>
        <Helmet>
            <meta charSet="utf-8" />
            <title>_1608</title>
        </Helmet>

        {Time && <>
            <div>{Time.years.text}</div>
            <div>{Time.months.text}</div>
            <div>{Time.days.text}</div>
            <div>{Time.hours.text}</div>
            <div>{Time.minutes.text}</div>
            <div>{Time.seconds.text}</div>
            <div className='MS'>{Time.milliseconds.fix}</div>
        </>}
    </div>)
}

export default _1608