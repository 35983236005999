import { useEffect, useState } from 'react'

import { animationElementClick } from '../Animations'
import IconX from '../IconX'
import Loading from '../Loading'
import Error from '../Error'
import { formatName } from '../../FuncX'
import API from '../../API'

import './style.scss'
import ButtonX from '../ButtonX'



const SelectUser = ({ name, onlyCorps = false, onlyUsers = false, select = (value) => { console.log('[SelectUser] select ->', value) }, hideId = [], hideStatus = [], ...props }) => {

    const
        isInit = { mount: false }

    const
        [isLoading, setLoading] = useState({ status: false, msg: '' }),
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isType, setType] = useState('individuals'),
        [isSelect, setSelect] = useState({ user: false, corp: false }),
        [isData, setData] = useState(false)

    const onClick = {

        setList: (e, type) => {

            if (isType === type || isType === type + 'Select') return false

            animationElementClick(e.currentTarget)
            setType(type)
            return true
        },

        setCorp: (e, corp) => {

            if (e) animationElementClick(e.currentTarget)
            if (onlyCorps) select({ user: false, corp })
            else {

                setType('corpsSelect')
                setSelect({ user: false, corp })
            }

            return true
        },

        select: (e, user, corp = false) => {

            if (e) animationElementClick(e.currentTarget)

            select({ user, corp })
            return true
        }
    }

    const checkHideId = (user_id) => {

        for (const user of hideId) {

            if (user._id === user_id) return false
        }

        return true
    }

    const checkHideStatus = (status_name) => {

        for (const status of hideStatus) {

            if (status === status_name) return false
        }

        return true
    }

    const get = () => new Promise(async (resolve) => {

        let response

        setLoading({ status: true, msg: 'Загрузка пользователей...' })

        try { response = await API.Users.GetClients() }
        catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            setError({ status: true, title: 'загрузки пользователей', msg: response.result })
            return resolve(false)
        }

        setData(response.result)
        setLoading({ status: false })
        return resolve(false)
    })

    const Mount = () => {

        isInit.mount = true
        get()

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div id="SelectUser" className={`name___${name}`} {...props}>

        {isLoading?.status ?
            <Loading module>{isLoading.msg}</Loading>
            :
            <>
                {isError?.status ?
                    <Error title={isError?.title} msg={isError?.msg} />
                    :
                    <>
                        {!onlyUsers &&
                            <div className='types'>
                                <div
                                    className={`type${isType === 'individuals' ? ' active' : ''}`}
                                    onClick={(e) => onClick.setList(e, 'individuals')}
                                >
                                    <IconX name='person' />Физ. Лица
                                </div>
                                <div
                                    className={`type${isType === 'corps' || isType === 'corpsSelect' ? ' active' : ''}`}
                                    onClick={(e) => onClick.setList(e, 'corps')}
                                >
                                    <IconX name='building' />Юр. Лица
                                </div>
                            </div>
                        }
                        <div className="list">
                            {isType === 'individuals' && <>
                                {isData?.individuals?.map((item, index) => (
                                    <>
                                        {checkHideId(item?._id) && checkHideStatus(item?.status) &&
                                            <ButtonX key={item._id} onUse={(e) => onClick.select(e, item)} iconUse full>
                                                <IconX name='person' /> {formatName(item, false, false)}
                                            </ButtonX>
                                        }
                                    </>
                                ))}
                            </>}
                            {isType === 'corps' && <>
                                {isData?.corps?.map((item, index) => (
                                    <ButtonX key={item._id} onUse={(e) => onClick.setCorp(e, item)} iconUse full>
                                        <IconX name='building' /> {item?.name}
                                    </ButtonX>
                                ))}
                            </>}
                            {isType === 'corpsSelect' && isSelect?.corp && <>
                                <ButtonX onUse={(_) => {

                                    setSelect({ user: false, corp: false })
                                    setType('corps')
                                }} iconUse full>
                                    <IconX name='arrow-left' /> Вернуться
                                </ButtonX>
                                <div className='corpTitle'>{isSelect?.corp?.name}</div>
                                {isData?.individuals?.map((individual, index) => (
                                    <>
                                        {individual?.corps.map((corp) => (
                                            <>
                                                {corp?._id === isSelect?.corp?._id &&
                                                    <>
                                                        {checkHideId(individual?._id) && checkHideStatus(individual?.status) &&
                                                            <ButtonX key={individual._id} onUse={(e) => onClick.select(e, individual, isSelect?.corp)} iconUse full>
                                                                <IconX name='person' /> {formatName(individual, false, false)}
                                                            </ButtonX>
                                                        }
                                                    </>
                                                }
                                            </>
                                        ))}
                                    </>
                                ))}
                            </>}
                        </div>
                    </>
                }
            </>
        }
    </div>)
}

export default SelectUser