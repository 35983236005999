import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router'

import Header from './Header'
import NavBar from './NavBar'
import AlertX from '../../Components/AlertX'
import IconX from '../../Components/IconX'
import UseCookieToast from '../../Components/UseCookieToast'
import { YandexMetrika } from '../../FuncX'
// import { generateToken } from '../../firebase'
import Auth from '../../Components/Auth'

const
    isInit = { mount: false }

const LayoutLK = ({ APIstatus, User }) => {

    const
        [isNavBarStatus, setNavBarStatus] = useState(false)

    const Mount = () => {

        if (isInit.mount) return UnMount

        isInit.mount = true
        YandexMetrika.init(97144790, { webvisor: true, clickmap: true, accurateTrackBounce: true, trackLinks: true, disabled: false })
        // generateToken()
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div id="content">
        {User === null ?
            <>
                {!APIstatus ?
                    <>
                        <AlertX type="error" icon={<IconX name="key" />}>Сервер API недоступен! Попробуйте повторить попытку позже.</AlertX>
                        <a href={(_) => window.location = window.location.href}><div className="btn_default">Повторить попытку</div></a>
                    </>
                    :
                    <>
                        <AlertX type="error" icon={<IconX name="key" />}>Вы не авторизованы! У вас нет доступа к этой странице.</AlertX>
                        <div className="error_auth_button"><Auth /></div>
                        <a href="/"><div className="btn_default">Перейти на главную</div></a>
                    </>
                }

            </>
            :
            <>
                <Header APIstatus={APIstatus} User={User} isNavBarStatus={isNavBarStatus} />
                <div id="workSpace">
                    <NavBar User={User} toggleStatus={(status) => setNavBarStatus(status)} />
                    <div className={`content lk${isNavBarStatus ? ' open' : ' close'}`}>
                        <Outlet />
                    </div>
                </div>
                <UseCookieToast />
            </>
        }
    </div>)
}

export default LayoutLK