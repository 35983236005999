import './AlertX.scss'

const AlertX = ({ children, type='info', icon='', ...props }) => {

    return (
        <div id="alert" className={type} {...props}>
            <div className="aura"></div>
            <div className="icon">{icon}</div>
            <div className="text">{children}</div>
        </div>
    )
}

export default AlertX