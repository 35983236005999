import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { DateTime, Kludge, checkUserStatus, formatName, parceTypeWork, setHeaderTitle } from '../../../../FuncX'
import ContentMenu from '../../../../Components/ContentMenu'
import Loading from '../../../../Components/Loading'
import Error from '../../../../Components/Error'
import IconX from '../../../../Components/IconX'
import { animationElementClick } from '../../../../Components/Animations'
import InputX from '../../../../Components/InputX'
import ButtonX from '../../../../Components/ButtonX'
import DialogX from '../../../../Components/DialogX'
import AlertX from '../../../../Components/AlertX'
import Admin_Works_View_History from './History'
import API from '../../../../API'

import './style.scss'

const Admin_Works_View = ({ User }) => {

    const
        _def = { isInit: { mount: false, loading: false }, isAfterLoading: { skip: 0, count: 0 } },
        _ = _def

    const
        { id } = useParams(),
        navigate = useNavigate(),

        [isDialog, setDialog] = useState(false),
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 }),
        [isEdit, setEdit] = useState({ active: false }),
        [isData, setData] = useState(false),
        [isServices, setServices] = useState([])

    const onClick = {

        edit: (e) => {

            animationElementClick(e.currentTarget)
            setEdit({ active: !isEdit.active })
            return true
        },

        delete: async (e) => {

            animationElementClick(e.currentTarget)
            setDialog({

                id: 'create_field',
                title: 'Удаление документа доступа',
                content: <>
                    <div className='title'>
                        <div className='main'>Вы уверены что хотите удалить услугу - {isData?.name}?</div>
                        <div className='alt'>Удаление этой услуги приведет к её недоступности.</div>
                    </div>
                    <div className='question'>
                        <ButtonX iconUse onUse={() => { setDialog(false) }}><IconX name="chevron-left" /> Отменить</ButtonX>
                        <ButtonX iconUse red onUse={async () => {

                            setDialog(false)
                            setError({ status: false })
                            setLoading({ status: true, msg: 'Удаление услуги...' })

                            let res

                            try { res = await API.Works.Delete(id) }
                            catch (error) { res = { status: false, result: error?.message } }

                            if (!res.status) {

                                setError({ status: true, title: 'удаление услуги', msg: res.result })
                                setLoading({ status: false })
                                return false
                            }

                            return await getData()
                        }}><IconX name="trash" /> Удалить</ButtonX>
                    </div>
                </>
            })
        },

        save: async (e) => {

            animationElementClick(e.currentTarget)

            const saveData = Kludge.deleteX(isEdit, ['active'])

            setError({ status: false })
            setLoading({ status: true, msg: 'Сохранение услуги...' })

            let res

            try { res = await API.Works.Update(id, saveData) }
            catch (error) { res = { status: false, result: error?.message } }

            if (!res.status) {

                setError({ status: true, title: 'сохранения услуги', msg: res.result })
                setLoading({ status: false })
                return false
            }

            return await getData()
        },

        restore: async (e) => {

            setError({ status: false })
            setLoading({ status: true, msg: 'Восстановление услуги...' })

            let res

            try { res = await API.Works.Restore(id) }
            catch (error) { res = { status: false, result: error?.message } }

            if (!res.status) {

                setError({ status: true, title: 'восстановление услуги', msg: res.result })
                setLoading({ status: false })
                return false
            }

            return await getData()
        },

        deleteForce: async (e) => {

            setError({ status: false })
            setLoading({ status: true, msg: 'Удаление навсегда услуги...' })

            let res

            try { res = await API.Works.DeleteForce(id) }
            catch (error) { res = { status: false, result: error?.message } }

            if (!res.status) {

                setError({ status: true, title: 'удаление навсегда услуги', msg: res.result })
                setLoading({ status: false })
                return false
            }

            return window.location.href = '/lk/adm/works'
        }
    }

    const onChange = {

        name: (e) => {

            const value = e.target.value

            if (!isData) return false
            if (isData.name === value) {

                setEdit((currentData) => {

                    if (Object.keys(currentData).length === 1) return { active: currentData.active }
                    else return Kludge.deleteX(currentData, ['name'])
                })
                return true
            }

            setEdit((currentData) => {

                return {

                    ...currentData,
                    name: value
                }
            })
            return true
        },

        cost: (e) => {

            const value = e.target.value

            if (!isData) return false
            if (isData.cost === Number(value)) {

                setEdit((currentData) => {

                    if (Object.keys(currentData).length === 1) return { active: currentData.active }
                    else return Kludge.deleteX(currentData, ['cost'])
                })
                return true
            }

            setEdit((currentData) => {

                return {

                    ...currentData,
                    cost: value
                }
            })
            return true
        },

        type: (e) => {

            const value = e.target.value

            if (!isData) return false
            if (isData.type === value) {

                setEdit((currentData) => {

                    if (Object.keys(currentData).length === 1) return { active: currentData.active }
                    else return Kludge.deleteX(currentData, ['type'])
                })
                return true
            }

            setEdit((currentData) => {

                return {

                    ...currentData,
                    type: value
                }
            })
            return true
        },

        service: (e) => {

            const value = e.target.value

            if (!isData) return false
            if (isData.service === value) {

                setEdit((currentData) => {

                    if (Object.keys(currentData).length === 1) return { active: currentData.active }
                    else return Kludge.deleteX(currentData, ['service'])
                })
                return true
            }

            setEdit((currentData) => {

                return {

                    ...currentData,
                    service: value
                }
            })
            return true
        }
    }

    const getData = () => new Promise(async (resolve) => {

        if (_.isInit.loading) return false

        _.isInit.loading = true

        setError({ status: false })
        setLoading({ status: true, msg: 'Загрузка услуги...' })

        let query

        try { query = await API.Works.Get(id) }
        catch (error) { query = { status: false, result: error?.message } }

        if (!query?.status) {

            setError({ status: true, title: 'загрузки услуги', msg: query?.result })
            setLoading({ status: false })
            _.isInit.loading = false
            return resolve(false)
        }

        setData(query?.result)
        setHeaderTitle(`Управление > Услуги > ${query?.result?.name || 'Неизвестно'}`)
        setLoading({ status: true, msg: 'Загрузка типов услуг...' })

        const services = await getServices()

        if (!services.status) {

            setError({ status: true, title: 'загрузки типов услуг', msg: services?.result })
            setLoading({ status: false })
            _.isInit.loading = false
            return resolve(false)
        }

        setServices(services?.result)
        setLoading({ status: false })
        _.isInit.loading = false
        return resolve(true)
    })

    const getServices = () => new Promise(async (resolve) => {

        try { return resolve(await API.Services.Get()) }
        catch (error) { return resolve({ status: false, result: error?.message }) }
    })

    const Mount = () => {

        _.isInit.mount = true
        setHeaderTitle('Загрузка...')
        getData()

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        _.isInit = _def.isInit
        _.isAfterLoading = _def.isAfterLoading
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>МТП | Личный кабинет | Управление | Услуги | Создание</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        {isDialog ?
            <>
                {Kludge.visibleContentScroll(false)}
                <DialogX id={isDialog.id} title={isDialog.title} close={() => {

                    setDialog(false)
                }}>
                    {isDialog.content}
                </DialogX>
            </>
            :
            <>{Kludge.visibleContentScroll()}</>

        }
        <div id="Admin_Works_View">
            <ContentMenu>
                {/* <Link to="/lk/adm/works"><IconX name="chevron-left" />Вернуться</Link> */}
                <div onClick={(_) => window.history?.length && window.history.length > 1 ? navigate(-1) : navigate('/lk/adm/works')}><IconX name="chevron-left" />Вернуться</div>
                <div className={isEdit.active ? 'active' : ''} onClick={onClick.edit}><IconX name="pencil" /></div>
                {isEdit.active && isData.active && <div className="delete" onClick={onClick.delete}><IconX name="trash" /></div>}
                {isEdit.active && Object.keys(isEdit).length > 1 && <div className='save' onClick={onClick.save}><IconX name="floppy" /></div>}
            </ContentMenu>

            {isLoading?.status ?
                <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            {!isData.active &&
                                <AlertX type="error" icon={<IconX name="trash" />}>
                                    <div className="title">Эта услуга недоступна</div>
                                    {checkUserStatus(['gAdmin', User?.status]) && <>
                                        <ButtonX confirm="Вы уверены?" red onUse={onClick.deleteForce}>Удалить услугу навсегда</ButtonX>
                                        <ButtonX confirm="Вы уверены?" green onUse={onClick.restore}>Восстановить услугу</ButtonX>
                                    </>}
                                </AlertX>
                            }
                            <div className="info">
                                {isEdit.active ? <InputX textarea name="name" label="Наименование" value={isData.name} handle={onChange.name} /> : <h1>{isData.name}</h1>}
                                <div><b>Создана: </b>{isData?.dateTime_create ? DateTime.fix(new Date(isData?.dateTime_create)).text : 'Неизвестно'}</div>
                                <div><b>Автор: </b>{isData?.author ? formatName(isData?.author, false) : 'Неизвестно'}</div>
                                {isEdit?.active ?
                                    <>
                                        <InputX type="number" name="cost" label="Стоимость" value={isData?.cost} handle={onChange.cost} />
                                        <InputX select name="type" value={isData?.type} handle={onChange.type}>
                                            <option value="default" selected>Выберите тип цены...</option>
                                            <option value="unit">за единицу</option>
                                            <option value="metr">за метр</option>
                                            <option value="pmetr">за погонный метр</option>
                                            <option value="timeHour">почасовая</option>
                                            <option value="income">прибыль</option>
                                            <option value="expenses">расходы</option>
                                        </InputX>
                                        <InputX select name="type" value={isData?.service?._id} handle={onChange.service}>
                                            <option value="default" selected>Выберите тип услуги...</option>
                                            {isServices?.map(({ _id, name }) => (<option value={_id}>{name}</option>))}
                                        </InputX>
                                    </>
                                    :
                                    <>
                                        <div><b>Стоимость:</b> {isData?.cost?.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })}</div>
                                        <div><b>Тип цены:</b> {parceTypeWork(isData?.type)}</div>
                                        <div><b>Тип услуги:</b> {isData?.service?.name || 'Неизвестно'}</div>
                                    </>
                                }
                            </div>
                            <Admin_Works_View_History workId={id} />
                        </>
                    }
                </>
            }
        </div >
    </>)
}

export default Admin_Works_View
