import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { DateTime, Kludge, Text, formatName, setHeaderTitle } from '../../../../FuncX'
import { animationElementClick } from '../../../../Components/Animations'
import DialogX from '../../../../Components/DialogX'
import IconX from '../../../../Components/IconX'
import Loading from '../../../../Components/Loading'
import Error from '../../../../Components/Error'
import Block from '../../../../Components/Block'
import ProgressBar from '../../../../Components/ProgressBar'
import SelectUser from '../../../../Components/SelectUser'
import ButtonX from '../../../../Components/ButtonX'
import ContentMenu from '../../../../Components/ContentMenu'
import API from '../../../../API'

import './style.scss'

const AppsIOcheckView = ({ User }) => {

    const
        isInit = { mount: false },
        isTimer = { update: { _: null, time: 60000 } }

    const
        { deviceId } = useParams(),

        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: true, msg: '', progress: 0 }),
        [isUpdate, setUpdate] = useState(false),
        [isDialog, setDialog] = useState(false),
        [isVersion, setVersion] = useState('0.0.0'),
        [isData, setData] = useState(null)

    const onClick = {

        copyURL: (e) => {

            animationElementClick(e.currentTarget)

            const input = document.createElement('input')
            const text = `МТП | IO.check | ${isData?.info?.appConfig?.name || 'Неизвестно'} - https://МагТехПро.рф/lk/apps/IO.check/${deviceId}`
            const yPosition = window.pageYOffset || document.documentElement.scrollTop

            input.style.position = 'absolute'
            input.style['left'] = '-9999px'
            input.style.fontSize = '16px'
            input.style.top = `${yPosition}px`
            input.setAttribute('readonly', '')
            document.body.appendChild(input)
            input.value = text
            input.focus()
            input.select()
            document.execCommand('copy')
            document.body.removeChild(input)
            alert(`Ссылка на устройство - ${isData?.info?.appConfig?.name || 'Неизвестно'} скопирована в ваш буфер обмена!`)
            return true
        },

        update: async (e) => {

            if (isUpdate) return false

            animationElementClick(e.currentTarget)
            await update(false, false)
            return true
        },

        editAccess: (e, access) => {

            if (e) animationElementClick(e.currentTarget)

            setDialog({

                id: 'editAccess_device',
                title: 'Редактирование доступа к устройству',
                content: <>
                    <div className="accesList">
                        <div className="title">Пользователи</div>
                        <div className="list">
                            {access?.length < 1 && <div className="empty">Пользователи отсутсвуют</div>}
                            {access?.map((user) => (
                                <div key={user?._id} className="user">
                                    {/* <a href={`/lk/user/${_id}`} target="_blank" rel="noreferrer"></a> */}
                                    {formatName(user, false, true, false)}
                                    {User.status === 'gAdmin' ? <IconX name="trash" onClick={(e) => onClick.removeAccesUser(e, user?._id, access)} /> : ''}
                                </div>
                            ))}
                        </div>
                    </div>
                    <ButtonX name="addDeviceUser" onUse={(e) => onClick.addAccesUser(e, access)} full><IconX name="plus-lg" />Добавить пользователя</ButtonX>
                    <ButtonX name="deleteDevice" confirm={`Вы действительно хотите удалить устройство - ${isData?.info?.appConfig?.name || 'Неизвестно'}?`} onUse={onClick.removeDevice} red full><IconX name='trash' />Удалить устройство</ButtonX>
                </>
            })
        },

        addAccesUser: (e, access) => {

            if (e) animationElementClick(e.currentTarget)

            setDialog({

                id: 'editAccess_device',
                title: 'Редактирование доступа к устройству -> Добавление пользователя',
                content: <>
                    <ButtonX name="editDeviceUser" onUse={(e) => onClick.editAccess(e, access)} full><IconX name="chevron-left" />Редактирование</ButtonX>
                    <SelectUser name="device_users" onlyUsers select={async (value) => await addUserAccess(value?.user?._id, access)} hideId={access} />
                </>
            })
            return true
        },

        removeAccesUser: async (e, user_id, access) => {

            if (e) animationElementClick(e.currentTarget)

            await removeUserAccess(user_id, access)
            return true
        },

        removeDevice: async (e) => {

            if (e) animationElementClick(e.currentTarget)

            await removeDevice()
            return true
        }
    }

    const addUserAccess = (user_id, access) => new Promise(async (resolve) => {

        let response

        setDialog({

            id: 'editAccess_device',
            title: 'Редактирование доступа к устройству -> Добавление пользователя',
            content: <Loading module>Добавление пользователя...</Loading>
        })

        try { response = await API.Apps.IOcheck.AddUserToAccess(deviceId, user_id) }
        catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            setDialog({

                id: 'editAccess_device',
                title: 'Редактирование доступа к устройству -> Добавление пользователя',
                content: <>
                    <Error title="добавления пользователя" msg={response.result} />
                    <div className="btn_default" onClick={(e) => onClick.editAccess(e, access)}><IconX name="chevron-left" /> Редактирование</div>
                </>
            })
            return resolve(false)
        }

        const updateData = await update(true, false)

        onClick.editAccess(null, updateData?.access || [])
        return resolve(true)
    })

    const removeUserAccess = (user_id, access) => new Promise(async (resolve) => {

        let response

        setDialog({

            id: 'editAccess_device',
            title: 'Редактирование доступа к устройству -> Удаление пользователя',
            content: <Loading module>Удаление пользователя...</Loading>
        })

        try { response = await API.Apps.IOcheck.RemoveUserToAccess(deviceId, user_id) }
        catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            setDialog({

                id: 'editAccess_device',
                title: 'Редактирование доступа к устройству -> Удаление пользователя',
                content: <>
                    <Error title="удаления пользователя" msg={response.result} />
                    <div className="btn_default" onClick={(e) => onClick.editAccess(e, access)}><IconX name="chevron-left" /> Редактирование</div>
                </>
            })
            return resolve(false)
        }

        const updateData = await update(true, false)

        onClick.editAccess(null, updateData?.access || [])
        return resolve(true)
    })

    const removeDevice = () => new Promise(async (resolve) => {

        let response

        setDialog({

            id: 'editAccess_device',
            title: 'Редактирование доступа к устройству -> Удаление устройства',
            content: <Loading module>Удаление устройства...</Loading>
        })

        try { response = await API.Apps.IOcheck.Remove(deviceId) }
        catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            setDialog({

                id: 'editAccess_device',
                title: 'Редактирование доступа к устройству -> Удаление устройства',
                content: <>
                    <Error title="удаления устройства" msg={response.result} />
                    <div className="btn_default" onClick={(e) => onClick.editAccess(e, isData?.access)}><IconX name="chevron-left" /> Редактирование</div>
                </>
            })
            return resolve(false)
        }

        window.location = '/lk/apps/IO.check'
        return resolve(true)
    })

    const update = (silent = true, retry = true) => new Promise(async (resolve) => {

        if (!isInit.mount) return false

        setUpdate(true)
        setError({ status: false })

        if (!deviceId) {

            setError({ status: true, title: 'загрузки устройства', msg: 'Неверный адрес устройства' })
            return resolve(false)
        }

        if (!silent) setLoading({ status: true, msg: 'Загрузка информации об устройстве...' })

        let response

        try {

            response = await API.Apps.IOcheck.Get(deviceId, (progressEvent) => {

                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                if (!silent) return setLoading({ status: true, msg: 'Загрузка информации об устройстве...', progress: percentage })

                return false
            })
        }
        catch (error) { response = { status: false, result: error.message } }


        if (!response.status) {

            if (!silent) setError({ status: true, title: 'загрузки информации об устройствe', msg: response.result })
            if (retry) isTimer.update._ = setTimeout(update, isTimer.update.time)

            setError({ status: true, title: 'загрузки информации об устройствe', msg: response.result })
            setLoading(false)
            return resolve(false)
        }

        if (response.result.info) {

            response.result.info.systemCheck.ram.percent = (response?.result?.info?.systemCheck?.ram?.used - response?.result?.info?.systemCheck?.ram?.cached) / response?.result?.info?.systemCheck?.ram?.total * 100
            response.result.info.systemCheck.disk = parce.fileSystem(response?.result?.info?.systemCheck?.disk, parce.OS(response?.result?.info?.systemCheck?.os?.platform))
        }

        setVersion(response.version)
        setData(response.result)
        setLoading({ status: false })
        setUpdate(false)
        setHeaderTitle(`Приложения > IO.check > ${response.result?.info?.appConfig?.name}`)

        if (retry) isTimer.update._ = setTimeout(update, isTimer.update.time)
        return resolve(response.result)
    })


    const parce = {

        onlineDevice: (time, interval) => {

            if (!time || !interval) return false

            const nextParce = new Date(time)

            nextParce.setMinutes(nextParce.getMinutes() + interval + 1)

            if (new Date().getTime() > nextParce.getTime()) return false
            else return true
        },

        type: (type) => {

            switch (type) {

                case 'PC': return 'pc-display'
                case 'NOTEBOOK': return 'laptop'
                case 'MONOBLOCK': return 'display'
                case 'SERVER': return 'pc'
                default: return 'cpu'
            }
        },

        OS: (os) => {

            switch (os) {

                case 'Windows': return 'windows'
                case 'linux': return 'ubuntu'
                case 'Mac': return 'apple'
                default: return 'question-square'
            }
        },

        networkType: (name) => {

            if (name.includes('VirtualBox')) return 'box'
            if (name.includes('Беспроводная')) return 'wifi'
            if (name.includes('OpenVPN')) return 'globe'
            if (name.includes('Bluetooth')) return 'bluetooth'

            return 'reception-4'
        },

        fileSystem: (data, os) => {

            if (!data) return []

            const toms = [], dataTotal = { size: 0, used: 0, usedPercent: 0, available: 0 }

            for (const tom of Object.keys(data)) {

                if (tom === 'blockDevices') continue
                if (tom === 'fsSize') continue

                const tmpTom = {

                    name: data[tom]?.name,
                    vendor: data[tom]?.vendor,
                    type: data[tom]?.type,
                    interfaceType: data[tom]?.interfaceType,
                    smartStatus: data[tom]?.smartStatus === 'unknown' ? null : data[tom]?.smartStatus,
                    serialNum: data[tom]?.serialNum || data[tom]?.serial,
                    firmwareRevision: data[tom]?.firmwareRevision,
                    temperature: data[tom]?.temperature
                }

                for (const block of data?.blockDevices || []) {

                    if (block?.device !== data[tom]?.device) continue

                    tmpTom.label = `${block?.label || block?.physical || block?.mount} (${block?.name})`

                    for (const size of data?.fsSize || []) {

                        if (os === 'windows') {

                            if (size?.fs !== block?.name) continue
                        } else if (os === 'ubuntu') {

                            if (size?.fs !== `/dev/${block?.name}`) continue
                        } else continue

                        tmpTom.system = size?.type
                        tmpTom.used = { _def: size?.used, ...Kludge.convertUnitByte(size?.used) }
                        tmpTom.available = { _def: size?.available, ...Kludge.convertUnitByte(size?.available) }
                        tmpTom.size = { _def: size?.size, ...Kludge.convertUnitByte(size?.size) }
                        tmpTom.use = size?.use

                        dataTotal.size += size?.size
                        dataTotal.used += size?.used
                        dataTotal.available += size?.available
                    }
                }

                // if (tom?.device?.length > 0) {


                // }

                toms.push(tmpTom)
            }

            dataTotal.usedPercent = dataTotal.used / dataTotal.size * 100

            return { toms, dataTotal }
        }
    }

    const Mount = () => {

        isInit.mount = true
        update(false)
        setHeaderTitle('Загрузка...')

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        clearTimeout(isTimer.update._)
        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        {isError.status &&
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`МТП | IO.check | ${isError.msg}`}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
        }
        {isData ?
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`МТП | IO.check | ${isData?.info?.appConfig?.name || 'Неизвестно'}`}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            :
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`МТП | IO.check | Загрузка...`}</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
        }
        {isDialog ?
            <>
                {Kludge.visibleContentScroll(false)}
                <DialogX
                    id={isDialog.id}
                    title={isDialog.title}
                    close={() => setDialog(false)}
                >
                    {isDialog.content}
                </DialogX>
            </>
            :
            <>{Kludge.visibleContentScroll()}</>
        }

        {isLoading.status ?
            <Loading module >{isLoading.msg}</Loading>
            :
            <>
                {isError.status ?
                    <Error title={isError.title} msg={isError.msg} />
                    :
                    <div id="IO_check_View">
                        <ContentMenu>
                            <Link className='btn' to='/lk/apps/IO.check'><IconX name='chevron-left' />Устройства</Link>
                            {!isLoading.status && <div className={isUpdate ? 'elementLoading' : ''}><IconX name="arrow-clockwise" onClick={onClick.update} /></div>}
                            {User.status === 'gAdmin' && <div onClick={(e) => onClick.editAccess(e, isData?.access)}><IconX name='pencil' /></div>}
                            <div className='btn' onClick={onClick.copyURL}><IconX name='copy' /></div>
                        </ContentMenu>

                        {!isData?.info ?
                            <div className="info">
                                <b>Ключ:</b> <code>{isData?._id || 'Неизвестно'}</code><br />
                                <b>Создан:</b> {DateTime.fix(new Date(isData?.dateTime_create))?.text_v2 || 'Неизвестно'}<br />
                            </div>
                            :
                            <>
                                <div className='info'>
                                    <h1 className={`statusActive_${parce.onlineDevice(isData?.info?.dateTime_create, isData?.info?.appConfig?.checkIntervalInMinutes)}`} ><IconX name={parce.type(isData?.info?.appConfig?.device)} /> {isData?.info?.appConfig?.name || 'Неизвестно'}</h1>
                                    <b>Местоположение:</b> <code>{isData?.info?.appConfig?.geo || 'Неизвестно'}</code><br />
                                    <b>Ключ:</b> <code>{isData?._id || 'Неизвестно'}</code><br />
                                    <div className={isData?.info?.appConfig?.version !== isVersion ? 'statusWarning' : ''}>
                                        <b>Версия:</b> <code>{isData?.info?.appConfig?.version || 'Неизвестно'}</code>
                                    </div>
                                    <b>Имя хоста:</b> <code>{isData?.info?.systemCheck?.os?.hostname || 'Неизвестно'}</code><br />
                                    <b>AnyDesk:</b> <code>{isData?.info?.systemCheck?.anydesk?.id ?  <span className={isData?.info?.systemCheck?.anydesk?.status ? 'active' : 'inActive'}>{isData?.info?.systemCheck?.anydesk?.id?.toLocaleString()}</span> : 'Неизвестно'}</code><br />
                                    <b>Создан:</b> {DateTime.fix(new Date(isData?.dateTime_create))?.text_v2 || 'Неизвестно'}<br />
                                    <hr />
                                    <b>Интервал сбора данных:</b> 1 раз в {DateTime.msToTime((isData?.info?.appConfig?.checkIntervalInMinutes || 10) * 60000)?.text || 'Неизвестно'}<br />
                                    <div className={parce.onlineDevice(isData?.info?.dateTime_create, isData?.info?.appConfig?.checkIntervalInMinutes) ? '' : 'statusWarning'}>
                                        <b>Последний сбор данных:</b> {DateTime.msToTime(new Date() - new Date(isData?.info?.dateTime_create))?.text + ' назад' || 'Неизвестно'}
                                    </div>
                                </div>

                                {isData?.info?.yandexDisk &&
                                    <div className='yandexDisk'>
                                        <h1><IconX name="hdd-network" /> Яндекс.Диск</h1>
                                        <div className="sync">
                                            <div className="title">
                                                <div className="event">{Text.firstUpperCase(isData?.info?.yandexDisk?.sync?.status || 'Неизвестно')}</div>
                                                <div>
                                                    {isData?.info?.yandexDisk?.sync?.error?.message ?
                                                        <>{Text.firstUpperCase(isData?.info?.yandexDisk?.sync?.error?.message)}</>
                                                        :
                                                        <>
                                                            {isData?.info?.yandexDisk?.sync?.current !== false ?
                                                                <>{Kludge.convertUnitByte(isData?.info?.yandexDisk?.sync?.current || 0).text} из {Kludge.convertUnitByte(isData?.info?.yandexDisk?.sync?.from || 0).text}</>
                                                                :
                                                                <>Что-то пошло не так... Проверьте службу Яндекс.Диска`а на устройствe.</>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <ProgressBar circle progress={Number(isData?.info?.yandexDisk?.sync?.percent || -1) > -1 ? Number(isData?.info?.yandexDisk?.sync?.percent) : -1} />
                                        </div>

                                        <div className="info">
                                            <ProgressBar progress={isData?.info?.yandexDisk?.info?.occupied / isData?.info?.yandexDisk?.info?.total * 100} />
                                            <div className="memoryInfo">
                                                <div className="used"><div>Занято</div><div>{Kludge.convertUnitByte(isData?.info?.yandexDisk?.info?.occupied).text}</div></div>
                                                <div className="available"><div>Свободно</div><div>{Kludge.convertUnitByte(isData?.info?.yandexDisk?.info?.free).text}</div></div>
                                                <div className="size"><div>Всего</div><div>{Kludge.convertUnitByte(isData?.info?.yandexDisk?.info?.total).text}</div></div>
                                                <div className="trash"><div>Корзина</div><div>{Kludge.convertUnitByte(isData?.info?.yandexDisk?.info?.trash).text}</div></div>
                                            </div>
                                        </div>
                                        <details>
                                            <summary><IconX name="files" /> Последние загруженные файлы ({isData?.info?.yandexDisk?.lastSync?.length || 0})</summary>
                                            <div className="info">
                                                {isData?.info?.yandexDisk?.lastSync?.map((filename, index) => (
                                                    <div key={index}>{index + 1}. <code>{filename}</code></div>
                                                ))}
                                            </div>
                                        </details>
                                    </div>
                                }

                                <details>
                                    <summary><IconX name={parce.OS(isData?.info?.systemCheck?.os?.platform)} /> Операционная система</summary>
                                    <div className="info">
                                        <b>Название:</b> {isData?.info?.systemCheck?.os?.distro || 'Неизвестно'}<br />
                                        <b>Релиз:</b> {isData?.info?.systemCheck?.os?.release || 'Неизвестно'}<br />
                                        <b>Билд:</b> {isData?.info?.systemCheck?.os?.build || 'Неизвестно'}<br />
                                        <b>Архитектура:</b> {isData?.info?.systemCheck?.os?.arch || 'Неизвестно'}<br />
                                    </div>
                                </details>

                                <details>
                                    <summary><IconX name="cpu" />Процессор ({isData?.info?.systemCheck?.cpu?.load?.currentLoad?.toFixed(0)}%)</summary>
                                    <div className="info">
                                        <b>Название:</b> <code>{isData?.info?.systemCheck?.cpu?.brand || 'Неизвестно'}</code><br />
                                        <b>Сокет:</b> {isData?.info?.systemCheck?.cpu?.socket || 'Неизвестно'}<br />
                                        <b>Ядер:</b> {isData?.info?.systemCheck?.cpu?.physicalCores || 'Неизвестно'}<br />
                                        <b>Потоки:</b> {isData?.info?.systemCheck?.cpu?.cores || 'Неизвестно'}<br />
                                        <b>Скорость:</b> {isData?.info?.systemCheck?.cpu?.currentSpeed?.avg || 'Неизвестно'} MHz<br />
                                        <b>Температура:</b> {isData?.info?.systemCheck?.cpu?.temperature?.main || 'Неизвестно'}°C / {isData?.info?.systemCheck?.cpu?.temperature?.max || 'Неизвестно'}°C<br />
                                        <details>
                                            <summary><IconX name="bezier2" />Процессы</summary>
                                            <div className="info">
                                                <b>Всего:</b> {isData?.info?.systemCheck?.cpu?.processes?.all || 'Неизвестно'}<br />
                                                {isData?.info?.systemCheck?.cpu?.processes?.list?.map((processes_item) => (
                                                    <details className={`marginNo`}>
                                                        <summary>{processes_item?.name} ({processes_item?.pid})</summary>
                                                        <div className="info">
                                                            <b>Нагрузка на CPU:</b> {processes_item?.cpu?.toFixed(0) + '%' || 'Неизвестно'}<br />
                                                            <b>Нагрузка на RAM:</b> {processes_item?.mem?.toFixed(0) + '%' || 'Неизвестно'}<br />
                                                            <b>Путь:</b> <code>{processes_item?.path || 'Неизвестно'}</code><br />
                                                        </div>
                                                    </details>
                                                ))}
                                            </div>
                                        </details>
                                    </div>
                                </details>

                                <details>
                                    <summary><IconX name="memory" />Оперативная память ({isData?.info?.systemCheck?.ram?.percent?.toFixed(0)}%)</summary>
                                    <div className="info">
                                        RAM + SWAP<br />
                                        <b>Всего:</b> {Kludge.convertUnitByte(isData?.info?.systemCheck?.ram?.total + (isData?.info?.systemCheck?.ram?.swaptotal || 0)).text}<br />
                                        <b>Занято:</b> {Kludge.convertUnitByte(isData?.info?.systemCheck?.ram?.used + (isData?.info?.systemCheck?.ram?.swapused || 0)).text}<br />
                                        <b>Свободно:</b> {Kludge.convertUnitByte(isData?.info?.systemCheck?.ram?.free + (isData?.info?.systemCheck?.ram?.swapfree || 0)).text}<br />
                                        <hr />
                                        RAM<br />
                                        <b>Всего:</b> {Kludge.convertUnitByte(isData?.info?.systemCheck?.ram?.total).text}<br />
                                        <b>Занято:</b> {Kludge.convertUnitByte(isData?.info?.systemCheck?.ram?.used - isData?.info?.systemCheck?.ram?.cached).text}<br />
                                        <b>Кэшировано:</b> {Kludge.convertUnitByte(isData?.info?.systemCheck?.ram?.cached).text}<br />
                                        <b>Свободно:</b> {Kludge.convertUnitByte(isData?.info?.systemCheck?.ram?.free + isData?.info?.systemCheck?.ram?.cached).text} ({Kludge.convertUnitByte(isData?.info?.systemCheck?.ram?.free).text})<br />
                                        <hr />
                                        SWAP<br />
                                        <b>Всего:</b> {Kludge.convertUnitByte(isData?.info?.systemCheck?.ram?.swaptotal).text}<br />
                                        <b>Занято:</b> {Kludge.convertUnitByte(isData?.info?.systemCheck?.ram?.swapused).text}<br />
                                        <b>Свободно:</b> {Kludge.convertUnitByte(isData?.info?.systemCheck?.ram?.swapfree).text}<br />
                                    </div>
                                </details>

                                <details>
                                    <summary><IconX name="pci-card-network" />Сетевые интерфейсы</summary>
                                    <div className="info">
                                        <b>Внешний IP:</b> <code>{isData?.info?.systemCheck?.publicIP?.ip || 'Неизвестно'}</code><br />
                                        <b>Страна:</b> {isData?.info?.systemCheck?.publicIP?.country || 'Неизвестно'}<br />
                                        {isData?.info?.systemCheck?.networkInterfaces?.map((networkInterfaces_item) => (
                                            <details className={`marginNo ${networkInterfaces_item?.operstate === 'up' ? 'connect' : 'disconnect'}`}>
                                                <summary><IconX name={parce.networkType(networkInterfaces_item?.iface)} /> {networkInterfaces_item?.iface}</summary>
                                                <div className="info">
                                                    <b>Название:</b> {networkInterfaces_item?.ifaceName || 'Неизвестно'}<br />
                                                    <b>IPv4:</b> {networkInterfaces_item?.ip4 || 'Неизвестно'} ({networkInterfaces_item?.ip4subnet || 'Неизвестно'})<br />
                                                    <b>IPv6:</b> {networkInterfaces_item?.ip6 || 'Неизвестно'} ({networkInterfaces_item?.ip6subnet || 'Неизвестно'})<br />
                                                    <b>DHCP:</b> {(networkInterfaces_item?.dhcp ? "Включено" : "Выключено") || 'Неизвестно'}<br />
                                                    <b>MAC:</b> {networkInterfaces_item?.mac || 'Неизвестно'}<br />
                                                    <b>Скорость:</b> {networkInterfaces_item?.speed ? networkInterfaces_item?.speed + ' мбит/с' : 'Неизвестно'}<br />
                                                </div>
                                            </details>
                                        ))}
                                    </div>
                                </details>

                                <details>
                                    <summary><IconX name="eye" />Графика</summary>
                                    <div className="info">
                                        <details className="marginNo">
                                            <summary><IconX name="pci-card" />Контроллеры</summary>
                                            <div className="info">
                                                {isData?.info?.systemCheck?.graphics?.controllers?.map((graphicsControllers_item) => (
                                                    <details className={`marginNo`}>
                                                        <summary>{graphicsControllers_item?.vendor}</summary>
                                                        <div className="info">
                                                            <b>Название:</b> {graphicsControllers_item?.model || 'Неизвестно'}<br />
                                                            <b>Шина:</b> {graphicsControllers_item?.bus || 'Неизвестно'}<br />
                                                            <b>Память:</b> {graphicsControllers_item?.vram ? graphicsControllers_item?.vram + ' мб' : 'Неизвестно'} {graphicsControllers_item?.vram?.vramDynamic ? '(Динамическая память)' : ''}<br />
                                                        </div>
                                                    </details>
                                                ))}
                                            </div>
                                        </details>
                                        <details className="marginNo">
                                            <summary><IconX name="display" />Дисплеи</summary>
                                            <div className="info">
                                                {isData?.info?.systemCheck?.graphics?.displays?.map((graphicsDisplays_item) => (
                                                    <details className={`marginNo${graphicsDisplays_item?.main ? ' active' : ''}`}>
                                                        <summary>{graphicsDisplays_item?.vendor} {graphicsDisplays_item?.main ? <IconX name="pin-angle" /> : ''}</summary>
                                                        <div className="info">
                                                            <b>Название:</b> {graphicsDisplays_item?.model || 'Неизвестно'}<br />
                                                            <b>Путь:</b> {graphicsDisplays_item?.deviceName || 'Неизвестно'}<br />
                                                            <b>Подключение:</b> {graphicsDisplays_item?.connection || 'Неизвестно'}<br />
                                                            <b>Разрешение:</b> {graphicsDisplays_item?.resolutionX || 'Неизвестно'}x{graphicsDisplays_item?.resolutionY || 'Неизвестно'} ({graphicsDisplays_item?.currentResX || 'Неизвестно'}x{graphicsDisplays_item?.currentResY || 'Неизвестно'})<br />
                                                            <b>Герцовка:</b> {graphicsDisplays_item?.currentRefreshRate || 'Неизвестно'}<br />
                                                        </div>
                                                    </details>
                                                ))}
                                            </div>
                                        </details>
                                    </div>
                                </details>

                                <details>
                                    <summary><IconX name="hdd-stack" />Файловая система ({isData?.info?.systemCheck?.disk?.dataTotal?.usedPercent?.toFixed(0)}%)</summary>
                                    <div className="info">
                                        <b>Всего:</b> {Kludge.convertUnitByte(isData?.info?.systemCheck?.disk?.dataTotal?.size).text || 'Неизвестно'}<br />
                                        <b>Использовано:</b> {Kludge.convertUnitByte(isData?.info?.systemCheck?.disk?.dataTotal?.used).text || 'Неизвестно'}<br />
                                        <b>Свободно:</b> {Kludge.convertUnitByte(isData?.info?.systemCheck?.disk?.dataTotal?.available).text || 'Неизвестно'}<br />
                                        {isData?.info?.systemCheck?.disk?.toms?.map((fileSystem_item) => (
                                            <details className={`marginNo`}>
                                                <summary>{fileSystem_item?.label}</summary>
                                                <div className="info">
                                                    <b>Название:</b> {fileSystem_item?.name || 'Неизвестно'}<br />
                                                    <b>Производитель:</b> {fileSystem_item?.vendor || 'Неизвестно'}<br />
                                                    <b>Форм-фактор:</b> {fileSystem_item?.type || 'Неизвестно'}<br />
                                                    <b>Интерфейс:</b> {fileSystem_item?.interfaceType || 'Неизвестно'}<br />
                                                    <b>Тип файловой системы:</b> {fileSystem_item?.system || 'Неизвестно'}<br />
                                                    <b>S.M.A.R.T.:</b> {fileSystem_item?.smartStatus || 'Неизвестно'}<br />
                                                    <b>Серийный номер:</b> {fileSystem_item?.serialNum || 'Неизвестно'}<br />
                                                    <b>Прошивка:</b> {fileSystem_item?.firmwareRevision || 'Неизвестно'}<br />
                                                    <b>Температура:</b> {fileSystem_item?.temperature || 'Неизвестно'}<br />
                                                    {fileSystem_item?.use && <>
                                                        <Block name="memory" title="Память">
                                                            <ProgressBar progress={fileSystem_item?.use || 0} />
                                                            <div className="memoryInfo">
                                                                <div className="used"><div>Занято</div><div>{fileSystem_item?.used?._ || 'Неизвестно'} {fileSystem_item?.used?.unit}</div></div>
                                                                <div className="available"><div>Свободно</div><div>{fileSystem_item?.available?._ || 'Неизвестно'} {fileSystem_item?.available?.unit}</div></div>
                                                                <div className="size"><div>Всего</div><div>{fileSystem_item?.size?._ || 'Неизвестно'} {fileSystem_item?.size?.unit}</div></div>
                                                            </div>
                                                        </Block>
                                                    </>}
                                                </div>
                                            </details>
                                        ))}
                                    </div>
                                </details>

                                <details>
                                    <summary><IconX name="lightning-charge" />Батарея {isData?.info?.systemCheck?.battery?.percent ? ` (${isData?.info?.systemCheck?.battery?.percent}%)` : ''}</summary>
                                    <div className="info">
                                        <b>Статус:</b> {isData?.info?.systemCheck?.battery?.isCharging ? 'Заряжается' : 'Не заряжается'}<br />
                                        <b>Заряд:</b> {isData?.info?.systemCheck?.battery?.percent ? `${isData?.info?.systemCheck?.battery?.percent}%` : 'Неизвестно'}<br />
                                        <b>Вольтаж:</b> {isData?.info?.systemCheck?.battery?.voltage || 'Неизвестно'} {isData?.info?.systemCheck?.battery?.capacityUnit}<br />
                                        <b>Модель:</b> {isData?.info?.systemCheck?.battery?.model || 'Неизвестно'}<br />
                                    </div>
                                </details>
                            </>
                        }
                    </div>
                }
            </>
        }
    </>)
}

export default AppsIOcheckView