import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router'

import IconX from '../../Components/IconX'
import Header from './Header'
import Footer from './Footer'
import UseCookieToast from '../../Components/UseCookieToast'
import { YandexMetrika } from '../../FuncX'

const LayoutDefault = ({ APIstatus, User }) => {

    const
        isInit = { mount: false }

    const
        [isAPIstatus, setAPIstatus] = useState(APIstatus),
        [isUser, setUser] = useState(User)

    useEffect((_) => { setAPIstatus(APIstatus) }, [APIstatus])
    useEffect((_) => { setUser(User) }, [User])

    const Mount = () => {

        isInit.mount = true
        YandexMetrika.init(97144784, { webvisor: true, clickmap: true, accurateTrackBounce: true, trackLinks: true, disabled: false })

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<div className="content">
        {!isAPIstatus && <div className='APIstatus'>
            <IconX name="database-slash" />
            <div className='text'>Сервер API недоступен...</div>
        </div>}
        <Header User={isUser} />
        <Outlet User={User} />
        <Footer />
        <UseCookieToast />
    </div>)
}

export default LayoutDefault