import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import IconX from '../../Components/IconX'
import Error from '../../Components/Error'
import Loading from '../../Components/Loading'
import Block from '../../Components/Block'
import DialogX from '../../Components/DialogX'
import { animationElementClick, animationElementError, animationElementLoadingStart, animationElementLoadingStop } from '../../Components/Animations'
import InputX from '../../Components/InputX'
import { DateTime, Kludge, NumberX, Text, setHeaderTitle } from '../../FuncX'
import ButtonX from '../../Components/ButtonX'
import API from '../../API'

import './style.scss'
import { generateToken } from '../../firebase'

const Settings = ({ User }) => {

    const isInit = { mount: false }

    const
        [isLoading, setLoading] = useState({ status: false, msg: '' }),
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isDialog, setDialog] = useState(false),
        [isUser, setUser] = useState(false),
        [isPersonalInfoLoading, setPersonalInfoLoading] = useState(false),
        [isPersonalInfoEdit, setPersonalInfoEdit] = useState({ active: false }),
        [isSocial, setSocial] = useState({}),
        [isSocialConnectLoading, setSocialConnectLoading] = useState(false),
        // [socialConnectTelegramLoading, setSocialConnectTelegramLoading] = useState(false),
        [sessions, setSessions] = useState([]),
        [corps, setCorps] = useState([]),
        [sessionActive, set_sessionActive] = useState(false)

    const onClick = {

        notificationApp: async (e) => {

            generateToken()
        },

        personalInfoSave: async (e) => {

            if (isPersonalInfoLoading) return false
            if (!isPersonalInfoEdit.active) return false

            animationElementClick(e.currentTarget)
            animationElementLoadingStart(document.querySelector('#BlockX>.name-PersonalInfo'))
            setPersonalInfoLoading(true)

            const editData = Kludge.deleteX(isPersonalInfoEdit, ['active'])
            let response

            try { response = await API.Users.Set(null, editData) }
            catch (error) {

                response = {
                    status: false,
                    result: error.message
                }
            }

            if (!response.status) {

                setError({ status: true, title: 'сохранение профиля', msg: response.result })
                setLoading({ status: false })
                return false
            }

            window.location = window.location.href
            return true
        },

        personalInfoAvatarEdit: (e) => {

            if (isPersonalInfoLoading) return false

            animationElementClick(e.currentTarget)
            document.querySelector('#BlockX>.name-PersonalInfo>.avatar>input').click()
            return true
        },

        personalInfoAvatarRemove: async (e) => {

            if (isPersonalInfoLoading) return false

            animationElementClick(e.currentTarget)
            setLoading({ status: true, msg: 'Удаление фото профиля' })

            let response

            try { response = await API.Users.Set(null, { avatar: null }) }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                // setError({ status: true, title: 'удаление фото профиля', msg: response.result })
                setLoading({ status: false })
                setDialog({

                    id: 'remove_avatar',
                    title: <><IconX name="exclamation-octagon" /> Ошибка удаления фото профиля</>,
                    content: <>
                        {response.result}
                    </>
                })
                return false
            }

            window.location = window.location.href
            return true
        },

        changePhoneNumber: (e) => {

            animationElementClick(e.currentTarget)
            setDialog({

                id: 'change_phoneNumber',
                title: <><IconX name="sim" /> Смена номера телефона</>,
                content: <>
                    <InputX name='changePhoneNumber' phone value={Text.toInputPhone(isUser?.phoneNumber)} label='Ваш номер телефона' focus noMargin />
                    <div className='btn_default' onClick={onClick.savePhoneNumber}><IconX name="floppy" /> Сохранить</div>
                </>
            })
            return true
        },

        savePhoneNumber: async (e) => {

            animationElementClick(e.currentTarget)

            const
                input = document.querySelector(`.id_changePhoneNumber > .phoneInput`),
                phoneNumberNew = input?.dataset?.phone

            if (phoneNumberNew?.length < 11) return animationElementError(input)
            if (phoneNumberNew === isUser?.phoneNumber) return animationElementError(input)

            setDialog({

                id: 'save_phoneNumber',
                title: <><IconX name="sim" /> Смена номера телефона</>,
                content: <>
                    <Loading module>Изменение номера с '+{isUser?.phoneNumber}' на '+{phoneNumberNew}'...</Loading>
                </>
            })

            let response

            try { response = await API.Users.Set(null, { phoneNumber: phoneNumberNew }) }
            catch (error) {

                response = {
                    status: false,
                    result: error.message
                }
            }

            if (!response.status) {

                setDialog({

                    id: 'change_phoneNumber',
                    title: <><IconX name="sim" /> Смена номера телефона</>,
                    content: <>
                        <InputX name='changePhoneNumber' phone value={Text.toInputPhone(phoneNumberNew)} label='Ваш номер телефона' focus noMargin />
                        <div className='error'>{response.result}</div>
                        <div className='btn_default' onClick={onClick.savePhoneNumber}><IconX name="floppy" /> Сохранить</div>
                    </>
                })
                return false
            }

            window.location = window.location.href
            return true
        },

        changeStatus: async (e, data) => {

            if (isSocialConnectLoading) return false

            const social = isUser.social
            let response

            setSocialConnectLoading(true)

            if (!data.active) {

                social[data.title] = { active: false, connect: NumberX.rand(100000000, 999999999) }

                try { response = await API.Users.Set(null, { social }) }
                catch (error) { response = { status: false, result: error.message } }

                if (!response.status) {

                    setError({ status: true, title: 'подключения социальной сети', msg: response.result })
                    setSocialConnectLoading(false)
                    return false
                }

                switch (data?.title) {

                    case 'telegram': {

                        setTimeout(() => { window.open(`https://t.me/MagTehPro_ROBOT?start=${social[data.title].connect}`, '_blank') })
                        break
                    }

                    case 'whatsapp': {

                        setTimeout(() => { window.open(`https://wa.me/79130001830?text=Код%20подключения:%20${social[data.title].connect}`, '_blank') })
                        break
                    }

                    default: { }
                }

                setTimeout(() => check_changeStatus(data), 2000)
                return true
            }

            social[data.title] = { active: false }

            try { response = await API.Users.Set(null, { social }) }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                setError({ status: true, title: 'отключения социальной сети', msg: response.result })
                return false
            }

            setSocial(social)
            setSocialConnectLoading(false)
            return true
        },

        settingSocialTelegram: async (e) => {

            animationElementClick(e.currentTarget)

            setDialog({

                id: 'setting_social_telegram',
                title: <><IconX name="telegram" /> Настройка уведомлений</>,
                content: <Loading module>Загрузка настроек уведомлений</Loading>
            })

            let response

            try { response = await API.Users.GetNotificationSettings(null, 'telegram') }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                setDialog({

                    id: 'setting_social_telegram',
                    title: <><IconX name="telegram" /> Настройка уведомлений</>,
                    content: <Error title="загрузки настроек уведомлений" msg={response.result} />
                })
                return false
            }

            render_notificationSettingsTelegram(response.result)
            return true
        },

        editCorp: async (e, corpId) => {

            animationElementClick(e.currentTarget)

            // if (corpId !== user)
            return true
        },

        deleteSession: async (e, sessionsId, force = false) => {

            animationElementClick(e.currentTarget)

            if (!force && sessionActive === sessionsId) {

                setDialog({

                    loading: true,
                    id: 'change_filter',
                    title: <><IconX name="question-lg" /> Удаление активной сессии</>,
                    content: <>
                        <div className="title">
                            <div className="main">Вы уверены что хотите удалить активную сессию?</div>
                            <div className="alt">Удаление этой сессии приведёт к выходу из личного кабинета.</div>
                        </div>
                        <div className="question">
                            <div className="btn_default" onClick={(_) => setDialog(false)}><IconX name="chevron-left" /> Отменить</div>

                            <div className="btn_default red" onClick={(_) => {

                                setDialog(false)
                                onClick.deleteSession(e, sessionsId, true)
                                localStorage.removeItem('MTP|Auth')
                                localStorage.removeItem('MTP|Module.Access|isClient')
                                localStorage.removeItem('MTP|Module.Access|isCorp')
                                localStorage.removeItem('MTP|Module.Access|isPath')
                                localStorage.removeItem('MTP|Module.Access|isPathHistory')
                            }}><IconX name="trash" /> Удалить</div>
                        </div>
                    </>
                })
                return false
            }

            animationElementLoadingStart(e.currentTarget)

            const newSessions = {}

            for (const a in sessions) {

                if (sessions[a].title === sessionsId) {

                    delete (sessions[a])
                    continue
                }

                const tmp_id = sessions[a].title

                delete (sessions[a].title)
                newSessions[tmp_id] = sessions[a]
            }

            const apiX = await API.Users.Set(null, { sessions: newSessions })

            if (!apiX.status) {

                animationElementLoadingStop(e.currentTarget)
                return false
            }

            if (sessionActive === sessionsId) {

                window.location = '/'
                return false
            }

            update()
            animationElementLoadingStop(e.currentTarget)
            return true
        }
    }

    const onChange = {

        personalInfo: (e) => {

            const name = e.target.name
            const value = e.target.value

            if (!name) return false
            if (name.includes('name')) {

                const field = name.split('_')?.[1]

                if (!field) return false

                const newValue = isPersonalInfoEdit?.name ? Kludge.copyObject(isPersonalInfoEdit?.name) : Kludge.copyObject(isUser?.name)

                if (newValue[field] === value) {

                    setPersonalInfoEdit((currentData) => {

                        currentData = Kludge.deleteX(currentData, ['name'])

                        if (Object.keys(currentData).length === 1) return { active: false }
                        else return currentData
                    })
                    return false
                }

                newValue[field] = value

                setPersonalInfoEdit((currentData) => {

                    return {

                        ...currentData,
                        active: true,
                        name: newValue
                    }
                })
                return true
            }
            if (!isUser[name]) return false
            if (isUser[name] === value) {

                setPersonalInfoEdit((currentData) => {

                    currentData = Kludge.deleteX(currentData, [name])

                    if (Object.keys(currentData).length === 1) return { active: false }
                    else return currentData
                })
                return false
            }

            setPersonalInfoEdit((currentData) => {

                return {

                    ...currentData,
                    active: true,
                    [name]: value
                }
            })
        },

        personalInfoAvatar: async (e) => {

            if (isPersonalInfoLoading) return false

            const avatar = document.querySelector('#BlockX>.name-PersonalInfo>.avatar>input')

            if (!avatar.files[0]) return false

            setLoading({ status: true, msg: 'Загрузка аватара...' })

            let response

            try { response = await API.Files.SetUserAvatar(avatar.files) }
            catch (error) {

                response = {
                    status: false,
                    result: error.message
                }
            }

            if (!response.status) {

                setError({ status: true, title: 'загрузки аватара', msg: response.result })
                setLoading({ status: false })
                return false
            }

            window.location = window.location.href
            return true
        },

        notificationTelegram: async (e) => {

            const name = e.target.name
            const value = e.target.checked
            const container = e.target.parentNode
            let response

            if (!name) return false
            if (!container) return false

            container.classList.add('elementLoading')

            try { response = await API.Users.SetNotificationSetting(null, 'telegram', name, value) }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                setDialog({

                    id: 'setting_social_telegram',
                    title: <><IconX name="telegram" /> Настройка уведомлений</>,
                    content: <Error title="изменения настроек уведомлений" msg={response.result} />
                })
                return false
            }

            container.classList.remove('elementLoading')
            return true
        }
    }

    const update = async () => {

        setLoading({ status: true, msg: 'Загрузка настроек...' })

        let response

        try { response = await API.Users.Get() }
        catch (error) {

            response = {
                status: false,
                result: error.message
            }
        }

        if (!response.status) {

            setError({ status: true, title: 'загрузки настроек', msg: response.result })
            setLoading({ status: false })
            return false
        }

        setUser(response.result)
        setSocial(response.result.social)
        // parceSocial(response.result.social)
        setCorps(response.result.corps)
        parceSessions(response.result.sessions)
        checkActiveSession(response.result.sessions)
        setLoading({ status: false })
        return true
    }

    const check_changeStatus = async (data) => {

        let response

        try { response = await API.Users.Get() }
        catch (error) { response = { status: false, result: error.message } }

        if (response?.result?.social?.[data?.title]?.active) {

            setSocial(response.result.social)
            setSocialConnectLoading(false)
            return true
        }

        setTimeout((_) => check_changeStatus(data), 2000)
        return false
    }

    const render_notificationSettingsTelegram = async (data) => {

        setDialog({

            id: 'setting_social_telegram',
            title: <><IconX name="telegram" /> Настройка уведомлений</>,
            content: <>
                <InputX slider name="request_create" label="Создание новой заявки" value={data?.request_create || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_changeTitle" label="Изменение названия заявки" value={data?.request_changeTitle || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_changeDescription" label="Изменение описания заявки" value={data?.request_changeDescription || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_changeDateTimeLimit" label="Изменение дедлайна в заявке" value={data?.request_changeDateTimeLimit || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_changeStatus" label="Изменение статуса заявки" value={data?.request_changeStatus || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_changeResponsible" label="Изменение ответственного заявки" value={data?.request_changeResponsible || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_createTodoList" label="Создание чек-листа в заявке" value={data?.request_createTodoList || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_changeTodoList" label="Изменение названия чек-листа в заявке" value={data?.request_changeTodoList || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_removeTodoList" label="Удаление чек-листа в заявке" value={data?.request_removeTodoList || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_addTodoListItem" label="Добавление задачи к чек-листу в заявке" value={data?.request_addTodoListItem || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_changeTodoListItem" label="Изменение названия задачи из чек-листа в заявке" value={data?.request_changeTodoListItem || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_removeTodoListItem" label="Удаление задачи из чек-листа в заявке" value={data?.request_removeTodoListItem || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_changeTodoListItemStatus" label="Выполнение задачи из чек-листа в заявке" value={data?.request_changeTodoListItemStatus || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_createWork" label="Добавление работы в заявку" value={data?.request_createWork || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_removeWork" label="Удаление работы из заявки" value={data?.request_removeWork || false} handle={onChange.notificationTelegram} />
                <InputX slider name="request_createComment" label="Добавление комментария в заявке" value={data?.request_createComment || false} handle={onChange.notificationTelegram} />
            </>
        })
        return true
    }

    const parceSessions = (data) => {

        if (!data) return false

        const tmp = []

        for (const a of Object.keys(data)) {

            tmp.push({ ...data[a], title: a })
        }

        setSessions(tmp)
        return true
    }

    const checkActiveSession = async (data) => {

        const key = localStorage.getItem('MTP|Auth').split('|')

        if (!data) return false
        if (!key[1]) return false

        for (const a of Object.keys(data)) {

            if (a === key[1]) {

                set_sessionActive(a)
                break
            }
        }
    }

    const Mount = () => {

        isInit.mount = true
        update()
        setHeaderTitle('Настройки')

        if (isInit.mount) return UnMount
    }

    const UnMount = () => {

        isInit.mount = false
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>

        <Helmet>
            <meta charSet="utf-8" />
            <title>{`МТП | Личный кабинет | Настройки`}</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>

        {isDialog &&
            <DialogX id={isDialog.id} title={isDialog.title} full={isDialog.full ?? false} close={() => { setDialog(false) }}>
                {isDialog.content}
            </DialogX>
        }

        <div id="Settings">
            {isLoading.status ?
                <Loading module>{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            {isUser &&
                                <>
                                    <Block name="PersonalInfo" icon={<IconX name="person-lines-fill" />} title="Персональные данные">
                                        <div className="avatar">
                                            <img name="avatar" src={isPersonalInfoEdit.avatar ? isPersonalInfoEdit.avatar : API.Files.GetPrivate(isUser?.avatar)} alt="Avatar" />
                                            <input type="file" onChange={onChange.personalInfoAvatar} accept="image/*" hidden />
                                            <div className="edit">
                                                <div className="btn_alt" onClick={onClick.personalInfoAvatarEdit}>
                                                    {isUser?.avatar?.includes("data:image/NOAVATAR") ?
                                                        <><IconX name="plus-lg" /> Добавить фото</>
                                                        :
                                                        <><IconX name="pencil" /> Изменить фото</>
                                                    }
                                                </div>
                                                {!isUser?.avatar?.includes("data:image/NOAVATAR") && <div className="btn_alt red" onClick={onClick.personalInfoAvatarRemove}><IconX name="x-lg" /> Удалить фото</div>}
                                            </div>
                                        </div>
                                        <InputX name="name_last" label="Ваша Фамилия" value={isUser?.name?.last} handle={onChange.personalInfo} readonly={isUser?.name?.last?.length > 0} />
                                        <InputX name="name_first" label="Ваше Имя" value={isUser?.name?.first} handle={onChange.personalInfo} readonly={isUser?.name?.first?.length > 0} />
                                        <InputX name="name_middle" label="Ваше Отчество" value={isUser?.name?.middle} handle={onChange.personalInfo} readonly={isUser?.name?.middle?.length > 0} />
                                        <InputX name="email" label="Ваша почта" value={isUser?.email} handle={onChange.personalInfo} readonly={isPersonalInfoLoading} />
                                        <ButtonX full iconUse name="editPhoneNumber" onUse={onClick.changePhoneNumber}><IconX name="sim" />Изменить номер телефона</ButtonX>
                                        {isPersonalInfoEdit.active && !isPersonalInfoLoading && <div className="btn_default" onClick={onClick.personalInfoSave}><IconX name="floppy" /> Сохранить</div>}
                                        {/* <ButtonX full iconUse name="notifications_app" onUse={onClick.notificationApp}><IconX name="bell" />Получать уведомления в приложении</ButtonX> */}
                                    </Block>
                                    <Block name="SocialConnect" icon={<IconX name="box-arrow-in-right" />} title="Социальные сети">
                                        <details className={`${isSocial?.telegram?.active ? 'connect' : 'disconnect'}`}>
                                            <summary><IconX name="telegram" /> Telegram</summary>
                                            <div className="info">
                                                <b>Статус:</b> {isSocial?.telegram?.active ? 'Подключен' : 'Отключен'}<br />
                                                {isSocial?.telegram?.name && <><b>Имя пользователя:</b> {isSocial?.telegram?.name}<br /></>}
                                                {isSocial?.telegram?.username && <><b>Юзернейм:</b> <a className="brand" href={`https://${isSocial?.telegram?.username}.t.me`} target="_blank" rel="noreferrer">@{isSocial?.telegram?.username}</a></>}
                                            </div>
                                            <div className="setting">
                                                {isSocial?.telegram?.active && <div className="btn_alt" onClick={onClick.settingSocialTelegram}><IconX name="gear" /> Настроить</div>}
                                                <div className={isSocialConnectLoading ? 'elementLoading btn_alt' : `btn_alt${isSocial?.telegram?.active ? ' red' : ' green'}`} onClick={(e) => { onClick.changeStatus(e, { ...isSocial?.telegram, title: 'telegram' }) }}>
                                                    {isSocial?.telegram?.active ?
                                                        <><IconX name="bell-slash" /> Отключить</>
                                                        :
                                                        <><IconX name="bell" /> Подключить</>
                                                    }
                                                </div>
                                            </div>
                                        </details>
                                        <details className={`${isSocial?.whatsapp?.active ? 'connect' : 'disconnect'}`}>
                                            <summary><IconX name="whatsapp" /> WhatsApp</summary>
                                            <div className="info">
                                                <b>Статус:</b> {isSocial?.whatsapp?.active ? 'Подключен' : 'Отключен'}<br />
                                                {isSocial?.whatsapp?.name && <><b>Имя пользователя:</b> {isSocial?.whatsapp?.name}<br /></>}
                                            </div>
                                            <div className="setting">
                                                {/* {isSocial?.whatsapp?.active && <div className="btn_alt" onClick={onClick.settingSocialTelegram}><IconX name="gear" /> Настроить</div>} */}
                                                <div className={isSocialConnectLoading ? 'elementLoading btn_alt' : `btn_alt${isSocial?.whatsapp?.active ? ' red' : ' green'}`} onClick={(e) => { onClick.changeStatus(e, { ...isSocial?.whatsapp, title: 'whatsapp' }) }}>
                                                    {isSocial?.whatsapp?.active ?
                                                        <><IconX name="bell-slash" /> Отключить</>
                                                        :
                                                        <><IconX name="bell" /> Подключить</>
                                                    }
                                                </div>
                                            </div>
                                        </details>
                                    </Block>
                                    {corps && corps.length > 0 &&
                                        <Block name="Corps" icon={<IconX name="building" />} title="Организации">
                                            {/* <div className="addCorp"><IconX iconName="PlusLg" /> Добавить</div> */}
                                            {corps.map((item) => (
                                                <details key={item?._id} className={User._id === item.owner ? 'active' : ''}>
                                                    <summary>{item?.name} | {item?.position}</summary>
                                                    <div className="info">
                                                        <b>Создана:</b> {DateTime.fix(new Date(item.dateTime_create)).text}<br />
                                                        <b>ИНН:</b> {item.inn}<br />
                                                        <b>ОГРН:</b> {item.ogrn === "NONE" ? '-' : item.ogrn}<br />
                                                        <b>ОГРНИП:</b> {item.ogrnip === "NONE" ? '-' : item.ogrnip}<br />
                                                        <b>Банк:</b> {item.bank}<br />
                                                        <b>БИК:</b> {item.bik}<br />
                                                        <b>Р/C:</b> {item.rs}<br />
                                                        <b>К/C:</b> {item.ks}<br />
                                                        <b>Адрес:</b> {item.address}<br />
                                                    </div>
                                                    {User._id === item?.owner ?
                                                        <div className="btn_alt icon" onClick={(e) => { onClick.editCorp(e, item._id) }}><IconX name="pencil" /> Редактировать</div>
                                                        :
                                                        <a href={`/lk/user/${item.owner}`} target="_blank" rel="noreferrer">
                                                            <div className='btn_alt'><IconX name="person" /> Открыть профиль владельца</div>
                                                        </a>
                                                    }
                                                </details>
                                            ))}
                                        </Block>
                                    }
                                    <Block name="Sessions" icon={<IconX name="eyeglasses" />} title="Сессии">
                                        {sessions
                                            .sort((a, b) => a.dateTime_last > b.dateTime_last ? -1 : 1)
                                            .map((item) => (
                                                <details key={item?.title} className={sessionActive === item.title ? 'active' : ''}>
                                                    <summary>{DateTime.fix(new Date(item.dateTime_last)).text} {sessionActive === item.title ? <IconX name="pin-angle" /> : ''}</summary>
                                                    <div className="info">
                                                        <b>Ключ:</b> <code>{item.title}</code><br />
                                                        <b>Создана:</b> {DateTime.fix(new Date(item.dateTime_create)).text}<br />
                                                        <b>Последняя активность:</b> {DateTime.fix(new Date(item.dateTime_last)).text}<br />
                                                        <b>IP Адрес:</b> {item.ip}<br />
                                                        <b>Устройство:</b> {item.device.name} {item.device.version}<br />
                                                        <b>OC:</b> {item.os.name} {item.os.version}<br />
                                                        <b>Браузер:</b> {item.browser.name} {item.browser.version}<br />
                                                        <b>Уведомления:</b> {item?.tokenFirebase && item?.tokenFirebase !== 'NONE' ? 'Да' : 'Нет'}
                                                    </div>
                                                    <div className="btn_alt red" onClick={(e) => { onClick.deleteSession(e, item.title) }}><IconX name="trash" /> Удалить</div>
                                                </details>
                                            ))}
                                    </Block>
                                </>
                            }
                        </>
                    }
                </>
            }
        </div>
    </>)
}

export default Settings