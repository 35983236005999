import React, { Component } from 'react'

import IconX from '../../Components/IconX'
import AlertX from '../../Components/AlertX'
// import LinkX from '../../Components/LinkX'

export default class Error extends Component {
  render() {
    return (
      <div style={{ marginTop: '100px' }}>
        <AlertX type="error" icon={<IconX name="dash-circle" />}>
          У нас нет такой страницы 🤔
        </AlertX>
      </div>
    )
  }
}