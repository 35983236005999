import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import Loading from '../../../Components/Loading'
import Error from '../../../Components/Error'
import { Kludge, setHeaderTitle } from '../../../FuncX'
import IconX from '../../../Components/IconX'
import { animationElementClick } from '../../../Components/Animations'
import ProgressBar from '../../../Components/ProgressBar'
import ListX from '../../../Components/ListX'
import AlertX from '../../../Components/AlertX'
import InputX from '../../../Components/InputX'
import ContentMenu from '../../../Components/ContentMenu'
import API from '../../../API'

import './style.scss'

const AppsIOcheck = ({ User }) => {

    const
        _def = {
            isInit: { mount: false, loading: false },
            isTimer: { update: { _: null, time: 1 * 60000 } }
        }, _ = _def

    const
        defaultActive = {

            all: { online: 0, max: 0, percent: 0 },
            PC: { online: 0, max: 0, percent: 0 },
            NOTEBOOK: { online: 0, max: 0, percent: 0 },
            MONOBLOCK: { online: 0, max: 0, percent: 0 },
            SERVER: { online: 0, max: 0, percent: 0 }
        },
        [isVersion, setVersion] = useState('0.0.0'),
        [isData, setData] = useState([]),
        [isDevices, setDevices] = useState([]),
        [isFindDevice, setFindDevice] = useState(false),
        [isDeviceActive, setDevicesActive] = useState(defaultActive),
        [isUpdate, setUpdate] = useState(false),
        [isError, setError] = useState({ status: false, title: '', msg: '' }),
        [isLoading, setLoading] = useState({ status: false, msg: '', progress: 0 })

    const onClick = {

        create: async (e) => {

            animationElementClick(e.currentTarget)

            let response

            setError({ status: false })
            setLoading({ status: true, msg: 'Создание нового устройства' })

            try { response = await API.Apps.IOcheck.Create() }
            catch (error) { response = { status: false, result: error.message } }

            if (!response.status) {

                setError({ status: true, title: 'создания нового устройства', msg: response.result })
                return false
            }

            await update(true, true)
            setLoading({ status: false })
            return true
        },

        update: async (e) => {

            if (isUpdate) return false

            animationElementClick(e.currentTarget)
            await update(true, true)
            return true
        }
    }

    const onChange = {

        findDevice: (e) => {

            if (e.target?.value?.length > 0) setFindDevice(true)
            else setFindDevice(false)

            return findDevice(e.target.value.toLowerCase())
        }
    }

    const findDevice = (value = '', data = false) => {

        if (data) return setDevices(data)

        const tmpOut = []

        for (const data of isData) {

            if (
                data?.info?.appConfig?.name?.toLowerCase()?.includes(value)
                || data?.info?.appConfig?.geo?.toLowerCase()?.includes(value)
                || data?._id?.toLowerCase()?.includes(value)
            ) {

                tmpOut.push(data)
                continue
            }
        }

        return setDevices(tmpOut)
    }

    const update = (silent = false, noRetry = false) => new Promise(async (resolve) => {

        if (_.isInit.loading) return false

        _.isInit.loading = true

        if (!silent) setLoading({ status: true, msg: 'Загрузка информации об устройствах...' })

        setError({ status: false })
        setUpdate(true)

        let response

        try {

            response = await API.Apps.IOcheck.Get(null, (progressEvent) => {

                if (silent) return false

                const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)

                if (_.isInit.loading) setLoading({ status: true, msg: 'Загрузка информации об устройствах...', progress: percentage })

                return true
            })
        } catch (error) { response = { status: false, result: error.message } }

        if (!response.status) {

            if (!silent) setError({ status: true, title: 'загрузки информации об устройствах', msg: response.result })
            if (!noRetry) _.isTimer.update._ = setTimeout((_) => update(true), _.isTimer.update.time)

            setUpdate(false)
            setLoading({ status: false })
            return resolve(false)
        }

        const tmpActive = Kludge.copyObject(defaultActive)

        for (const device of response.result) {

            if (!device?.info) continue

            device.online = false
            tmpActive.all.max++
            tmpActive[device?.info.appConfig?.device].max++

            if (parceOnlineDevice(device?.info.dateTime_create, device?.info.appConfig?.checkIntervalInMinutes)) {

                device.online = true
                tmpActive.all.online++
                tmpActive[device?.info.appConfig?.device].online++
            }
        }

        tmpActive.all.percent = tmpActive.all.online / tmpActive.all.max * 100
        tmpActive.PC.percent = tmpActive.PC.online / tmpActive.PC.max * 100
        tmpActive.NOTEBOOK.percent = tmpActive.NOTEBOOK.online / tmpActive.NOTEBOOK.max * 100
        tmpActive.MONOBLOCK.percent = tmpActive.MONOBLOCK.online / tmpActive.MONOBLOCK.max * 100
        tmpActive.SERVER.percent = tmpActive.SERVER.online / tmpActive.SERVER.max * 100

        setDevicesActive(tmpActive)
        setVersion(response.version)
        setData(response.result)
        setUpdate(false)
        setLoading({ status: false })

        if (!silent) findDevice(false, response.result)
        if (silent) findDevice(isFindDevice ? document.querySelector('#inputX.id_findDevice>input').value : false, isFindDevice ? false : response.result)
        if (!noRetry) _.isTimer.update._ = setTimeout((_) => update(true), _.isTimer.update.time)

        _.isInit.loading = false
        return resolve(true)
    })


    const parceOnlineDevice = (time, interval) => {

        if (!time || !interval) return false

        const nextParce = new Date(time)

        nextParce.setMinutes(nextParce.getMinutes() + interval + 1)

        if (new Date().getTime() > nextParce.getTime()) return false
        else return true
    }


    const parceTypeDevice = (type) => {

        switch (type) {

            case 'PC': return 'pc-display'
            case 'NOTEBOOK': return 'laptop'
            case 'MONOBLOCK': return 'display'
            case 'SERVER': return 'pc'
            default: return 'cpu'
        }
    }

    const Mount = () => {

        _.isInit.mount = true
        update()
        setHeaderTitle('Приложения > IO.check')

        if (_.isInit.mount) return UnMount
    }

    const UnMount = () => {

        clearTimeout(_.isTimer.update._)
        _.isInit = _def.isInit
        _.isTimer = _def.isTimer
    }

    useEffect(Mount, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`МТП | IO.check`}</title>
            <link rel="canonical" href={window.location.href} />
        </Helmet>
        <div id="IO_check">
            <ContentMenu>
                <Link className='btn' to='/lk/apps'><IconX name='chevron-left' />Приложения</Link>
                {User?.status === 'gAdmin' && !isLoading?.status && <div onClick={onClick.create}><IconX name="plus-lg" />Создать</div>}
                {!isLoading?.status && <div className={isUpdate ? 'elementLoading' : ''} onClick={onClick.update}><IconX name="arrow-clockwise" /></div>}
            </ContentMenu>

            {isLoading?.status ?
                <Loading module progress={isLoading.progress}>{isLoading.msg}</Loading>
                :
                <>
                    {isError.status ?
                        <Error title={isError.title} msg={isError.msg} />
                        :
                        <>
                            <div className="dashBoard">
                                <div className={isDeviceActive?.all?.max === 0 ? 'inactive' : ''}>
                                    <div className="title"><IconX name="cpu" />Все устройства</div>
                                    <ProgressBar circle progress={isDeviceActive?.all?.percent || 0} />
                                    {isDeviceActive?.all?.max ?
                                        <div>{isDeviceActive?.all?.online > -1 ? isDeviceActive?.all?.online : '-'} из {isDeviceActive?.all?.max > -1 ? isDeviceActive?.all?.max || '-' : '-'}</div>
                                        :
                                        <div>Отсутсвуют</div>
                                    }
                                </div>
                                <div className={isDeviceActive?.PC?.max === 0 ? 'inactive' : ''}>
                                    <div className="title"><IconX name="pc-display" />Компьютеры</div>
                                    <ProgressBar circle progress={isDeviceActive?.PC?.percent || 0} />
                                    {isDeviceActive?.PC?.max ?
                                        <div>{isDeviceActive?.PC?.online > -1 ? isDeviceActive?.PC?.online : '-'} из {isDeviceActive?.PC?.max > -1 ? isDeviceActive?.PC?.max || '-' : '-'}</div>
                                        :
                                        <div>Отсутсвуют</div>
                                    }
                                </div>
                                <div className={isDeviceActive?.NOTEBOOK?.max === 0 ? 'inactive' : ''}>
                                    <div className="title"><IconX name="laptop" />Ноутбуки</div>
                                    <ProgressBar circle progress={isDeviceActive?.NOTEBOOK?.percent || 0} />
                                    {isDeviceActive?.NOTEBOOK?.max ?
                                        <div>{isDeviceActive?.NOTEBOOK?.online > -1 ? isDeviceActive?.NOTEBOOK?.online : '-'} из {isDeviceActive?.NOTEBOOK?.max > -1 ? isDeviceActive?.NOTEBOOK?.max || '-' : '-'}</div>
                                        :
                                        <div>Отсутсвуют</div>
                                    }
                                </div>
                                <div className={isDeviceActive?.MONOBLOCK?.max === 0 ? 'inactive' : ''}>
                                    <div className="title"><IconX name="display" />Моноблоки</div>
                                    <ProgressBar circle progress={isDeviceActive?.MONOBLOCK?.percent || 0} />
                                    {isDeviceActive?.MONOBLOCK?.max ?
                                        <div>{isDeviceActive?.MONOBLOCK?.online > -1 ? isDeviceActive?.MONOBLOCK?.online : '-'} из {isDeviceActive?.MONOBLOCK?.max > -1 ? isDeviceActive?.MONOBLOCK?.max || '-' : '-'}</div>
                                        :
                                        <div>Отсутсвуют</div>
                                    }
                                </div>
                                <div className={isDeviceActive?.SERVER?.max === 0 ? 'inactive' : ''}>
                                    <div className="title"><IconX name="pc" />Серверы</div>
                                    <ProgressBar circle progress={isDeviceActive?.SERVER?.percent || 0} />
                                    {isDeviceActive?.SERVER?.max ?
                                        <div>{isDeviceActive?.SERVER?.online > -1 ? isDeviceActive?.SERVER?.online : '-'} из {isDeviceActive?.SERVER?.max > -1 ? isDeviceActive?.SERVER?.max || '-' : '-'}</div>
                                        :
                                        <div>Отсутсвуют</div>
                                    }
                                </div>
                            </div>

                            <InputX name="findDevice" label="Поиск (по названию, по локации, по ключу)" handle={onChange.findDevice} />

                            {isDevices?.length > 0 ?
                                <ListX
                                    name="deviceList"
                                    header={<>
                                        <div><IconX name="cpu" /></div>
                                        <div><IconX name="card-heading" /></div>
                                        <div><IconX name="geo-alt" /></div>
                                    </>}
                                    footer={`Отображено устройств: ${isDevices?.length}`}
                                >
                                    {isDevices?.map(({ _id, active, online, info }) => (
                                        <Link className={`content statusOnline_${online} statusActive_${active} ${info?.appConfig?.version !== isVersion ? 'oldVersion' : ''}`} key={_id} to={`/lk/apps/IO.check/${_id}`}>
                                            <div><IconX name={parceTypeDevice(info?.appConfig?.device)} /></div>
                                            <div>{info?.appConfig?.name || 'Неизвестно'}</div>
                                            <div>{info?.appConfig?.geo?.length > 2 ? info?.appConfig?.geo : 'Неизвестно'}</div>
                                        </Link>
                                    ))}
                                </ListX>
                                :
                                <AlertX icon={<IconX name="info" />}>Список устройств пуст</AlertX>
                            }
                        </>
                    }
                </>
            }
        </div>
    </>)
}

export default AppsIOcheck